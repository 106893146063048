
//Theme
import style from './style';
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

//Icons
const openFolderHorizontal = {iconName:'OpenFolderHorizontal'};


function setColumns(parent,type){
    switch(type){
        case 'default':
            return [
              {
                key: 'column1',
                name: 'Pályázat',
                fieldName: 'tender',
                minWidth: 250,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column2',
                name: 'File-ok száma',
                fieldName: 'filesCount',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: parent._onColumnClick,
                data: 'number',
                isPadded: true,
              },
              {
                key: 'column3',
                name: 'Beérkezett',
                fieldName: 'dateCreatedValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
                onRender: (item) => {
                  return <span>{item.dateCreated}</span>;
                },
                isPadded: true,
              },
              {
                key: 'column4',
                name: 'Ajánlat értéke',
                fieldName: 'value',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
                isPadded: true,
              },{
                key: 'column5',
                name: 'Valuta',
                fieldName: 'currency',
                minWidth: 40,
                maxWidth: 40,
                isResizable: true,
                data: 'string',
                onColumnClick: parent._onColumnClick,
                isPadded: true,
              },
              {
                key: 'column6',
                name: 'Ajánlat érvényes',
                fieldName: 'dateValidToValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
                onRender: (item) => {
                  return <span>{item.dateValidTo}</span>;
                },
                isPadded: true,
              },
              {
                key: 'column7',
                name: 'Módosítva',
                fieldName: 'dateModifiedValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
                onRender: (item) => {
                  return <span>{item.dateModified}</span>;
                },
                isPadded: true,
              },
              {
                key: 'column8',
                name: 'Megnyitás',
                fieldName: 'open',
                style:style.fluentUI.fileIconCell,
                isIconOnly: true,
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: parent._onColumnClick,
                onRender: (item) => (
                  <TooltipHost content="Megnyitás">
                     <IconButton
                      onClick={()=>parent.handleClick(item)}
                      iconProps={openFolderHorizontal}
                    />        
                  </TooltipHost>
                ),
              },
            ]
        default:
            return []      
    }
}

function setFarCommandItems(parent){
    return [
      {
        key: 'info',
        text: 'Info',
        id:'bt-info',
        // This needs an ariaLabel since it's icon-only
        ariaLabel: 'Info',
        iconOnly: true,
        iconProps: { iconName: 'Info' },
        onClick: () => parent.handleOpenInfo()
      },
    ]
  }

function setCommandItems(parent){
  return [
  {
    key: 'contacts',
    text: 'Kapcsolattartók',
    cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
    iconProps: { iconName: 'People' },
    subMenuProps: {
      items: [
        {
          key: 'view',
          text: 'Megtekintés',
          iconProps: { iconName: 'View' },
          onClick:()=>parent.handleOpen('viewContact'),
        },
        {
          key: 'new',
          text: 'Új hozzáadása',
          iconProps: { iconName: 'Add',style:style.fluentUI.newIcon },
          onClick:()=>parent.handleOpen('new'),
        },
        {
          key: 'edit',
          text: 'Módosítás',
          iconProps: { iconName: 'Edit',style:style.fluentUI.modifyIcon },
          onClick:()=>parent.handleOpen('editContact'),
        }
      ],
    },
  },
  {
    key: 'edit',
    text: 'Módosítás',
    onClick:()=>parent.handleOpen('edit'),
    iconProps: { iconName: 'Edit',style:style.fluentUI.modifyIcon },
  },
]
}

export default {
  setColumns,
  setFarCommandItems,
  setCommandItems
}
