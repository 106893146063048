import * as React from 'react';
//Material UI
import {Grid} from '@material-ui/core';
//Fluent UI
import {
  Modal,
  IconButton,
  Dropdown,
  Spinner,
  MessageBar,
  MessageBarType
} from 'office-ui-fabric-react';
//Components
import {PeoplePicker} from '../../../../../../components/Inputs';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

const cancelIcon = { iconName: 'Cancel' };


class AddRoles extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //selected roles
      roles:[],
      //selected people
      people:[],
      //options
      options:[],
      peopleOptions:[],
      //request
      loading:{
        options:true,
        peopleOptions:true,
        request:false
      },
      response:null
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  //TODO
  componentDidMount(){
    const {tender} = this.props;
      apiRequest.getOptions(this);
      apiRequest.getPersonOptions(this,tender.id);
  }
  //*Handle user interactions*//

  handleSelectChange = (event,item) => {
      const {roles} = this.state;
      const keys =  item.selected ? [...roles, item.key] : roles.filter(key => key !== item.key);
      if(keys.includes(2) || keys.includes(3)){
        if(!keys.includes(1)){
          keys.push(1);
        }
      }
      if(keys.includes(6) || keys.includes(4)){
        if(!keys.includes(4)){
          keys.push(4);
        }
        if(!keys.includes(1)){
          keys.push(1);
        }
      }
    if (item) {
        this.setState({
            roles:keys,
            response:null,
        });
    }
  };

  handlePeopleChange = (people) =>{
      const keys = people.map(item=>{return item.key});
      this.setState({
        people:keys,
        response:null,
      });
  }
  //**//

  //*Handle actions*//

  handleSave(){
    const {tender} = this.props;
    const {people,roles} = this.state;
    this.setState((prevState)=>({
      loading:{
          ...prevState.loading,
          request:true
      },
      response:null
    }));
    const users=[...people];
    const scopes =[...roles];
    const data ={tenderId:tender.id,users,scopes};
    apiRequest.addRoles(this,data);
  }

  //**//

  //*Helper methods*//

  validate(){
    const{roles,people} = this.state;
    if(roles.length<1 || people.length<1){
        return false;
    }
    return true;
  }

  refreshOptions = () =>{
    const {tender} = this.props;

    this.setState((prevState)=>({
      loading:{
          ...prevState.loading,
          options:true,
          peopleOptions:true
      }
    }));
    apiRequest.getOptions(this);
    apiRequest.getPersonOptions(this,tender.id);
  }

  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  //**//

  render(){
    const {roles,options,peopleOptions,people,loading,response} = this.state;
    const {open,hideModal,tender}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
            <div className={style.contentStyles.titleContent}>
                <div className={style.contentStyles.titleName}>{tender.name}</div>
                <div className={style.contentStyles.titleIndex}>Jogosultságok hozzáadása</div>
            </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        <div className={style.contentStyles.body}>
        {response!==null &&
          <MessageBar
            messageBarType={this.setMessageBarType(response.type)}
            isMultiline={false}
          >
            {response.message}
          </MessageBar>
          }
        {(loading.peopleOptions === true || loading.options===true) ?   
        <Spinner style={{padding:'1rem'}} label="Betöltés..." /> :
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Dropdown
                    placeholder="Válaszd ki..."
                    label="Jogosultságok"
                    selectedKeys={roles}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={this.handleSelectChange}
                    multiSelect
                    options={options}
                />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <PeoplePicker selected={people} onChange={this.handlePeopleChange} peopleList={peopleOptions}/>
            </Grid>
          </Grid>}
        </div>
        <div className={style.contentStyles.action}>
          <PrimaryButton disabled={!this.validate() || loading.request} onClick={this.handleSave} style={{marginRight:'5px'}}>
            Mentés
          </PrimaryButton>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
  
};

export default AddRoles;