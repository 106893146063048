export const lightTheme = {
    palette: {
        themePrimary: '#00a7ba',
        themeLighterAlt: '#f2fbfc',
        themeLighter: '#cdf0f4',
        themeLight: '#a4e3ea',
        themeTertiary: '#55c9d6',
        themeSecondary: '#17b1c2',
        themeDarkAlt: '#0097a8',
        themeDark: '#007f8d',
        themeDarker: '#005e68',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
  };