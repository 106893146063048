import React, { Component } from 'react';
//Router
import { Router } from 'react-router-dom';
import Routes from './Routes';
import { createBrowserHistory } from 'history';
//Components
import {CookieInfo} from './components';
import {Constants,UserStore} from './stores';
import {authenticationService} from './services';

//Fluent UI
import { initializeIcons } from '@uifabric/icons';
import { createTheme,loadTheme} from "@fluentui/react";
//Theme
import {lightTheme} from './themes';

const browserHistory = createBrowserHistory();


export default class App extends Component {

  constructor(props){
    super(props);
    this.state={
      currentUser:null,
      loggedInStatus:null,
      notificationBar:true,
    }
    //For the FluentUI Icons 
    initializeIcons();
    //Load FluentUI crated theme 
    loadTheme(createTheme(lightTheme));   

    this.refreshToken = this.refreshToken.bind(this);
  }

  componentDidUpdate(prevProps,prevState){
    const{currentUser} = this.state;
      //User state changes then reset the interval(when token is changed)
      if(prevState.currentUser!=currentUser){
          if(currentUser!==null && currentUser!==undefined){
            //Refresh token in every ..... milliseconds
            this.interval = setTimeout(this.refreshToken, Constants.getTokenExpired());
          }
      }
  }
  componentDidMount() {
    //Set browserhistory in to the constants 
    Constants.setHistory(browserHistory);
    //Subscribe user value 
    UserStore.getUser().subscribe(res => this.setState({
      currentUser: res,
    }));
    //Subscribe logged in status value ('LOGGED_IN, NOT_LOGGED_IN')
    UserStore.getLoggedInStatus().subscribe(res => this.setState({
      loggedInStatus: res,
    }));
  }

  componentWillUnmount () {
    //Clear the interval 
    clearTimeout(this.interval);
    //Clear the subscribes
    this.state={
      currentUser:null,
      loggedInStatus:null
    }
  }

  //Handle refresh token api calls
  refreshToken(){
    authenticationService.refreshToken();
  }

  render() {
    return (
          <React.Fragment>
            <Router history={browserHistory}>
              <Routes />
            </Router>  
            <CookieInfo />
          </React.Fragment>

    );
  }
}