import {Api,handleResponse,handleError} from '../../../../../../api';
//Fluent UI
import {
    DropdownMenuItemType
  } from 'office-ui-fabric-react';
//Action
const Actions = {
    GetOptions:'inner/role-options',
    GetPersonOptions:'inner/person-options',
    EditRoles:'inner/edit-tender-role',
    GetRoles:'inner/edit-role',
    DeleteRole:'inner/delete-tender-role'
}


export const apiRequest = {
    getOptions,
    getPersonOptions,
    editRoles,
    getRoles,
    deleteRole
};

async function getRoles(parent,tenderId) {
    await new Api().call.get(`${Actions.GetRoles}/${tenderId}`).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                items:[...res],
                originalItems:[...res],   
                loading:false,
            }));
        }).catch(handleError);
}

async function getOptions(parent) {
    await new Api().call.get(Actions.GetOptions).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                options:res.map(item=>(
                    {key:item.key,text:item.label,itemType:item.isHeader ? DropdownMenuItemType.Header : item.isDivider ? DropdownMenuItemType.Divider : null})),
            }));
        }).catch(handleError);
}

async function getPersonOptions(parent,tenderId) {
    await new Api().call.get(`${Actions.GetPersonOptions}/${tenderId}`).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                peopleOptions:res.map(item=>({
                    key:item.key,imageUrl:item.avatar,secondaryText:item.bio,text:item.name,imageInitials:item.imageInitials
                  })),    
                loading:{
                    ...prevState.loading,
                    peopleOptions:false
                }
            }));
        }).catch(handleError);
}

async function editRoles(parent,data) {
    await new Api().call.post(Actions.EditRoles,data).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                loading:false,
                response:res,
                editIndex:null,
                request:false
              }));
              parent.refreshRoles();
            }).catch(error=>{
                if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                    parent.setState({
                        response:error.response.data,
                        request:false
                    });
                }
                else{
                    parent.setState((prevState)=>({
                        response:{message:'Váratlan hiba történt',type:'error'},
                        request:false
                    }));
                }
                handleError(error);
            });
}

async function deleteRole(parent,data) {
    await new Api().call.post(Actions.DeleteRole,data).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                loading:false,
                response:res,
                removeIndex:null,
                request:false
              }));
              parent.refreshRoles();
            }).catch(error=>{
                if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                    parent.setState({
                        response:error.response.data,
                        request:false
                    });
                }
                else{
                    parent.setState((prevState)=>({
                        response:{message:'Váratlan hiba történt',type:'error'},
                        request:false
                    }));
                }
                handleError(error);
            });
}