import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Send: 'inner/upload-files',
}


export const apiRequest = {
    upload
};

async function upload(parent,data) {
    const {onRefresh} = parent.props;
    const {files} = parent.state;
    const options ={
        onUploadProgress:(progressEvent)=>{
            const {loaded,total} = progressEvent;
            let percent = Math.floor((loaded*100)/total);
            if(percent===100){
                parent.setState({
                    uploadProgress:99
                })
            }else{
                parent.setState({
                    uploadProgress:percent
                })
            }
           // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        }
    }
    await new Api().call.post(`${Actions.Send}`, data,options).then(handleResponse)
        .then(res => {
            let temp = [];
            if(res.failed.length>0){
                files.map(file=>{
                    if(res.failed.filter(fail=>fail.fileName === file.file.name).length>0){
                        temp.push(file);
                    }                
                })             
            }
            parent.setState({
                files:temp,
                success:res.success,
                failed:res.failed,
                request:false,
                uploadProgress:null
            });
            onRefresh();
        }).catch(handleError);
}