import {Api,handleResponse,handleError} from '../../../../../../../../api';
//Action
const Actions = {
    Delete: 'external/delete-offer',
}


export const apiRequest = {
    deleteOffer
};

async function deleteOffer(parent,data) {
    const {onRefresh,hideModal,setPivotItem} = parent.props;
    await new Api().call.post(Actions.Delete,data).then(handleResponse)
        .then(res => {
            parent.setState({
                loading:false,
                response:res
              });
              onRefresh();
              setPivotItem('0');           
              hideModal();
            }).catch(error=>{
                if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                    parent.setState({
                        response:error.response.data,
                        loading:false
                    });
                }
                else{
                    parent.setState({
                        response:{message:'Váratlan hiba történt',type:'error'},
                        loading:false
                    });
                }
                handleError(error);
            });
}