import * as React from 'react';
//Fluent UI
import {
  Callout,
  Text,
  Icon
} from 'office-ui-fabric-react';

//Theme
import style from './style';

const styles = style.contentStyles;


class InfoCallout extends React.Component{


  render(){
    const {buttonId,onClose,dateAvailable} = this.props;
    return (
      <Callout
      className={styles.callout}
      ariaLabelledBy='info-label'
      ariaDescribedBy='info-description'
      role="alertdialog"
      gapSpace={0}
      target={`#${buttonId}`}
      onDismiss={onClose}
      setInitialFocus
    >
     <Text block variant="xLarge" className={styles.title} id='info-label'>
        Jelmagyarázat
      </Text>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.winner} iconName="SquareShapeSolid"/>  
        <Text block variant="small" id='info-open-winner'>
          Nyertes pályázat.
        </Text>
      </div>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.info} iconName="Info"/>  
        <Text block variant="small" id='info-open-info'>
          Információ megtekintése a lezárt kiíráshoz. 
        </Text>
      </div>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.openFolder} iconName="OpenFolderHorizontal"/>  
        <Text block variant="small" id='info-open-folder'>
          Kiírás megnyitása. (További információk, interakciók megtekintése)
        </Text>
      </div>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.changes} iconName="PageHeaderEdit"/>  
        <Text block variant="small" id='info-tender-changes'>
          Az utolsó megtekintés óta változott a pályázati kiírás vagy a hozzá feltötltött file-ok.
        </Text>
      </div>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.changes} iconName="PageEdit"/>  
        <Text block variant="small" id='info-offer-changes'>
          Az utolsó megtekintés óta változtak a beadott pályázati anyagok vagy ajánlat.
        </Text>
      </div>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.new} iconName="AddTo"/>  
        <Text block variant="small" id='info-new-tender'>
          Új pályázati kiírás
        </Text>
      </div>

    </Callout>
  );
  }
};

export default InfoCallout;