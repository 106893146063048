import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    GetGroups: 'inner/get-offer-groups',
    NoticeChanges:'inner/offer-changes/notice'
}


export const apiRequest = {
    getGroups,
    noticeChanges
};

async function getGroups(parent,tenderId) {
    await new Api().call.get(`${Actions.GetGroups}/${tenderId}`).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                items:res,
                loading:false
            }));
        }).catch(handleError);
}

async function noticeChanges(parent,model) {
    const {onReceivedGroup} = parent.props;
    await new Api().call.post(`${Actions.NoticeChanges}/${model.id}`).then(handleResponse)
        .then(res => {
        }).catch(handleError);
}
