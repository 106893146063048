import * as React from 'react';
//Material UI
import {Divider, Grid} from '@material-ui/core';
//Fluent UI
import { DefaultButton, Label } from '@fluentui/react';
import {
  Spinner,
  TextField,
  Modal,
  IconButton
} from 'office-ui-fabric-react';
import {apiRequest} from './apiRequest';
//Theme
import style from './style';


const cancelIcon = { iconName: 'Cancel' };

class ViewPartnerContacts extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //editable
      partner:{...this.props.selectedPartner,contacts:[]},
      //request
      loading:true
    };

  }

  componentDidMount(){
    const {selectedPartner} = this.props;
    apiRequest.getContacts(this,selectedPartner.key);
  }


  render(){
    const {partner,loading} = this.state;
    const {open,hideModal,selectedPartner}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>{selectedPartner.name} - kapcsolattartók</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false && <div className={style.contentStyles.body}>
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <div style={{display:'flex'}}>
                  <Label>Kapcsolattartók:</Label>
              </div>
              {partner.contacts.length>0 && partner.contacts.map((contact,index)=>(
                <div key={index}>
                     <TextField
                        styles={{
                            fieldGroup:{
                                marginBottom:'5px'
                            },
                            root:{width:'100%'}
                        }}
                        readOnly
                        label="Email"
                        value={partner.contacts[index].email} 
                        name={`contact-email-${index}`} 
                    />
                    <Divider/>
                </div>
                   
              ))}
              {partner.contacts.length<1 &&
              <div style={{textAlign:'center'}}>
                <Label>Üres a kapcsolattartók lista</Label>
              </div>
              }
                 
            </Grid>
          </Grid>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Betöltés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
  
};

export default ViewPartnerContacts;