import { BehaviorSubject } from 'rxjs';
import Cookies from 'js-cookie';
//Components
//import { NotificationBar } from 'components';
import {Api,handleResponse,handleError,handleRefresh} from '../api';
import {Constants,UserStore} from '../stores';

//Actions
const Actions = {
    SignIn: 'auth/login',
    Logout:'auth/revoke-token',
    RefreshToken:'auth/refresh-token'
}

const remainingTimeSubject = new BehaviorSubject(null);
var time = null;

export const authenticationService = {
    login,
    logout,
    logoutForced,
    refreshToken,
    remainingTimeSubject: remainingTimeSubject,
    remainingTime: remainingTimeSubject.asObservable(),
    get remainingTimeValue() { return remainingTimeSubject.value }
};


async function login(userName, password) {
    const history = Constants.getHistory();

    const model = { userName,password };
    await new Api().callAnonymus.post(Actions.SignIn, model, {withCredentials: true}).then(value => {
        UserStore.setUser(value.data);
        UserStore.setLoggedInStatus('LOGGED_IN');
        UserStore.setRequest(false);
        history.push(value.data.redirectTo);
        //NotificationBar('Sikeres bejelentkezés', 'top', 'right', 2000, 'success', null);
    }).catch(error => {
        UserStore.setRequest(false);
        throw error;
    });
}

async function logout() {
    const history = Constants.getHistory();
    await new Api().call.post(Actions.Logout,{},{withCredentials: true}).then(value => {
        UserStore.setLoggedInStatus('NOT_LOGGED_IN');
        UserStore.setRequest(false);
        UserStore.setUser(null);
        history.push('/sign-in');
    }).catch(error => {
        console.error(error);
    });
}

async function logoutForced() {
    const history = Constants.getHistory();
        UserStore.setLoggedInStatus('SESSION_IS_OVER');
        UserStore.setRequest(false);
        UserStore.setUser(null);
        history.push('/sign-in');
}


async function refreshToken() {
    const history = Constants.getHistory();
    await new Api().callAnonymus.post(Actions.RefreshToken,null,{withCredentials: true}).then(value => {
        UserStore.setUser(value.data);
        UserStore.setLoggedInStatus('LOGGED_IN');
        UserStore.setRequest(false);
        //NotificationBar('Sikeres bejelentkezés', 'top', 'right', 2000, 'success', null);
    }).catch(error => {
        handleError(error);
        if(UserStore.getLoggedInStatusValue()==='LOGGED_IN' || UserStore.getLoggedInStatusValue()==='SESSION_IS_OVER'){
            UserStore.setLoggedInStatus('SESSION_IS_OVER');
        }else{
            UserStore.setLoggedInStatus('NOT_LOGGED_IN');
        }
        UserStore.setRequest(false);
        UserStore.setUser(null);

    });
}