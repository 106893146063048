import React,{Component} from 'react';
//MaterialUI
import {Avatar,CssBaseline,Paper,Link,Grid,Box,Typography, withStyles} from '@material-ui/core';
//FluentUI
import { TextField,PrimaryButton,Label,MessageBar,MessageBarType,Spinner,SpinnerSize } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
//Model
import SignInModel from './model';
import {UserStore} from '../../stores';
//Theme
import style from './style';
//Services
import {authenticationService} from '../../services';


//Copyright panel 
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://merkbau.hu/">
        Merkbau
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
//Error panel for the messages 
function ErrorPanel(props){
  const {message,style} = props;
  return ( 
    <MessageBar
      styles={style}
      messageBarType={MessageBarType.error}
      isMultiline={false}
    >
      {message}
    </MessageBar>
  )
}
//Warning panel for the session over
function WarningPanel(props){
  const {style} = props;
  return ( 
    <MessageBar
      styles={style}
      messageBarType={MessageBarType.warning}
      isMultiline={false}
    >
      Lejárt a munkamenet, kérlek jelentkezz be újra! 
    </MessageBar>
  )
}


class SignIn extends Component{

  constructor(props){
    super(props)
    this.state={
      user:'',
      pwd:'',
      validation:null,
      warning:null,
      error:null,
      request:false,
      loggedInStatus:UserStore.getLoggedInStatusValue()
    }

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.enterFunction = this.enterFunction.bind(this);
  }

  componentDidMount(){
    document.addEventListener("keydown", this.enterFunction, false);

       //Subscribe logged in status value ('LOGGED_IN, NOT_LOGGED_IN')
       UserStore.getLoggedInStatus().subscribe(res => this.setState({
        loggedInStatus: res,
       }));
       if(this.state.loggedInStatus==='SESSION_IS_OVER'){
        this.setState({
          warning:{
            style:style.fluentUI.warningPanelIn,
            isValid:false
          }
        });
       }
       
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.enterFunction, false);
  }

  enterFunction(event){
    if(event.keyCode === 13) {
      this.handleSubmit();
    }
  }



  componentDidUpdate(prevProps,prevState){
    const {user,pwd,validation,error,warning,loggedInStatus} = this.state;
    //Update error panel if user or pwd state is missing 
    if(prevState.pwd!==pwd || prevState.user!==user){
      if(validation!==null && validation.isValid!==true){
        this.setState({
          validation:{
            style:style.fluentUI.errorPanelOut,
            isValid:true,
            message:''
          },
          warning:{
            style:style.fluentUI.warningPanelOut
          }
        });
      }
      if(warning!==null && warning.validation!==true){
        this.setState({
          warning:{
            style:style.fluentUI.warningPanelOut,
            isValid:true,
          }
        });
      }
      //Update error panel if gets error from the server 
      if(error!==null && error.isValid!==true){
        this.setState({
          error:{
            style:style.fluentUI.errorPanelOut,
            isValid:true,
            message:''
          }
        });
      }
    }
  }

  //Handle textbox changes
  handleTextChange(event){
      const name = event.target.name;
      const value = event.target.value;
      this.setState({
        [name]:value
      });
  }

  //Handle login button click
  handleSubmit(){
    const {user,pwd} = this.state;
    if(user==='' || pwd===''){
      this.setState({
        validation:{
          style:style.fluentUI.errorPanelIn,
          isValid:false,
          message:'Mindkét mező kitöltése kötelező.'
        }
      });
    }else{
      //Model for the login
      var model = new SignInModel(user,pwd);
      var errorState=null;
      this.setState({
        request:true
      });
        authenticationService.login(model.userName,model.password).catch(error => {
        //Handle error cases
        try{
          //Write out server response message to the page
          if(error.response && error.response.data && error.response.data.message){
              errorState={
                isValid:false,
                style:style.fluentUI.errorPanelIn,
                message:error.response.data.message
              }     
          }
          //Write out 'Netwokr Error' message to the page
          else if(error.toJSON().message==='Network Error')
          {
            errorState={
                isValid:false,
                style:style.fluentUI.errorPanelIn,
                message:'A kiszolgáló nem elérhető'
            }
          }
          //Unhandled error 
          else{
            errorState={
                isValid:false,
                style:style.fluentUI.errorPanelIn,
                message:'Váratlan hiba történt'
              }
          }
        }
        //Unhandled error 
        catch(exception){
          errorState={
                isValid:false,
                style:style.fluentUI.errorPanelIn,
                message:'Váratlan hiba történt'
              }
        }
        //Set error and request state 
        this.setState({
            error:errorState,
            request:false
        });
      });
   
    }
  }

render(){
  const {classes} = this.props
  const {user,pwd,validation,warning,request,error,loggedInStatus} = this.state;
  return(
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={5} md={9} component={Paper} className={classes.image} />
      <Grid item xs={12} sm={7} md={3} component={Paper} elevation={6} square className={classes.sideImage}>
            <div className={classes.paper}> 
              <Avatar variant="rounded" src="images/merkbau-logo.png" className={classes.logo}/> 
              <div className={classes.titleDiv}>
              <Label id="title" style={style.fluentUI.title}>
                Bejelentkezés
              </Label>
              <Avatar className={classes.avatar}>
                  <Icon iconName="Lock" />
              </Avatar>
              </div>
             

              <form className={classes.form} noValidate>
                  {validation!==null &&            
                  <ErrorPanel style={validation.style} message={validation.message} />
                  }
                  {warning!==null &&
                  <WarningPanel style={warning.style} />
                  }
                  {error!==null &&            
                  <ErrorPanel style={error.style} message={error.message} />
                  }                  
                  <TextField
                      onChange={this.handleTextChange}
                      name="user"
                      value={user} 
                      id="username" 
                      label="Felhasználónév"               
                  />
                  <TextField
                      onChange={this.handleTextChange}
                      value={pwd}  
                      id="password"
                      name="pwd" 
                      label="Jelszó" 
                      type="password" 
                      canRevealPassword
                  />
                  <PrimaryButton
                      onClick={this.handleSubmit}             
                      id="login-button" 
                      style={style.fluentUI.loginButton} 
                      text={
                        request ?  
                        <Spinner size={SpinnerSize.medium} /> : 
                        'Bejelentkezés'
                      }
                      disabled={request}
                      allowDisabledFocus 
                  />
                <Grid container>
                  <Grid item xs>
                    <Link id="helper-link" href="#" variant="body2">
                      Nem tud belépni? Lépjen velünk kapcsolatba
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={8}>
                  <Copyright />                
                </Box>
              </form>
            </div>                          
      </Grid>
    </Grid>
  );
  }
}
export default withStyles(style.materialUI)(SignIn);