import * as React from 'react';
//Material UI
import {Grid} from '@material-ui/core';
//Fluent UI
import {
  TextField,
  Modal,
  IconButton,
  Spinner,
  MessageBar,
  MessageBarType
} from 'office-ui-fabric-react';
//Theme
import style from './style';
import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';
//Api
import {apiRequest} from './apiRequest';
//Icons
const cancelIcon = { iconName: 'Cancel' };

class EditFile extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //editable file
      editFile:this.props.file,
      //request
      loading:false,
      response:null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
  }

  //*Handle user inputs*//
  onTextChange(event){
    const {editFile} = this.state;
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      editFile:{...editFile,[name]:value}
    });
  }
  //**//
  
  //*Save*//
  handleSave(){
    const {editFile} = this.state;
    const {offer} = this.props;

    //Init states
    this.setState({
      loading:true,
      response:null,
    });
    //Post model
    const model = {fileId:editFile.id,offerId:offer.id,name:editFile.name,isFolder:editFile.isFolder}
    apiRequest.modifyFile(this,model);
  }
  //**//

  //*Helper methods*//
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }

  validate(){
    const {editFile} = this.state;
    if(editFile.name!==null && editFile.name!==undefined && editFile.name!==''){
      return true;
    }
    return false;
  }
  //**//

  render(){
    const {editFile,response,loading} = this.state;
    const {open,hideModal,file}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>{file.name}</div>
            <div className={style.contentStyles.titleIndex}>Módosítás</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false &&  
        <div className={style.contentStyles.body}>
          {response!==null &&
            <MessageBar
              messageBarType={this.setMessageBarType(response.type)}
              isMultiline={false}
            >
              {response.message}
            </MessageBar>
          }
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <TextField
                required 
                onChange={this.onTextChange} 
                name="name" 
                value={editFile.name} 
                label="Megnevezés" 
              />
            </Grid>
          </Grid>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Betöltés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton
            disabled={!this.validate() || loading} 
            onClick={this.handleSave} 
            style={{marginRight:'5px'}}
          >
            Mentés
          </PrimaryButton>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>     
      </Modal>
  );
  }
  
};

export default EditFile;