import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Get: 'inner/get-notifications',
    GetLastRun:'inner/get-email-service-last-run',
}


export const apiRequest = {
    getNotifications,
    getLastRun
};

async function getNotifications(parent,tenderId) {
    await new Api().call.get(`${Actions.Get}/${tenderId}`).then(handleResponse)
        .then(res => {
            parent.setState({
                items:res,
                loading:false
              });
        }).catch(handleError);
}

async function getLastRun(parent) {
    await new Api().call.get(Actions.GetLastRun).then(handleResponse)
        .then(res => {
            parent.setState({
                lastRun:res==='' ? null : res
              });
        }).catch(handleError);
}