import * as React from 'react';
//Fluent UI
import {
  Modal,
  IconButton,
  Spinner
} from 'office-ui-fabric-react';
import { Icon } from '@fluentui/react/lib/Icon';
import { DefaultButton } from '@fluentui/react';
//Theme
import style from './style';
//Api
import {apiRequest} from './apiRequest';
//Icons
const cancelIcon = { iconName: 'Cancel' };


class OfferChanges extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //changes
      changes:[],
      fileChanges:[],
      //loading
      loading:{
        changes:true,
        fileChanges:true
      }
    }
  }

  componentDidMount(){
    const {offer} = this.props;

    apiRequest.getChanges(this,offer.id);
    apiRequest.getFileChanges(this,offer.id)

  }

  getEvent = (event) =>{
    if(event==='Created'){
      return <Icon className={style.contentStyles.newIcon} iconName="Add"/>
    }else if(event==='Deleted'){
      return <Icon className={style.contentStyles.deleteIcon} iconName="Delete"/>
    }else if(event==='NewVersion'){
      return <Icon className={style.contentStyles.editIcon} iconName="Edit"/>
    }

    return 'Not Found';
  }

  render(){
    const {changes,fileChanges,loading} = this.state;
    const {open,hideModal,offer}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>{offer.name}</div>
            <div className={style.contentStyles.titleIndex}>Változások</div>
          </div>
          <IconButton           
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        <div className={style.contentStyles.body}>
          <h4 style={{textAlign:'center'}}>Ajánlatban történő változások</h4>        
          {loading.changes ? <Spinner label="Betöltés..."/> :
        <table style={{margin:'auto'}}>
          <thead>
            <tr>
              <th colSpan={5} className={style.contentStyles.tableHeader}></th>
            </tr>
            <tr>
              <th className={style.contentStyles.borderLeft} align="center">Ajánlat értéke</th>
              <th className={style.contentStyles.borderX} align="center">Valuta</th>
              <th className={style.contentStyles.borderRight} align="center">Érvényesség</th>
              <th className={style.contentStyles.borderRight} align="center">Módosító</th>
              <th className={style.contentStyles.borderRight} align="center">Időpont</th>
            </tr>
            <tr>
              <th colSpan={5} className={style.contentStyles.tableHeader}></th>
            </tr>
          </thead>
          <tbody>
            {changes.map((row,index)=>
            <tr key={index}>
              {row.columns.map((col,index)=>
               <td key={index} className={col.event==='Changed' ? style.contentStyles.markedCol : null } align="center">{col.value}</td>
              )}
               <td align="center">{row.timeInString}</td>             
            </tr>
            )}
            {changes.length<1 &&  
            <tr>
               <td colSpan={5} align="center">Nem történt változás</td>            
            </tr>
            }    
             <tr>
              <td colSpan={5} className={style.contentStyles.tableHeader}></td>
            </tr>   
          </tbody>        
        </table>}
        <h4 style={{textAlign:'center'}}>Feltöltésekben történő változások</h4>
        {loading.fileChanges ? <Spinner label="Betöltés..."/> :
        <table style={{margin:'auto'}}>
          <thead>
            <tr>
              <th colSpan={4} className={style.contentStyles.tableHeader}></th>
            </tr>
            <tr>
              <th className={style.contentStyles.borderLeft}  align="center">File név</th>
              <th className={style.contentStyles.borderX} align="center">Művelet</th>
              <th className={style.contentStyles.borderRight} align="center">Módosító</th>
              <th className={style.contentStyles.borderRight} align="center">Időpont</th>
            </tr>
            <tr>
              <th colSpan={4} className={style.contentStyles.tableHeader}></th>
            </tr>
          </thead>
          <tbody>
            {fileChanges.map((row,index)=>
            <tr key={index}>
               <td align="center">{row.name}</td>
               <td align="center">{this.getEvent(row.event)}</td> 
               <td align="center">{row.userName}</td> 
               <td align="center">{row.timeInString}</td>              
            </tr>
            )}
            {fileChanges.length<1 &&  
            <tr>
               <td colSpan={4} align="center">Nem történt változás</td>            
            </tr>
            }    
            <tr>
              <td colSpan={4} className={style.contentStyles.tableHeader}></td>
            </tr>   
          </tbody>        
        </table>}
        </div>    
        <div className={style.contentStyles.action}>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>   
      </Modal>
  );
  }
  
};

export default OfferChanges;