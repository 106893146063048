import * as React from 'react';
//Material UI
import {Divider, Grid} from '@material-ui/core';
//Fluent UI
import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';
import {
  TextField,
  Modal,
  IconButton,
  MessageBarType,
  MessageBar,
  Spinner
} from 'office-ui-fabric-react';
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
import { SharedColors } from '@fluentui/theme';

const cancelIcon = { iconName: 'Cancel' };
const addIcon = { iconName: 'Add' };
const deleteIcon = { iconName: 'Delete',style:{color:SharedColors.red10}};

class AddPartner extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //partner
      partner:{name:'',contacts:[]},
      //response
      response:null,
      //request
      loading:false
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
    this.addNewContact = this.addNewContact.bind(this);
    this.onContactChange = this.onContactChange.bind(this);
    this.removeContact = this.removeContact.bind(this);
  }

  //*Handle user interactions*//
  onTextChange(event){
    const {partner} = this.state;
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
        partner:{...partner,[name]:value},
        response:null
    });
  }

  onContactChange(event,props,index){
    const {partner} = this.state;
    const value = event.target.value;
    var newArray = [...partner.contacts];
    var contact = newArray[index];
    contact={...contact,[props]:value,index};
    newArray[index] = contact;

    this.setState({
        partner:{...partner,contacts:newArray},
        response:null
    });

  }
  //**//

  //*Handle user actions*//
  handleSave(){
    const {partner} = this.state;
    this.setState({
      response:null,
      loading:true
    });
    apiRequest.create(this,partner);
  }

  
  
  addNewContact(){
    const {partner} = this.state;
    var newArray = [...partner.contacts];
    newArray.push({email:''});

    this.setState({
        partner:{...partner,contacts:newArray},
        response:null
    });
  }

  removeContact(index){
    const {partner} = this.state;
    var newArray = [...partner.contacts];
    newArray.splice(index, 1);

    this.setState({
        partner:{...partner,contacts:newArray},
        response:null
    });
  }
  //**//

  //*Helper methods*//

  validateEmail(value){
    if(value.includes('@')){
        return true
    }else{
        return false;
    }

  }

  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }

  validate(){
    const {partner} = this.state;
    if(partner!==null && partner!==undefined){
        if(partner.name!==null && partner.name!==undefined && partner.name!==''){
            if(partner.contacts!==null && partner.contacts!==undefined && partner.contacts.length>0){
                if(partner.contacts.filter(contact=> contact.email===null || contact.email===undefined ||  contact.email==='').length>0){
                    return false;
                }
                if(partner.contacts.filter(contact=> contact.email!==null && contact.email!==undefined && contact.email!=='' && !contact.email.includes('@')).length>0){
                    return false;
                }
            }
            return true;
        }
    }
    return false;
  }

  //**//

  render(){
    const {partner,loading,response} = this.state;
    const {open,hideModal}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>Új partner létrehozása</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false && <div className={style.contentStyles.body}>
          {response!==null &&
              <MessageBar
                messageBarType={this.setMessageBarType(response.type)}
                isMultiline={false}
              >
              {response.message}
              </MessageBar>
            }
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <TextField required onChange={this.onTextChange} name="name" value={partner.name} label="Megnevezés" />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <div style={{display:'flex'}}>
                  <Label>Kapcsolattartók:</Label>
                  <IconButton onClick={this.addNewContact} iconProps={addIcon}/>
              </div>
              {partner.contacts.map((contact,index)=>(<div>
                     <TextField
                        styles={{
                            fieldGroup:{
                                marginBottom:'5px'
                            },
                            root:{width:'100%'}
                        }}
                        required
                        label="Email"
                        errorMessage={this.validateEmail(partner.contacts[index].email) ? null : "Nem megfelelő e-mail cím" }
                        onChange={(event)=>this.onContactChange(event,'email',index)} 
                        value={partner.contacts[index].email} 
                        name={`contact-email-${index}`} 
                    />
                    <div style={{textAlign:'right'}}><IconButton onClick={()=>this.removeContact(index)} iconProps={deleteIcon}/></div> <Divider/></div>
                   
              ))}
                 
            </Grid>
          </Grid>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Betöltés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton disabled={!this.validate() || loading} onClick={this.handleSave} style={{marginRight:'5px'}}>
            Mentés
          </PrimaryButton>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
  
};

export default AddPartner;