import * as React from 'react';
import { DatePicker, DayOfWeek } from 'office-ui-fabric-react/lib/DatePicker';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

const DayPickerStrings = {
  months: [
    'Január',
    'Február',
    'Március',
    'Április',
    'Május',
    'Június',
    'Július',
    'Augusztus',
    'Szeptember',
    'Október',
    'November',
    'December',
  ],

  shortMonths: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec'],

  days: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],

  shorterDays: ['Vas', 'Hé', 'Kedd', 'Szer', 'Csüt', 'Pén', 'Szom'],

  shortDays: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],

  goToToday: 'Ugorj a mai napra',
  prevMonthAriaLabel: 'Ugorj az előző hónapra',
  nextMonthAriaLabel: 'Ugorj a következő hónapra',
  prevYearAriaLabel: 'Ugorj az előző évre',
  nextYearAriaLabel: 'Ugorj a következő évre',
  closeButtonAriaLabel: 'Bezárás',
  monthPickerHeaderAriaLabel: '{0}, válaszd ki a hónaphoz',
  yearPickerHeaderAriaLabel: '{0}, válaszd ki az évhez',
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
});

const onFormatDate = (date) => {
  return !date ? '' : DayPickerStrings.shorterDays[date.getDay()] + ' ' +  DayPickerStrings.shortMonths[date.getMonth()] + ' '+ date.getDate() +' ' + (date.getFullYear());
};

const desc = 'This field is required. One of the support input formats is year dash month dash day.';
const firstDayOfWeek = DayOfWeek.Monday;

const DateSelect = (props) => {
  const {label,disabled,onChange,value,name,minDate,errorMessage,required} = props;
  //const [value, setValue] = React.useState(null);

  const onSelectDate = (date) => {
    onChange(name,date);
  };

  return (
      <DatePicker
        label={label}
        disabled={disabled}
        isRequired={false}
        placeholder="Válaszd ki a dátumot..."
        allowTextInput={false}
        textField={{
          errorMessage:errorMessage,
          required:required===true ? true : false
        }}
        minDate={minDate}
        ariaLabel={desc}
        firstDayOfWeek={firstDayOfWeek}
        strings={DayPickerStrings}
        value={value}
        onSelectDate={onSelectDate}
        formatDate={onFormatDate}
      />

  );
};
export default DateSelect;