import { createMuiTheme } from '@material-ui/core/styles';
import {lightTheme} from '../../../../themes';
import { FontSizes, NeutralColors, SharedColors } from '@fluentui/theme';
import { AnimationVariables,AnimationStyles } from '@fluentui/react';
import { mergeStyles } from '@uifabric/merge-styles';

const theme = createMuiTheme();

const materialUI = ({
    root: {
        boxShadow: 'none',
        backgroundImage: 'url(/images/login-side-screen.png)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderBottom: `4px solid ${lightTheme.palette.themePrimary}`,
      },
      flexGrow: {
        flexGrow: 1
      },
      signOutButton: {
      marginLeft: theme.spacing(1)
      },
      title:{
        marginLeft: theme.spacing(1),
        color:lightTheme.palette.neutralSecondary,
        fontSize: FontSizes.size16
      }
  });

const menu_persona = mergeStyles({
    display:'flex',
    borderRadius:'0.5rem',
    alignItems:'center',
    selectors: {
      ':hover': {
        cursor:'pointer',
        backgroundColor:NeutralColors.gray20
      },
    },
    paddingLeft:"1.0rem",
});

const small_name = mergeStyles({
  selectors: {
    ':hover': {
      cursor:'pointer',
    }
  },

  paddingRight:"0.5rem"
});
  
const fluentUI ={
  callout: {
    width: 300,
    maxWidth: '90%',
  },
  persona:{
    
  }
}

const styles={
    materialUI,
    fluentUI,
    menu_persona,
    small_name
};


  export default styles;