import { createMuiTheme } from '@material-ui/core/styles';
import {lightTheme} from '../../../themes';
import { FontSizes } from '@fluentui/theme';
import { AnimationVariables,AnimationStyles } from '@fluentui/react';

const theme = createMuiTheme();

const materialUI = ({
    root:{
        padding: theme.spacing(4)
    },
    activitiesWrapper:{
        position: 'relative', 
        maxHeight: 'inherit',
        [theme.breakpoints.down('sm')]: {
            height: '90px',
        },
        [theme.breakpoints.up('md')]: {
            height: '400px',
        },
    },
    badge:{
        backgroundColor:lightTheme.palette.themePrimary

    }
});

  
const fluentUI ={
   card:{
        title:{
            padding:theme.spacing(1),
            fontSize: FontSizes.size16
        }
   },
   panelHeader:{
    borderTop: `3px solid ${lightTheme.palette.themePrimary}`
   }
}

const styles={
    materialUI,
    fluentUI
};


  export default styles;