import * as React from 'react';
//Material UI
import {Grid} from '@material-ui/core';
import {
  Spinner,
  MessageBar,
  MessageBarType,
  TextField,
  Modal,
  IconButton,
  Toggle
} from 'office-ui-fabric-react';
import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';
//Components
import {DateSelect, PartnerSelect} from '../../../../../../components/Inputs';
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
//Icons
const cancelIcon = { iconName: 'Cancel' };

class EditTender extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //editable
      editedTender:this.props.tender,
      //request
      loading:false,
      //response
      response:null,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
    this.validateDate = this.validateDate.bind(this);
  }

  //*Handle user interactions*//
  onTextChange(event){
    const {editedTender} = this.state;
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      editedTender:{...editedTender,[name]:value},
      response:null,
    });
  }

  onDateChange(name,newDate){
    const {editedTender} = this.state;
    this.setState({
      editedTender:{...editedTender,[name]:newDate},
      response:null,
    });
  }

  onSelectChange(items){
    const {editedTender} = this.state;
    this.setState({
      editedTender:{...editedTender,partners:items},
      response:null,
    });
  }

  onToggleChange(event,checked){
    const {editedTender} = this.state;
    this.setState({
      editedTender:{...editedTender,showBestOffer:checked},
      response:null,
    });
  }
  //**//


  //*Handle actions*//
  handleSave(){
    const {editedTender} = this.state;

    //Init states
    this.setState({
      loading:true,
      response:null,
    });

    let data = {...editedTender};
    //Convert dates to LocalDate
    data.startDate = this.getLocalDate(new Date(data.startDate.toString()));
    data.endDate = this.getLocalDate(new Date(data.endDate.toString()));

    apiRequest.edit(this,data);
  }
  //**//


  //*Helper methods*//

  //Helper method to convert Date to UTC
  getLocalDate(date){
    let day = date.getDate();
    let mounth = date.getMonth()+1;
    let year = date.getFullYear();
    return `${day}/${mounth}/${year}`
  }
    
  validate(){
    const {editedTender} = this.state;
    if(editedTender.partners!==null && editedTender.partners.length>0 && editedTender.offerNumber!=='' && editedTender.offerNumber!==undefined && new Date(editedTender.startDate)<=new Date(editedTender.endDate) && editedTender.name!==null && editedTender.name!==undefined && editedTender.name!=='')
    {
      return true;
    }
    return false;

  }
  
  validateDate(){
    const {editedTender} = this.state;
    if(editedTender.startDate==='' || editedTender.endDate===''){
      return true;
    }
    if(new Date(editedTender.startDate)<=new Date(editedTender.endDate))
    {
      return true;
    }
    return false;
  }

  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  //**//

  render(){
    const {editedTender,loading,response} = this.state;
    const {open,hideModal,tender,partners}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>{tender.name}</div>
            <div className={style.contentStyles.titleIndex}>Módosítás</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false &&  <div className={style.contentStyles.body}>
            {response!==null &&
                <MessageBar
                  messageBarType={this.setMessageBarType(response.type)}
                  isMultiline={false}
                >
                {response.message}
                </MessageBar>
            }
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
              <TextField required onChange={this.onTextChange} name="name" value={editedTender.name} label="Megnevezés" />
            </Grid>
            <Grid xs={12} sm={12} md={9} lg={12} xl={12} item>
              <TextField onChange={this.onTextChange} name="description" multiline={true} value={editedTender.description} label="Leírás" />
            </Grid>
            <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
              <TextField onChange={this.onTextChange} name="workNumber" value={editedTender.workNumber} label="Munkaszám" />
            </Grid>
            <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
              <TextField required={true} onChange={this.onTextChange} name="offerNumber" value={editedTender.offerNumber} label="Ajánlati szám" />
            </Grid>
            <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
              <DateSelect required={true} name="startDate" onChange={this.onDateChange} value={new Date(editedTender.startDate)} label="Beadási időszak kezdete"/>
            </Grid>
            <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
              <DateSelect  required={true} errorMessage={this.validateDate() ?  null : 'Nem megengedett érték' } minDate={new Date(editedTender.startDate)} name="endDate" onChange={this.onDateChange} value={new Date(editedTender.endDate)} label="Beadási időszak vége"/>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                <Label>Partnerek <span style={{color:'rgb(164, 38, 44)'}}>*</span></Label>
                <PartnerSelect placeholder="Kereséshez kezdj el gépelni..."  onChange={this.onSelectChange} selected={editedTender.partners} tags={partners} />            
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Toggle checked={editedTender.showBestOffer} onChange={this.onToggleChange} label="Legjobb ajánlat mutatása" defaultChecked onText="Igen" offText="Nem" />
              </Grid> 
          </Grid>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Betöltés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton disabled={!this.validate() || loading} onClick={this.handleSave} style={{marginRight:'5px'}}>
            Mentés
          </PrimaryButton>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
  
};

export default EditTender;