import * as React from 'react';
//Fluent UI
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import {
  Modal,
  IconButton,
  Label,
  Spinner,
  MessageBar,
  MessageBarType
} from 'office-ui-fabric-react';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
//Icons
const cancelIcon = { iconName: 'Cancel' };

class OfferDelete extends React.Component{

  constructor(props){
    super(props);

    this.state={
      //request
      loading:false,
      response:null
    }

    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  //*Handle user interactions*//
  handleCancel(){
    const {hideModal}=this.props;
    hideModal();
  }

  handleOk(){
    const {offer,tender}=this.props;
    this.setState({
      loading:true,
      response:null
    });
    const data = {tenderId:tender.id,offerId:offer.id};
    apiRequest.deleteOffer(this,data);
  }
  //**//

  //*Helper methods*//
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  //**//

  render(){
    const {loading,response} = this.state;
    const {open,hideModal}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>Ajánlat törlése</div>
            <div className={style.contentStyles.titleIndex}>Megerősítés</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false && <div className={style.contentStyles.body}>
          {response!==null &&
            <MessageBar
              messageBarType={this.setMessageBarType(response.type)}
              isMultiline={false}
            >
              {response.message}
            </MessageBar>
          }
          <Label>Biztos törölni szeretnéd az ajánlatot ? Törlés esetén a feltöltött file-ok is törlésre kerülnek! </Label>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Törlés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton 
            onClick={this.handleOk} 
            style={{marginRight:'5px'}}
          >
            Igen
          </PrimaryButton>
          <DefaultButton onClick={this.handleCancel}>
            Nem
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
};

export default OfferDelete;