import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const materialUI = ({
    root:{
        padding: theme.spacing(4)
    }
});

const styles={
    materialUI
};


  export default styles;