import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Material UI
import { AppBar, Toolbar, Hidden, withStyles } from '@material-ui/core';
//FluentUI
import { DefaultButton,Label,Persona,PersonaSize, PrimaryButton,Callout } from '@fluentui/react';
//Themes
import style from './style';
//Services
import {authenticationService} from '../../../../services';
//Components
import {UserStore} from '../../../../stores';



const menuIcon = { iconName: 'CollapseMenu' };


class Topbar extends Component{


  constructor(props){
    super(props)

    this.userName = UserStore.getUserValue()!=null && UserStore.getUserValue()!==undefined 
    ? UserStore.getUserValue().userName : '...Felhasználó';

    this.state={
      menuProps:null,
      menu:false
    }

    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount(){
    this.setState({
      menuProps:{
        items: [
          {
            key: 'logOut',
            text: 'Kijelentkezés',
            iconProps: { iconName: 'SignOut' },
            onClick:()=>this.handleLogout()
          },
        ],
        directionalHintFixed: true,
      }
    })
  }

  componentWillUnmount(){
    this.state={
      menuProps:null,
    }
  }

  handleLogout(){
    authenticationService.logout();
  }

  closeMenu = () =>{
    this.setState({
      menu:false
    });
  }

  openMenu = () =>{
    this.setState({
      menu:true
    });
  }


  render(){
    const {classes,rest} = this.props;
    const {menuProps,menu} = this.state;
    return (
      <AppBar
        {...rest}
        className={clsx(classes.root)}
      >
        <Toolbar>
          <RouterLink to="/">
             <img
              style={{height:'41px',width:'137px'}}
              alt="Logo"
              src={"/images/merkbau-logo-horizontal.png"}
            />
          </RouterLink>
          <Hidden smDown>
            <Label style={style.materialUI.title}>ARendszer</Label>
          </Hidden>
          <Hidden mdUp>
            <Label style={style.materialUI.title}>AR</Label>
          </Hidden>
          
                 
          <div className={classes.flexGrow} />
          <div id="menu_persona" className={style.menu_persona} onClick={this.openMenu}>
            <Hidden smDown>
              <Label className={style.small_name}>{this.userName}</Label>
            </Hidden>
            <Persona        
              size={PersonaSize.size24}          
            />  
          </div>
      
           {menu && <Callout
           className={style.fluentUI.callout}
            gapSpace={0}
            target={`#menu_persona`}
            onDismiss={this.closeMenu}
            setInitialFocus
          >
            <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
              <img             
                style={{height:'41px',width:'137px'}}
                alt="Logo"
                src={"/images/merkbau-logo-horizontal.png"}
              />
              <PrimaryButton onClick={this.handleLogout} style={{paddingTop:'20px',paddingBottom:'20px'}}>
                Kijelentkezés
              </PrimaryButton>
            </div>
            <div style={{padding:'20px',display:'flex',alignItems:'center'}}>
              <Persona        
                size={PersonaSize.size48}          
              /> 
              <div>
                {(this.fullName!==null && this.fullName!==undefined) && <Label>{this.fullName}</Label>}
                <Label>{this.userName}</Label>
              </div>
            </div>
          </Callout>}           
         </Toolbar>
      </AppBar>
    );
  }
  
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default withStyles(style.materialUI)(Topbar);
