import * as type from '../types';

const initialState = {
    response:null,
    loading:true,
    error:null
}
export default function files(state = initialState,action){
    switch(action.type){
        case type.FILE_DOWNLOAD_REQUESTED:
            return{
                ...state,
                loading:true
            };
        case type.FILE_DOWNLOAD_SUCCEEDED:
            return{
                ...state,
                loading:false,
                response:action.response
        };
        case type.FILE_DOWNLOAD_FAILED:
         return{
                ...state,
                loading:false,
                error:action.message
        };
        default:
            return state;
    }
}