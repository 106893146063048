import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    GetTenders: 'partner/get-tenders',
}


export const apiRequest = {
    getTenders,
};

async function getTenders(parent,partnerId) {
    await new Api().call.get(`${Actions.GetTenders}/${partnerId}`).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                items:res,
                loading:false
            }));
        }).catch(handleError);
}

