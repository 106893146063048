import * as React from 'react';
//Fluent UI
import {
  Callout,
  Text,
  Icon
} from 'office-ui-fabric-react';

//Theme
import style from './style';

const styles = style.contentStyles;


class InfoCallout extends React.Component{


  render(){
    const {buttonId,onClose,dateAvailable} = this.props;
    return (
      <Callout
      className={styles.callout}
      ariaLabelledBy='info-label'
      ariaDescribedBy='info-description'
      role="alertdialog"
      gapSpace={0}
      target={`#${buttonId}`}
      onDismiss={onClose}
      setInitialFocus
    >
     <Text block variant="xLarge" className={styles.title} id='info-label'>
        Jelmagyarázat
      </Text>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
      <Icon className={styles.downloadAll} iconName="Installation"/>
      <Text block variant="small" id='info-hasWinner'>
        Összes file letöltése.
      </Text>
      </div>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.download} iconName="Download"/>  
        <Text block variant="small" id='info-open-folder'>
        File letöltése.
        </Text>
      </div>
    </Callout>
  );
  }
};

export default InfoCallout;