class Partner
{
    name;
    key;

    constructor(key,name)
    {
        this.key = key;
        this.name = name;
    }

    getName(){
        return this.name
    }

    getKey(){
        return this.key;
    }
    
}

export default Partner;