import { createMuiTheme } from '@material-ui/core/styles';
import {lightTheme} from '../../themes';
import { FontSizes } from '@fluentui/theme';
import { AnimationVariables,AnimationStyles } from '@fluentui/react';

const theme = createMuiTheme();

const materialUI = ({
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxHeight:'20%'
    },
    image: {
      backgroundImage: 'url(/images/login-screen.png)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'right',
    },
    sideImage:{
      backgroundImage: 'url(/images/login-side-screen.png)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'right',
    },
    titleDiv:{
        display:'flex',
        alignItems:'center'
    },
    root: {
      height: '100vh',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor:lightTheme.palette.themePrimary
    },
    logo:{
        height:'100px',
        width:'100px'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      width:'100%',
      margin: theme.spacing(3, 0, 2),
    },
  });

  
const fluentUI ={
    loginButton:{
        width:'100%',
        marginTop:'5px',
        marginBottom:'5px'
    },
    title:{
        fontSize: FontSizes.size24
    },
    errorPanelIn:{
        root:{
            ...AnimationStyles.slideDownIn20,
        }
    },
    errorPanelOut:{
        root:{
            ...AnimationStyles.slideDownOut20,
        }
    },
    warningPanelIn:{
      root:{
          ...AnimationStyles.slideDownIn20,
      }
    },
    warningPanelOut:{
      root:{
          ...AnimationStyles.slideDownOut20,
      }
    }
}

const styles={
    fluentUI,
    materialUI
};


  export default styles;