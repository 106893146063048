
//Theme
import style from './style';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
//FluentUI
import { IconButton,Spinner } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

//Icons
const downloadIcon = { iconName: 'Download' };
const downloadAllIcon = {iconName:'Installation'}

const classNames=  mergeStyleSets(style.fluentUI.fileIconCell,style.fluentUI.fileIconHeaderIcon);


function setColumns(parent,type){
    switch(type){
      case 'empty':
        return [
          {
           key: 'column1',
           name: 'File Type',
           className: classNames.fileIconCell,
           iconClassName: classNames.fileIconHeaderIcon,
           ariaLabel: 'Column operations for File type, Press to sort on File type',
           iconName: 'Page',
           isIconOnly: true,
           fieldName: 'name',
           minWidth: 16,
           maxWidth: 16,
           onColumnClick: parent._onColumnClick,
           onRender: (item) => (
               <TooltipHost content={`${item.fileType} file`}>
                                 <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${getFileIcon(item.fileType,item.isFolder)}.svg`} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
               </TooltipHost>
               ),
         },
         {
           key: 'column2',
           name: 'Megnevezés',
           fieldName: 'name',
           minWidth: 250,
           maxWidth: 250,
           isRowHeader: true,
           isResizable: true,
           isSortedDescending: false,
           onColumnClick: parent._onColumnClick,
           data: 'string',
           isPadded: true,
         },
         {
           key: 'column3',
           name: 'Feltöltve',
           fieldName: 'dateCreatedValue',
           minWidth: 70,
           maxWidth: 90,
           isResizable: true,
           onColumnClick: parent._onColumnClick,
           data: 'number',
           onRender: (item) => {
             return <span>{item.dateCreated}</span>;
           },
           isPadded: true,
         },
         {
           key: 'column4',
           name: 'Módosítva',
           fieldName: 'dateModifiedValue',
           minWidth: 70,
           maxWidth: 90,
           isResizable: true,
           data: 'number',
           onColumnClick: parent._onColumnClick,
           onRender: (item) => {
             return <span>{item.dateModified}</span>;
           },
           isPadded: true,
         },
         {
           key: 'column5',
           name: 'Utolsó módosító',
           fieldName: 'modifiedBy',
           minWidth: 120,
           maxWidth: 120,
           isResizable: true,
           data: 'string',
           onColumnClick: parent._onColumnClick,
         },
         {
           key: 'column6',
           name: 'Letöltés',
           fieldName: 'download',
           style:style.fluentUI.fileIconCell,
           isIconOnly: true,
           minWidth: 16,
           maxWidth: 16,
           onColumnClick: parent._onColumnClick,
           onRender: (item) => (
              <div>
               <TooltipHost content="Letöltés">
                   <IconButton
                   onClick={()=>parent.handleDownload(item)}
                   iconProps={downloadIcon}
                   />        
               </TooltipHost>
              </div>
   
             
           ),
         },
   
       ]
        case 'default':
            return [
              {
               key: 'column1',
               name: 'File Type',
               className: classNames.fileIconCell,
               iconClassName: classNames.fileIconHeaderIcon,
               ariaLabel: 'Column operations for File type, Press to sort on File type',
               iconName: 'Page',
               isIconOnly: true,
               fieldName: 'name',
               minWidth: 16,
               maxWidth: 16,
               onColumnClick: parent._onColumnClick,
               onRender: (item) => (
                   <TooltipHost content={`${item.fileType} file`}>
                                     <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${getFileIcon(item.fileType,item.isFolder)}.svg`} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
                   </TooltipHost>
                   ),
             },
             {
               key: 'column2',
               name: 'Megnevezés',
               fieldName: 'name',
               minWidth: 250,
               maxWidth: 250,
               isRowHeader: true,
               isResizable: true,
               isSortedDescending: false,
               onColumnClick: parent._onColumnClick,
               data: 'string',
               isPadded: true,
             },
             {
               key: 'column3',
               name: 'Feltöltve',
               fieldName: 'dateCreatedValue',
               minWidth: 70,
               maxWidth: 90,
               isResizable: true,
               onColumnClick: parent._onColumnClick,
               data: 'number',
               onRender: (item) => {
                 return <span>{item.dateCreated}</span>;
               },
               isPadded: true,
             },
             {
               key: 'column4',
               name: 'Módosítva',
               fieldName: 'dateModifiedValue',
               minWidth: 70,
               maxWidth: 90,
               isResizable: true,
               data: 'number',
               onColumnClick: parent._onColumnClick,
               onRender: (item) => {
                 return <span>{item.dateModified}</span>;
               },
               isPadded: true,
             },
             {
               key: 'column5',
               name: 'Utolsó módosító',
               fieldName: 'modifiedBy',
               minWidth: 120,
               maxWidth: 120,
               isResizable: true,
               data: 'string',
               onColumnClick: parent._onColumnClick,
             },
             {
               key: 'column6',
               name:parent.state.zipIsProgress===true ?   
               <Spinner style={{justifyContent:"unset"}} label="Letöltés folyamatban..." ariaLive="assertive" labelPosition="right" /> :  
               <div>
                  <TooltipHost content="Összes letöltése">
                    <IconButton 
                      onClick={parent.handleDownloadAllFiles}  
                      iconProps={downloadAllIcon}
                    >
                    </IconButton>
                  </TooltipHost>
               </div>,
               fieldName: 'download',
               style:style.fluentUI.fileIconCell,
               minWidth: 16,
               maxWidth: 16,
               onColumnClick: parent._onColumnClick,
               onRender: (item) => (
                  <div>
                   <TooltipHost content="Letöltés">
                       <IconButton
                       disabled={parent.state.zipIsProgress}
                       onClick={()=>parent.handleDownload(item)}
                       iconProps={downloadIcon}
                       />        
                   </TooltipHost>
                  </div>
       
                 
               ),
             },     
           ]
           case 'downloadIsProgress':
            return [
              {
               key: 'column1',
               name: 'File Type',
               className: classNames.fileIconCell,
               iconClassName: classNames.fileIconHeaderIcon,
               ariaLabel: 'Column operations for File type, Press to sort on File type',
               iconName: 'Page',
               isIconOnly: true,
               fieldName: 'name',
               minWidth: 16,
               maxWidth: 16,
               onColumnClick: parent._onColumnClick,
               onRender: (item) => (
                   <TooltipHost content={`${item.fileType} file`}>
                                     <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${getFileIcon(item.fileType,item.isFolder)}.svg`} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
                   </TooltipHost>
                   ),
             },
             {
               key: 'column2',
               name: 'Megnevezés',
               fieldName: 'name',
               minWidth: 250,
               maxWidth: 250,
               isRowHeader: true,
               isResizable: true,
               isSortedDescending: false,
               onColumnClick: parent._onColumnClick,
               data: 'string',
               isPadded: true,
             },
             {
               key: 'column3',
               name: 'Feltöltve',
               fieldName: 'dateCreatedValue',
               minWidth: 70,
               maxWidth: 90,
               isResizable: true,
               onColumnClick: parent._onColumnClick,
               data: 'number',
               onRender: (item) => {
                 return <span>{item.dateCreated}</span>;
               },
               isPadded: true,
             },
             {
               key: 'column4',
               name: 'Módosítva',
               fieldName: 'dateModifiedValue',
               minWidth: 70,
               maxWidth: 90,
               isResizable: true,
               data: 'number',
               onColumnClick: parent._onColumnClick,
               onRender: (item) => {
                 return <span>{item.dateModified}</span>;
               },
               isPadded: true,
             },
             {
               key: 'column5',
               name: 'Utolsó módosító',
               fieldName: 'modifiedBy',
               minWidth: 120,
               maxWidth: 120,
               isResizable: true,
               data: 'string',
               onColumnClick: parent._onColumnClick,
             },
             {
               key: 'column6',
               name: 'Letöltés',
               fieldName: 'download',
               style:style.fluentUI.fileIconCell,
               isIconOnly: true,
               minWidth: 16,
               maxWidth: 16,
               onColumnClick: parent._onColumnClick,
               onRender: (item) => (
                parent.state.downloadIsProgress===item.id ?
                <Spinner style={{justifyContent:"unset"}} label="Letöltés folyamatban..." ariaLive="assertive" labelPosition="right" /> :      
                  <div>
                   <TooltipHost content="Letöltés">
                       <IconButton
                       onClick={()=>parent.handleDownload(item)}
                       iconProps={downloadIcon}
                       />        
                   </TooltipHost>
                  </div>
       
                 
               ),
             },
       
           ]
           default:
            return []      
    }
}

function setFarCommandItems(parent){
    return [
      {
        key: 'info',
        text: 'Info',
        id:'bt-info',
        // This needs an ariaLabel since it's icon-only
        ariaLabel: 'Info',
        iconOnly: true,
        iconProps: { iconName: 'Info' },
        onClick: () => parent.handleOpenInfo()
      },
    ]
  }

export default {
  setColumns,
  setFarCommandItems
}

function getFileIcon(fileType,isFolder){
  const pictures = ['png','jpg'];
  const docs=['doc'];
  if(!isFolder){
    if(pictures.includes(fileType)){
      return 'photo';
    }else if(docs.includes(fileType)){
      return 'docx';
    }
    return fileType;
  }else{
    return 'folder';
  }
  
}
