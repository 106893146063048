import * as React from 'react';
//Material UI
import {Paper} from '@material-ui/core';
//Fluent UI
import {
  Modal,
  IconButton,
  Dropdown,
  List,
  Icon,
  Label,
  Persona,
  PersonaSize,
  MessageBar,
  MessageBarType,
  MessageBarButton,
  Spinner
} from 'office-ui-fabric-react';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
import { DefaultButton } from '@fluentui/react';

//Icons
const cancelIcon = { iconName: 'Cancel' };

//Warning panel
const WarningExample = (p) => (
  <MessageBar
    messageBarType={MessageBarType.warning}
    isMultiline={false}
    onDismiss={p.resetChoice}
    dismissButtonAriaLabel="Close"
    actions={
      <div>
        <MessageBarButton onClick={p.onYes}>Igen</MessageBarButton>
        <MessageBarButton onClick={p.onNo}>Nem</MessageBarButton>
      </div>
    }
  >
   Biztosan törölni szeretnéd ?
  </MessageBar>
);



//Render coin 
function _onRenderCoin(props) {
  const { coinSize, imageAlt, imageUrl } = props;
  return <img src={imageUrl} alt={imageAlt} width={coinSize} height={coinSize} className={style.listStyles.itemAvatar} />;
}

//Render list elements
const onRenderCell = (item, index,editIndex,removeIndex,options,onEdit,onCancel,onChange,onSave,onRemove,onRemoveRequest,onRemoveCancel) => {
  var roles=item.roles;
  const handleSelectChange = (event,item) => {
    onChange(item);
  };

  const getIcons=(key)=>{
    const filtered = roles.filter(row=>row===key);
    if(filtered.length>0){
      return <Icon className={style.contentStyles.okIcon} iconName="CheckMark"/>
    }else{
      return <Icon className={style.contentStyles.blockIcon} iconName="Blocked"/>;
    }
  }

  return (
    <div className={style.listStyles.itemCell}>
      <div className={style.listStyles.itemContent}>
      <Persona
        text={item.user.name}
        secondaryText={item.user.bio}
        size={PersonaSize.size48}
        onRenderCoin={_onRenderCoin}
        imageUrl={null}
      />
        {removeIndex===index && <WarningExample onYes={()=>onRemove(item)} onNo={onRemoveCancel}/>}      
        {editIndex!==index &&
        <Paper>
          <table style={style.materialUI.table}>
            <thead>
              <tr>
                <th style={style.materialUI.tableHeader} align="center">
                  <IconButton disabled={(removeIndex!==null || editIndex!=null) ? true : false} onClick={()=>onEdit(index)} className={style.listStyles.chevron}>
                    <Icon className={(removeIndex!==null || editIndex!=null) ? null : style.listStyles.editIcon} iconName="Edit" />
                  </IconButton>
                  <IconButton onClick={()=>onRemoveRequest(index)} disabled={(removeIndex!==null || editIndex!=null) ? true : false} className={style.listStyles.chevron}>
                    <Icon className={ (removeIndex!==null || editIndex!=null) ? null : style.listStyles.deleteIcon}  iconName="Delete" />
                  </IconButton>
                </th>
                <th style={style.materialUI.tableHeader} align="center"><Label>Pályázat</Label></th>
                <th style={style.materialUI.tableHeader} align="center"><Label>Ajánlat</Label></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="1" align="center"><Label className={style.listStyles.tdText}>Megtekintés</Label></td>
                <td align="center">{getIcons(1)}</td>
                <td align="center">{getIcons(4)}</td>
              </tr>
              <tr>
                <td id="2" align="center"><Label className={style.listStyles.tdText}>Módosítás (Elbírálás)</Label></td>
                <td align="center">{getIcons(2)}</td>
                <td align="center">{getIcons(6)}</td>
              </tr>
              <tr>
                <td id="3" align="center"><Label className={style.listStyles.tdText}>Jogosultság áll.</Label></td>
                <td align="center">{getIcons(3)}</td>
              </tr>
            </tbody>
          </table>
        </Paper>}
        {editIndex===index &&
          <React.Fragment>
            <Label>
              Jogosultság   
              <React.Fragment>
                <IconButton onClick={onSave} className={style.listStyles.chevron}>
                  <Icon className={style.listStyles.okIcon} iconName="Save" />
                </IconButton>
                <IconButton onClick={onCancel} className={style.listStyles.chevron}>
                  <Icon className={style.listStyles.deleteIcon}  iconName="Cancel" />
                </IconButton>
              </React.Fragment>
            </Label>
            <Dropdown
              placeholder="Válaszd ki..."
              selectedKeys={roles}
              onChange={handleSelectChange}
              multiSelect
              options={options}
              style={{maxWidth:'250px'}}
            />
          </React.Fragment>

        }
      </div>    
    </div>
  );
};

class EditRoles extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //options
      options:[],
      //items
      items:[],
      originalItems:[],
      //indexes
      editIndex:null,
      removeIndex:null,
      //request
      response:null,
      loading:true,
      request:false
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveRequest = this.handleRemoveRequest.bind(this);
    this.handleRemoveCancel = this.handleRemoveCancel.bind(this);
  }

  componentDidMount(){
    const {tender} = this.props;
      apiRequest.getOptions(this,tender.id);
      apiRequest.getRoles(this,tender.id);
  }

  //*Handle user interactions*//
  handleChange(item){
    const {items,editIndex} = this.state;
    const row = {...items[editIndex]};
    const keys =  item.selected ? [...row.roles, item.key] : row.roles.filter(role => role !== item.key);
    if(keys.includes(2) || keys.includes(3)){
      if(!keys.includes(1)){
        keys.push(1);
      }
    }
    if(keys.includes(6) || keys.includes(4)){
      if(!keys.includes(4)){
        keys.push(4);
      }
      if(!keys.includes(1)){
        keys.push(1);
      }
    }
    row.roles = keys;
    var temp = [...this.state.items];

    temp[editIndex] = row;
    this.setState({
      items:temp
    });
  }

  handleRemoveRequest(index){
    this.setState({
      removeIndex:index,
      response:null,
      items:[...this.state.items]
    });
  }

  handleRemoveCancel(){
    this.setState({
      removeIndex:null,
      items:[...this.state.items]
    });
  }
  //**//



  //*Handle actions*//
  handleEdit(index){
    this.setState({
      editIndex:index,
      response:null,
      items:[...this.state.items]
    });
  }

  handleRemove(item){
    const {tender} = this.props;
    const {items,removeIndex} = this.state;

    this.setState({
      request:true
    });
    const userId = items[removeIndex].user.key;
    const data = {tenderId:tender.id,userId};
    apiRequest.deleteRole(this,data);
  }

  handleCancel(){
    this.setState({
      editIndex:null,
      items:[...this.state.originalItems]
    });
  }

  handleSave(){
    const {tender} = this.props;
    const {items,editIndex} = this.state;

    this.setState({
      request:true
    });
    const userId = items[editIndex].user.key;
    const scopes = items[editIndex].roles;
    const data = {tenderId:tender.id,scopes,userId};
    apiRequest.editRoles(this,data);
  }
  //**//

  //*Helper methods*//
  refreshRoles = () =>{
    const {tender} = this.props;
    this.setState({
      loading:true
    })
    apiRequest.getRoles(this,tender.id);
  }

  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  //**//

  render(){
    const {items,editIndex,options,removeIndex,loading,request,response} = this.state;
    const {open,hideModal,tender}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
            <div className={style.contentStyles.titleContent}>
                <div className={style.contentStyles.titleName}>{tender.name}</div>
                <div className={style.contentStyles.titleIndex}>Jogosultságok módosítása</div>
            </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        <div className={style.contentStyles.body}>
        {response!==null &&
          <MessageBar
            messageBarType={this.setMessageBarType(response.type)}
            isMultiline={false}
          >
            {response.message}
          </MessageBar>
          }
        {(loading === true || request===true)  ? 
          <Spinner style={{padding:'1rem'}} label="Betöltés..." /> :
          items.length>0 ? 
          <List 
            items={items} 
            editIndex={editIndex} 
            onRenderCell={(item,index)=>onRenderCell(item,index,editIndex,removeIndex,options,this.handleEdit,this.handleCancel,this.handleChange,this.handleSave,this.handleRemove,this.handleRemoveRequest,this.handleRemoveCancel)} 
          /> : <div style={{textAlign:'center'}}><Label>Nincs létrehozva jogosultság</Label></div>}
        </div>
        <div className={style.contentStyles.action}>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
  
};

export default EditRoles;