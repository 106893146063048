import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Topbar, Footer } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    paddingTop: 64
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%',
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  /*const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });*/

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar />
      <main className={classes.content}>
          {children}
        {/*<Footer />*/}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
