import * as React from 'react';
//Material UI
import {Grid} from '@material-ui/core';
//FluentUI
import { 
    Sticky,
    ScrollablePane,
    Label,
    SearchBox,
    CommandBar,
    Breadcrumb,
    DetailsRow,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    TextField } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
//Components
import {Pagination} from '../../../../../../components'
import {DecisionModal,NoWinnerModal,InfoCallout} from './components';

import { eventyTypes } from '../../../../../../stores';
import {apiRequest} from './apiRequest';
import data from './data';
//Theme
import style from './style';
import { withStyles } from '@material-ui/core';

//Icons
const calenDarIcon = { iconName: 'Calendar' };
const peopleIcon ={iconName:'People'};





export class ReceivedGroupedList extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      //table rows
      items: [],
      columns: data.setColumns(this,'default'),
      isCompactMode: false,
      //request
      loading:true,
      //pagination
      first:0,
      last:0,
      //filter
      filteredItems:[],
      isFiltered:false,
      //helper
      winner:null,
      isClosed:this.props.tender.isClosed,
      //modals
      modal:{
        decision:false,
        noWinner:false,
      },
      //command items
      commandItems:[],
      farCommandItems:[],
      info:false
    };

    this._onItemInvoked  = this._onItemInvoked.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.refreshTable = this.refreshTable.bind(this);

   
  }

  componentDidMount(){
    const {tender} = this.props;
    //Get groups from api
    apiRequest.getGroups(this,tender.id);
    //Set command items
    this.setState({
      commandItems:data.setCommandItems(this),
      farCommandItems:data.setFarCommandItems(this)
    });

  }

  componentDidUpdate(prevProps,prevState){
    const {winner,items} = this.state;
    //Refresh command items
    if(prevState.winner!=winner){
      this.setState({
        commandItems:data.setCommandItems(this),
        farCommandItems:data.setFarCommandItems(this)
      });
    }
    //Check items contains element with isWon:true property 
    if(prevState.items!=items){
      const filtered = items.find(item=>item.isWon==true);
      if(filtered!==null && filtered!==undefined){
        this.setState({
          winner:filtered,
          commandItems:data.setCommandItems(this),
          farCommandItems:data.setFarCommandItems(this)
        });
      }else{
        this.setState({
          winner:null,
          commandItems:data.setCommandItems(this),
          farCommandItems:data.setFarCommandItems(this)
        });
      }
    }
  }

  //*Handle modals*//
  handleOpen = (props) =>{
    this.setState(prevState=>({
      modal:{
          ...prevState.modal,
          [props]:true
      }
    }));
  }

  handleClose = (props) =>{
    this.setState(prevState=>({
      modal:{
          ...prevState.modal,
          [props]:false
      }
    }));
  }
  //**//

  //*Set clicked row*//
  handleClick(item){

    this.props.onSelect(item);

    const model ={...item}
    model.lastEvent = eventyTypes.NOTHING;
    if(item.lastEvent==eventyTypes.CREATE){
      apiRequest.noticeChanges(this,model);
    }
    
  }
  //**//

  //*Helper methods*//
  refreshTable(){
    const {tender} = this.props;
    apiRequest.getGroups(this,tender.id);
  }

  setIsClosed = () =>{
    this.setState({
      isClosed:true
    });
  }

  handleOpenInfo = () =>{
    this.setState({
      info:true
    })
  }

  handleCloseInfo = () =>{
    this.setState({
      info:false
    })
  }

  //**//

  render() {
    const { columns,info, isCompactMode,loading,modal,winner, items,commandItems,farCommandItems,first,last,filteredItems,isFiltered } = this.state;
    const {classes,navItems,tender} = this.props;
    return (
      <div>
        {navItems!=null && 
        <div className={classes.breadcrumb}>
          <Breadcrumb
            items={navItems}
            maxDisplayedItems={10}
          />
        </div>
        }
        <CommandBar
          items={commandItems}
          farItems={farCommandItems}
        />
        {info===true && <InfoCallout  onClose={()=>this.handleCloseInfo()} buttonId={`bt-info`} />}
        {modal.decision===true &&  <DecisionModal setIsClosed={this.setIsClosed} onRefresh={this.refreshTable} offers={items} open={modal.decision} onClose={()=>this.handleClose('decision')} />}
        {modal.noWinner===true &&  <NoWinnerModal setIsClosed={this.setIsClosed} onRefresh={this.refreshTable} tender={tender} open={modal.noWinner} onClose={()=>this.handleClose('noWinner')} />}
        {winner!=null && <div className={classes.header}>
              <Grid spacing={2} container>
              <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                  <Label>Nyertes ajánlat</Label>
                  <TextField value={winner.name} readOnly={true}/>
                </Grid>
                <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                  <Label>Ajánlat értéke</Label>
                  <TextField value={winner.value} suffix={winner.currency} readOnly={true}/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                  <Label>Ajánlat érvényes a következőig</Label>
                  <TextField value={winner.dateValidTo} iconProps={calenDarIcon} readOnly={true}/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                  <Label>Ajánlatot adta</Label>
                  <TextField value={winner.offerCreator} iconProps={peopleIcon} readOnly={true}/>
                </Grid>               
              </Grid>
          </div>}
        <div className={classes.table}>
          <div style={style.fluentUI.controlWrapper}>
            <SearchBox style={style.fluentUI.search} placeholder="Keresés..." onChange={this._onChangeText} />
          </div>
          <div style={style.fluentUI.tableWrapper}>
          <ScrollablePane>
            <DetailsList
              items={isFiltered ? filteredItems.slice(first,last) : items.slice(first,last)}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={
                (detailsHeaderProps, defaultRender) => (
                  <Sticky>
                    {defaultRender(detailsHeaderProps)}
                  </Sticky>
                ) }
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
              onRenderRow={this.onRenderColumnListRow}
            />
            </ScrollablePane>
          </div>
          <Pagination loading={loading} parent={this} length={isFiltered ? filteredItems.length : items.length}/>
        </div>
      </div>
    );
  }

  onRenderColumnListRow= (props) => {
    const customStyles = {};
    if (props) {
        if(props.item.isWon===true){
          //Set green background to row with isWon:true property 
          customStyles.root = style.fluentUI.highlightRow;
          return <DetailsRow {...props} styles={customStyles}/>;
        }else{
          //Default row style
          customStyles.root = style.fluentUI.tableRow;
          return <DetailsRow {...props} styles={customStyles}/>;
        }

    }
    return null;
  };

   _getKey(item, index) {
    return item.key;
  }

   _onChangeCompactMode = (ev, checked) => {
    this.setState({ isCompactMode: checked });
  };

  _onChangeText = (ev, text) => {
    if(text!=='' && text!==null && text!==undefined){
      this.setState({
        isFiltered:true
      });
    }else{
     this.setState({
       isFiltered:true
     });
    }
    const {items} = this.state;
     this.setState({
       filteredItems: text ? items.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
     });
  };

  _onItemInvoked(item) {
    this.handleClick(item)
 }


   _onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns= columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

//**//


export default withStyles(style.materialUI)(ReceivedGroupedList);