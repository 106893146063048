import * as React from 'react';
import { ActivityItem, IActivityItemProps, Link, mergeStyleSets,FontSizes } from 'office-ui-fabric-react';
import { TestImages } from '@uifabric/example-data';
import {lightTheme} from '../../../themes/FluentUI';
import { Label } from '@fluentui/react';

const classNames = mergeStyleSets({
  exampleRoot: {
    marginTop: '20px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  coloredBoldText:{
    fontWeight: 'bold',
    color:lightTheme.palette.themePrimary
  },
  noResult:{
      fontSize:FontSizes.si
  }
});

const ActivityItemPersonaExample = (props) => {

  const items = props.items.map(item=>{
    return {
        key: item.key,
        activityDescription: [
          <span key={1} className={classNames.coloredBoldText}>
            {item.people}
          </span>,
          <span key={2}>   {item.action} </span>,
          <span key={3} className={classNames.boldText}>
            {item.document}
          </span>,
        ],
        style:{borderBottom:`1px solid ${lightTheme.palette.neutralTertiaryAlt}`},
        activityPersonas: [{ imageUrl: item.imageUrl, imageInitials:item.imageInitials, text: item.imageText }],
        comments: item.tender,
        timeStamp: item.timeStamp,
      }
  });
  return (

    <div>
       {items.length<1 && <div style={{textAlign:'center'}}><Label className={classNames.noResult}>Nincs újabb aktivitás...</Label></div>}
      {items.map((item) => (
        <ActivityItem  {...item} key={item.key} className={classNames.exampleRoot} />
      ))}
    </div>
  );
};

export default ActivityItemPersonaExample;
