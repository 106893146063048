import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    GetInfo: 'inner/tender-infos',
    GetFiles: 'inner/get-files',
    GetFile:'inner/get-file',
    DeleteFile:'inner/delete-file',
    GetAllFiles:'inner/get-all-files',
    NoticeChanges:'inner/tender-changes/notice'
}


export const apiRequest = {
    getInfos,
    getFiles,
    downloadFile,
    deleteFile,
    downloadAllFile,
    noticeChanges
};

async function getInfos(parent,tenderId) {
    const {setSelectedTender} = parent.props;
    await new Api().call.get(`${Actions.GetInfo}/${tenderId}`).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                tender:res,
                loading:{
                    ...prevState.loading,
                    tender:false,
                  },
            }));
            setSelectedTender(res);
        }).catch(handleError);
}

async function noticeChanges(parent,model) {
    
    await new Api().call.post(`${Actions.NoticeChanges}/${model.id}`).then(handleResponse)
        .then(res => {
            parent.setState(prevState=>({
                loading:{
                  ...prevState.loading,
                  notice:false
                }
              }))
            getInfos(parent,model.id);
        }).catch(error=>{
            handleError(error);
            parent.setState(prevState=>({
                loading:{
                  ...prevState.loading,
                  notice:false
                }
              }))
        });
}

async function deleteFile(parent,data) {

    parent.setState({
        deleteIsProgress:true
    });

    await new Api().call.post(Actions.DeleteFile,data).then(handleResponse)
        .then(res => {
            parent.setState({
                deleteRequest:null,
                requestResponse:res,
            });

            parent.refreshFiles();
        }).catch(error=>{
            handleError(error);

            let response = {message:'Váratlan hiba miatt nem törölhető',type:'error'}
            if(error.response!==null && error.response!==undefined && error.response.data!==null && error.response.data!==undefined){
                if(error.response.data.message!==null && error.response.data.message!==undefined)
                response=error.response.data
            }
            parent.setState({
                requestResponse: response,
                deleteRequest:null,
            });
        });
}

async function getFiles(parent,model) {
    await new Api().call.post(Actions.GetFiles,model).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                items:res,
                loading:{
                    ...prevState.loading,
                    files:false,
                  },
            }));
        }).catch(handleError);
}


async function downloadFile(parent,tenderId,fileId,isFolder) {

    parent.setState({
        downloadIsProgress:fileId
    });
        await new Api().call.post(`${Actions.GetFile}/${tenderId}/${fileId}/${isFolder}`,null,{
            onDownloadProgress: progressEvent => {
                const {loaded,total} = progressEvent;
            
                let percent = Math.floor(loaded / total * 100)
            //console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            },
            responseType: 'arraybuffer'
        })
        .then(res => {
            var blob = new Blob([res.data], {type: "application/octet-stream"});
            var header = res.headers['content-disposition'];
            //Get file name from header
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(header);
            let fileName;
            if (matches != null && matches[1]) { 
                fileName = matches[1].replace(/['"]/g, '');
            }
             //Create the download link
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();

            parent.setState({
                    downloadIsProgress:null
            });
            
        }).catch(error=>{
            handleError(error);
            let response = {message:'Váratlan hiba miatt nem tölthető le',type:'error'}
            if(error.response!==null && error.response!==undefined && error.response.data!==null && error.response.data!==undefined){
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
                var obj = JSON.parse(decode_utf8(decodedString));
                if(obj!==null && obj!==undefined){
                    response = obj;
                }
            }
            parent.setState({
                     downloadIsProgress:null,
                     requestResponse: response
             });
        });
    
}

async function downloadAllFile(parent,tenderId,parentId) {


    parent.setState({
        zipIsProgress:true
    });

    await new Api().call.post(`${Actions.GetAllFiles}/${tenderId}/${parentId}`,null,{
        onDownloadProgress: progressEvent => {
            const {loaded,total} = progressEvent;
        
            let percent = Math.floor(loaded / total * 100)
        //console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        },
        responseType: 'arraybuffer'

    }).then(res => {       
            var blob = new Blob([res.data], {type: "application/octet-stream"});
            var header = res.headers['content-disposition'];
            //Get file name from header
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(header);
            let fileName;
            if (matches != null && matches[1]) { 
                  fileName = matches[1].replace(/['"]/g, '');
            }
            //Create the download link
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();

            parent.setState({
                zipIsProgress:false
            });

        }).catch(error=>{
            handleError(error);
            let response = {message:'Váratlan hiba miatt nem tölthető le',type:'error'}
            if(error.response!==null && error.response!==undefined && error.response.data!==null && error.response.data!==undefined){
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
                var obj = JSON.parse(decode_utf8(decodedString));
                if(obj!==null && obj!==undefined){
                    response = obj;
                }
            }
            parent.setState({
                     requestResponse: response,
                     zipIsProgress:false
             });
        });
    
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

 function decode_utf8(utftext) {
    var plaintext = ""; var i=0; 
    var c=0;
    var c1=0;
    var c2=0;
    var c3=0;
    // while-Schleife, weil einige Zeichen uebersprungen werden
    while(i<utftext.length)
        {
        c = utftext.charCodeAt(i);
        if (c<128) {
            plaintext += String.fromCharCode(c);
            i++;}
        else if((c>191) && (c<224)) {
            c2 = utftext.charCodeAt(i+1);
            plaintext += String.fromCharCode(((c&31)<<6) | (c2&63));
            i+=2;}
        else {
            c2 = utftext.charCodeAt(i+1); c3 = utftext.charCodeAt(i+2);
            plaintext += String.fromCharCode(((c&15)<<12) | ((c2&63)<<6) | (c3&63));
            i+=3;}
        }
    return plaintext;
}	