import {lightTheme} from '../../../../../../themes';
import {
  getTheme,
  mergeStyleSets,
  FontWeights
} from 'office-ui-fabric-react';
import { getFocusStyle } from 'office-ui-fabric-react/lib/Styling';
import { SharedColors } from '@fluentui/theme';

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const materialUI = {
  table:{
    borderSpacing:'5px',
    overflow:'auto',
    margin:'auto'
  },
  tableHeader:{
    borderBottom: `3px solid ${lightTheme.palette.themePrimary}`
  }
}
const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      maxHeight:'600px'
    },
    titleContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    titleName: [
      fonts.xLarge,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    ],
    titleIndex: {
      fontSize: fonts.medium.fontSize,
      color: palette.neutralTertiary,
      marginBottom: 10,
    },
    okIcon:{
      color:SharedColors.yellowGreen10
    },
    blockIcon:{
      color:SharedColors.red10
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${lightTheme.palette.themePrimary}`,
        color: lightTheme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    action:{
      padding: '0 24px 24px 24px',
      textAlign:'right'
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });
  const iconButtonStyles = {
    root: {
      color: lightTheme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: lightTheme.palette.neutralDark,
    },
  };

  const listStyles = mergeStyleSets({
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      },
    ],
    itemAvatar:{
      borderRadius: 20,
      display: 'block',
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
    },
    editIcon:{
      color:SharedColors.orange10,
    },
    deleteIcon:{
      color:SharedColors.red10
    },
    okIcon:{
      color:SharedColors.yellowGreen10
    },
    tdText:{
      fontSize:fonts.small.fontSize,
    },
    chevron: {
      alignSelf: 'center',
      marginLeft: 10,
      color: palette.neutralTertiary,
      fontSize: fonts.large.fontSize,
      flexShrink: 0,
    },
  });
 
  export default {
    contentStyles,
    iconButtonStyles,
    listStyles,
    materialUI
  };