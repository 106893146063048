import * as React from 'react';
//Material UI
import {Grid} from '@material-ui/core';
//Fluent UI
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import {
  TextField,
  Modal,
  IconButton,
  Dropdown,
  Spinner,
  MessageBar,
  MessageBarType
} from 'office-ui-fabric-react';
//Components
import {DateSelect, PartnerSelect} from '../../../../../../components/Inputs';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
//Icons
const cancelIcon = { iconName: 'Cancel' };

class EditOffer extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //editable
      editOffer:{value:'',currency:null,dateValidTo:''},
      //request
      loading:true,
      response:null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount(){
    const {tender} = this.props;
    apiRequest.getOffer(this,tender.id);
  }

  //*Handle user inputs*//
  onTextChange(event){
    const {editOffer} = this.state;
    const name = event.target.name;
    const value = event.target.value;

    // Create our number formatter.
    var formatter = new Intl.NumberFormat('hu-HU', {
      style: 'currency',
      currency: 'HUF',
      maximumFractionDigits:0
    });

    //Remove white spaces from string 
    const formattedValue=formatter.format(value.replace(/\s/g, ""));


    this.setState({
      editOffer:{...editOffer,[name]:formattedValue.substring(0, formattedValue.length-3)}
    });
  }

  onDateChange(name,newDate){
    const {editOffer} = this.state;
    this.setState({
      editOffer:{...editOffer,[name]:newDate}
    });
  }
  
  handleSelectChange(event,item){
    const {editOffer} = this.state;
    this.setState({
      editOffer:{...editOffer,currency:item.key}
    });
  }
  //**//

  //*Save*//
  handleSave(){
    const {editOffer} = this.state;
    const {tender} = this.props;

    this.setState({
      response:null,
      loading:true
    });

    let data = {...editOffer,tenderId:tender.id};
    //Convert dates to LocalDate
    data.dateValidTo = this.getLocalDate(new Date(data.dateValidTo.toString()));
    
    apiRequest.editOffer(this,data);
  }
  //**//



  //*Helper methods*//
  validate(){
    const {editOffer} = this.state;
    if(editOffer.dateValidTo!==null && editOffer.dateValidTo!==undefined && editOffer.value!==undefined && editOffer.value!=='')
    {
      return true;
    }
    return false;
  }

  //Helper method to convert Date to UTC
  getLocalDate(date){
    let day = date.getDate();
    let mounth = date.getMonth()+1;
    let year = date.getFullYear();
    return `${day}/${mounth}/${year}`
  }

  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  //**//

  render(){
    const {editOffer,loading,response} = this.state;
    const {open,hideModal,options,tender}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>{tender.name}</div>
            <div className={style.contentStyles.titleIndex}>Ajánlat módosítása</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false &&  <div className={style.contentStyles.body}>
          {response!==null &&
                <MessageBar
                  messageBarType={this.setMessageBarType(response.type)}
                  isMultiline={false}
                >
                {response.message}
                </MessageBar>
            }
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <TextField 
                required 
                onChange={this.onTextChange} 
                name="value" 
                value={editOffer.value} 
                label="Ajánlat értéke" 
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <Dropdown
                required
                selectedKey={editOffer.currency}
                placeholder="Válaszd ki..."
                label="Pénznem"
                onChange={this.handleSelectChange}
                options={options}
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <DateSelect 
                required={true} 
                name="dateValidTo" 
                onChange={this.onDateChange} 
                value={new Date(editOffer.dateValidTo)} 
                label="Ajánlat érvényes a következőig"
              />
            </Grid>
          </Grid>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Betöltés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton 
            disabled={!this.validate() || loading} 
            onClick={this.handleSave} 
            style={{marginRight:'5px'}}
          >
            Mentés
          </PrimaryButton>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
};

export default EditOffer;