import * as React from 'react';
//FluentUI
import { 
  Sticky,
  ScrollablePane,
  SearchBox,
  CommandBar,
  DetailsRow,
  MessageBar,
  MessageBarType,
  MessageBarButton } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
//Theme
import style from './style';
import { withStyles } from '@material-ui/core';
//Components
import {InfoCallout} from './components';
import {AddPartner} from '../../';
import {Pagination} from '../../../../../../components';
import data from './data';
//Models
import {apiRequest} from './apiRequest';


const WarningPanel = (p) => (
  <MessageBar
    messageBarType={MessageBarType.warning}
    isMultiline={false}
    onDismiss={p.resetChoice}
    dismissButtonAriaLabel="Close"
    actions={
      <div>
        <MessageBarButton onClick={p.onYes}>Igen</MessageBarButton>
        <MessageBarButton onClick={p.onNo}>Nem</MessageBarButton>
      </div>
    }
  >
   Biztosan törölni szeretnéd ?
  </MessageBar>
);



export class PartnerList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //table rows
      items: [],
      columns: data.setColumns(this,'default'),
      isCompactMode: false,
      //request
      loading:true,
      //interactions
      addNew:false,
      deleteRequest:null,
      requestResponse:null,
      //pagination
      first:0,
      last:0,
      //filter
      filteredItems:[],
      isFiltered:false,
      //command items
      commandItems:data.setCommandItems(this),
      commandFarItems:data.setFarCommandItems(this),
      info:false
    };

    this._onItemInvoked  = this._onItemInvoked.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.refreshStore = this.refreshStore.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleResponsePanel = this.handleResponsePanel.bind(this);
  }

  componentDidMount(){
    apiRequest.getPartners(this);
  }

  componentDidUpdate(prevProps,prevState){
    const {deleteRequest} = this.state;
    
    if(deleteRequest!=prevState.deleteRequest){
      if(deleteRequest!==null){
        this.setState({
          columns: data.setColumns(this,'delete')
        });
      }else{
        this.setState({
          columns: data.setColumns(this,'default'),
          deleteIsProgress:false
        });
      }
    }
} 

  //*Handle modals*//
  handleOpen(){
    this.setState({
      addNew:true
    });
  }

  handleClose(){
    this.setState({
      addNew:false
    });
  }
  //**//

  //*Set clicked row*//
  
  handleClick(item){
    this.props.onSelect(item)
  }
  //**//

  //*Helper methods*//
  refreshStore(){
    this.setState({
      loading:true
    });
    apiRequest.getPartners(this);
    this.props.onRefreshPartners();
  }

  handleResponsePanel(){
    this.setState({
      requestResponse:null
    })
  }

  refreshTable = () =>{
    apiRequest.getPartners(this);
  }

  handleOpenInfo = () =>{
    this.setState({
      info:true
    })
  }

  handleCloseInfo = () =>{
    this.setState({
      info:false
    })
  }

  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }

  //**//

  //*Delete actions*//
  handleDeleteRequest(item){
    //Create array and push into the selected item
    let request = [];
    request.push(item);

    this.setState({
      deleteRequest:request,
      requestResponse:null,
    });
  }

  handleDelete(){
    const {tender} = this.props;
    const {deleteRequest} = this.state;
    //Fill model
    const model = [];
    deleteRequest.map(row=>{
      model.push({partnerId:row.id})
    });
    //Call api
    apiRequest.deletePartners(this,model);
  }

  handleCancelDelete(){
    this.setState({
      deleteRequest:null
    })
  }
  //**//


  render() {
    const { columns,info, isCompactMode,requestResponse,first,last,deleteRequest, items,commandItems,commandFarItems,addNew,filteredItems,isFiltered,loading  } = this.state;
    const {classes} = this.props;
    return (
      <div>
        <CommandBar
          items={commandItems}
          farItems={commandFarItems}
        />
        {deleteRequest!==null && 
          <WarningPanel 
            onYes={()=>this.handleDelete(deleteRequest)} 
            onNo={this.handleCancelDelete}
          />
        }
        {requestResponse!==null &&
          <MessageBar
            messageBarType={this.setMessageBarType(requestResponse.type)}
            isMultiline={false}
            actions={
              <div>
                <MessageBarButton onClick={this.handleResponsePanel}>Ok</MessageBarButton>
              </div>
            }
          >
            {requestResponse.message}
          </MessageBar>
        }
        {info===true && <InfoCallout  onClose={()=>this.handleCloseInfo()} buttonId={`bt-info`} />}  
        {addNew===true && <AddPartner onRefresh={this.refreshStore} open={addNew} hideModal={this.handleClose}/>}
        <div className={classes.table}>
          <div style={style.fluentUI.controlWrapper}>
            <SearchBox style={style.fluentUI.search} placeholder="Keresés..." onChange={this._onChangeText} />
          </div>
          <div style={style.fluentUI.tableWrapper}>
          <ScrollablePane>
            <DetailsList
              items={isFiltered ? filteredItems.slice(first,last) : items.slice(first,last)}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={
                (detailsHeaderProps, defaultRender) => (
                  <Sticky>
                    {defaultRender(detailsHeaderProps)}
                  </Sticky>
                ) }
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
              onRenderRow={this.onRenderColumnListRow}
            />
            </ScrollablePane>
          </div>
          <Pagination loading={loading} parent={this} length={isFiltered ? filteredItems.length : items.length}/>
        </div>
      </div>
    );
  }

  //*To render table rows*//

  onRenderColumnListRow= (props) => {
    const {deleteRequest} = this.state
    const customStyles = {};
    if (props) {
      if(deleteRequest!==null && deleteRequest!==undefined && deleteRequest.filter(row=>row.id===props.item.id).length>0){
        customStyles.root = style.fluentUI.highlightRow;
        return <DetailsRow {...props} styles={customStyles} />;
      }else{
        customStyles.root = style.fluentUI.tableRow;
        return <DetailsRow {...props} styles={customStyles}/>;
      }
    }
    return null;
  };

   _getKey(item, index) {
    return item.key;
  }

   _onChangeCompactMode = (ev, checked) => {
    this.setState({ isCompactMode: checked });
  };

   _onChangeText = (ev, text) => {
    if(text!=='' && text!==null && text!==undefined){
        this.setState({
          isFiltered:true
        });
      }else{
       this.setState({
         isFiltered:true
       });
      }
       const{items} = this.state;
        this.setState({
            filteredItems: text ? items.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
        });
  };

   _onItemInvoked(item) {
     this.props.onSelect(item);
  }


   _onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns= columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
//**//




export default withStyles(style.materialUI)(PartnerList);