import * as React from 'react';
//Fluent UI
import {
  Modal,
  IconButton,
  MessageBar,
  MessageBarType,
  ProgressIndicator
} from 'office-ui-fabric-react';
import { Icon } from '@fluentui/react/lib/Icon';
import { List } from 'office-ui-fabric-react/lib/List';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';
//Theme
import style from './style';
//Api
import {apiRequest} from './apiRequest';
//Icons
const cancelIcon = { iconName: 'Cancel' };


class NewFile extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //files
      files:[],
      //request
      uploadProgress:null,
      request:false,
      //response
      failed:[],
      success:[]
    }

    this.changeHandler = this.changeHandler.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.clearItem = this.clearItem.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  //*Select file handler*//
  changeHandler(event){
    let temp = [];
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      let lastDot = files[i].name.lastIndexOf('.');
      let length = files[i].name.length;
      if(lastDot!==null && lastDot!==undefined){
        var name=files[i].name.slice(0,lastDot);
        var fileType=files[i].name.slice(lastDot+1,length);
        var thumbnail=`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${this.getFileIcon(fileType)}.svg`;
      }else{

      }
     
      temp.push({file:files[i],name,fileType,thumbnail});
  }
    this.setState({
      files:temp,
      failed:[],
      success:[]
    });
	};
  //**//

  //*Remove files from list*//
  clearAll(){
    this.setState({
      files:[],
      failed:[],
      success:[]
    });
  }

  clearItem(item){
    const {files}=this.state;
    const temp = files.filter(row=>row!==item);
    this.setState({
      files:temp,
      failed:[],
      success:[]
    });

  }
  //**//

  //*Upload*//
  handleUpload(){
    const {files} = this.state;
    const {selected,offer}=this.props;
    
    this.setState({
      request:true,
      failed:[],
      success:[]
    });
    let localFiles = [...files];
    var data = new FormData();
    data.append('offerId', offer.id);
    data.append('parentId', (selected!==null && selected!==undefined) ? selected.id : null);
    localFiles.map(file => {
        data.append('files[]', file.file);
    });

    apiRequest.upload(this,data);
  }
  //**//

  //*Render list items*//
   onRenderCell = (item, index, isScrolling) => {
    return (
      <div className={style.listStyles.itemCell} data-is-focusable={true}>
        <Image iconName={item.thumbnail}
          className={style.listStyles.itemImage}
          src={isScrolling ? undefined : item.thumbnail}
          width={50}
          height={50}
          imageFit={ImageFit.center}
        />
        <div className={style.listStyles.itemContent}>
          <div className={style.listStyles.itemName}>{item.name}</div>
          <div className={style.listStyles.itemIndex}>.{item.fileType}</div>
        </div>
        <IconButton onClick={()=>this.clearItem(item)}>
          <Icon className={style.contentStyles.deleteIcon} iconName="Delete"/>
        </IconButton>
      </div>
    );
  };

  getFileIcon(fileType){
    const pictures = ['png','jpg'];
    const docs=['doc'];
    if(pictures.includes(fileType)){
      return 'photo';
    }else if(docs.includes(fileType)){
      return 'docx';
    }
    return fileType;
  }
  //**//

  render(){
    const {files,uploadProgress,request,failed,success} = this.state;
    const {open,hideModal,tender}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.listStyles.titleContent}>
            <div className={style.listStyles.titleName}>{tender.name}</div>
            <div className={style.listStyles.titleIndex}>Új file feltöltése</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        <div className={style.contentStyles.body}>
        {failed.length>0 &&  
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            {failed.map((item,index)=> <div key={index}><Label>{item.fileName}</Label> - {item.errorMessage}</div>)}
          </MessageBar>
        }
          {success.length>0 &&  
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            {success.map((item,index)=> <div key={index}><Label>{item.fileName}</Label> - {item.message}</div>)}
          </MessageBar>}
          <div style={{padding:'20px'}}>
          {request == false && 
            <label className={style.contentStyles.fileInput} htmlFor="file-input">
              File-ok kiválasztása...      
            </label>
          }
          {request == true && 
            <label className={style.contentStyles.fileInput}>
              {uploadProgress!=null && uploadProgress+'%'}    
            </label>
          }
            <input style={{ display: 'none' }}  
              id="file-input" 
              type="file" 
              name="file-input" 
              onChange={this.changeHandler} 
              multiple={true} 
            />        
          </div>
          {files.length>0 && 
          <div>
            <div style={{display:'flex',alignItems:'center'}}> 
              <Label className={style.contentStyles.filesTitle}>Kiválasztott file-ok:</Label>
              <div style={{flexGrow:3}}/>
              <IconButton onClick={this.clearAll}>
                <Icon className={style.contentStyles.deleteIcon} iconName="Delete"/>
              </IconButton>
            </div>
           
            <List items={files} onRenderCell={this.onRenderCell} />
          </div>
          }
        </div>
        {(uploadProgress!==null && request ==true ) && 
        <ProgressIndicator percentComplete={uploadProgress/100} />
        }
        <div className={style.contentStyles.action}>
          <PrimaryButton 
            disabled={files.length<1 ? true : false} 
            style={{marginRight:'5px'}} 
            onClick={this.handleUpload}
          >
              Feltöltés
          </PrimaryButton>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>   
      </Modal>
  );
  }
};

export default NewFile;