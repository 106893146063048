import * as React from 'react';
//Fluent UI
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import {
  Modal,
  IconButton,
  Spinner,
  MessageBar,
  MessageBarType,
  Label
} from 'office-ui-fabric-react';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
//Icons
const cancelIcon = { iconName: 'Cancel' };

class NoWinnerModal extends React.Component{

  constructor(props){
    super(props);

    this.state={
      //request
      loading:false,
      saveRequest:false,
      response:null,
      //options
      options:[],
      //selected
      checked:null
    }

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  
  //*Handle user interactions*//
  handleCancel(){
    const {onClose}=this.props;
    onClose();
  }

  handleSave(){
    const {checked} = this.state;
    const {tender} = this.props;
    const model = {tenderId:tender.id};
    this.setState({
      saveRequest:false,
      loading:true
    })
    apiRequest.setDecision(this,model);
  }

  //**//

  //*Helper methods*//
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  //**//

  render(){
    const {loading,response} = this.state;
    const {open,onClose}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={onClose}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>Elbírálás</div>
            <div className={style.contentStyles.titleIndex}>Nincs nyertes ajánlat</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onClose}
          />
        </div>
        {loading===false && <div className={style.contentStyles.body}>
          {response!==null &&
            <MessageBar
              messageBarType={this.setMessageBarType(response.type)}
              isMultiline={false}
            >
              {response.message}
            </MessageBar>
          }
          <Label>Biztos lezárja a kiírást nyertes ajánlat nélkül ? </Label>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Törlés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton
            disabled={loading}
            onClick={this.handleSave} 
            style={{marginRight:'5px'}}
          >
            Mentés
          </PrimaryButton>
          <DefaultButton onClick={this.handleCancel}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
};

export default NoWinnerModal;