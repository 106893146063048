import * as React from 'react';
//Material UI
import {Grid} from '@material-ui/core';
//FluentUI
import { Sticky,
  ScrollablePane,
  Label,
  SearchBox,
  CommandBar,
  Breadcrumb,
  TextField,
  DetailsRow,
  MessageBar,
  MessageBarButton,
  MessageBarType, 
  Separator} from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
//Components
import {Pagination} from '../../../../../../components';
import {apiRequest} from './apiRequest';
import data from './data';
//Theme
import style from './style';
import { withStyles } from '@material-ui/core';
import {DownloadProgress,InfoCallout,OfferChanges,LoadingProgress} from './components';
import { eventyTypes } from '../../../../../../stores';
//Icons
const calenDarIcon = { iconName: 'Calendar' };
const peopleIcon ={iconName:'People'};



export class ReceivedList extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      //table rows
      items: [],
      isCompactMode: false,
      columns: data.setColumns(this,'empty'),
      //filter
      filteredItems:[],
      isFiltered:false,
      requestResponse:null,
      //pagination
      first:0,
      last:0,
      //request
      loading:true,
      loadingNotice:false,
      zipIsProgress:false,
      downloadIsProgress:null,
      info:false,
      changes:false,
      //commands
      farCommandItems:data.setFarCommandItems(this),
      tableNavItems:[],
      selected:null
    };

    this._onItemInvoked  = this._onItemInvoked.bind(this);
    this.handleResponsePanel = this.handleResponsePanel.bind(this);
  }

  componentDidMount(){
    const {offer} = this.props;
    const model = {offerId:offer.id};
    apiRequest.getFiles(this,model);
  }

  componentDidUpdate(prevProps,prevState){
    const {items,downloadIsProgress,selected} = this.state;
    const {offer} = this.props;
    if(items!=prevState.items){
        if(items.length>0){
          this.setState({
            columns: data.setColumns(this,'default'),
          })
        }else{
          this.setState({
            columns: data.setColumns(this,'empty'),
          })
        }
    }
    if(downloadIsProgress!=prevState.downloadIsProgress){
      if(downloadIsProgress!==null){
        this.setState({
          columns:  data.setColumns(this,'downloadIsProgress')
        });
      }else{
        this.setState({
          columns: data.setColumns(this,'default')
        });
      }
    }
    if(prevState.selected!=selected){
      //Init states
      this.setState((prevState)=>({
        loading:{
          ...prevState.loading,
          files:true
        }
      }));
      //Call api
      const model = {offerId:offer.id,parentId:(selected!==null && selected!==undefined) ? selected.id : null};
      apiRequest.getFiles(this,model);
    }
  }

//*Download actions*//
handleDownload=(item)=>{
  const {offer} = this.props;
  //Init states
  this.setState({
    requestResponse:null
  });
  //Call api
  apiRequest.downloadFile(this,offer.id,item.id,item.isFolder);
}

handleDownloadAllFiles=()=>{  
  const {offer} = this.props;
  const {selected} = this.state;
  //Call api
  apiRequest.downloadAllFile(this,offer.id,(selected!==null && selected!==undefined) ? selected.id : 'null');
}
//**//

  //*Helper methods*//
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  
  handleCloseInfo = () =>{
    this.setState({
      info:false
    });
  }

  handleOpenInfo = () =>{
    this.setState({
      info:true
    });
  }

  handleOpenChanges = () =>{
    this.setState({
      changes:true
    });
  }

  handleCloseChanges = () =>{
    this.setState({
      changes:false
    });
  }
  //**//

  //*Handle modals*//
  handleResponsePanel(){
    this.setState({
      requestResponse:null
    })
  }
  //**//

  handleSelect = (item) =>{
    const {tableNavItems} = this.state;

    var navItems = null;
    if(tableNavItems.length===0){
      navItems = [{ text: '....', key: 0, onClick: this.onBreadcrumbItemClicked },{ text: item.name, key: 1,selected:item, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }];
    }else{
      navItems = tableNavItems;
      navItems.map(item=>item.isCurrentItem=false);
      navItems.push({ text: item.name,selected:item, key:navItems.length+2, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true });
    }
   
    this.setState({
      tableNavItems:navItems,
      selected:item
    });
  }

  onBreadcrumbItemClicked=(ev, item)=> {
    const {tableNavItems} = this.state;
    

    if(item.key===0){
      this.setState({
        tableNavItems:[],
        selected:null
      });
    }else {
      const navItems = tableNavItems;
      var filtered = null;
      filtered = navItems.filter(row=>row.key<=item.key);
      
      this.setState({
        tableNavItems:filtered,
        selected:item.selected
      });
    }
  }

  handleChanges = (showLoading) =>{
    const {offer} = this.props;

    if(showLoading){
      this.setState({
        loadingNotice:true
      });
    }


    const model ={...offer}
    model.lastEvent = eventyTypes.NOTHING;

    apiRequest.noticeChanges(this,model);
  }

  showChanges = () =>{
    this.handleOpenChanges();
    this.handleChanges(false);
  }


  render() {
    const { columns,info,tableNavItems,changes,loadingNotice, isCompactMode,first,last,loading, items,farCommandItems,zipIsProgress,downloadIsProgress,requestResponse, filteredItems,isFiltered  } = this.state;
    const {classes,navItems,offer} = this.props;
    return (
      <div>
        {navItems!=null && 
        <div className={classes.breadcrumb}>
          <Breadcrumb
            items={navItems}
            maxDisplayedItems={10}
          />
        </div>
        }
        <LoadingProgress open={loadingNotice}/>
        {changes && <OfferChanges offer={offer} open={true} hideModal={this.handleCloseChanges}/>}
         {/*Single download*/}
         <DownloadProgress onClose={this.closeProgressModal} open={downloadIsProgress!==null ? true : false}/>
          {/*Zip download*/}
          <DownloadProgress onClose={this.closeProgressModal} open={zipIsProgress}/>
        <CommandBar
          farItems={farCommandItems}
        />
        {info===true && <InfoCallout  onClose={()=>this.handleCloseInfo()} buttonId={`bt-info`} />}
         {offer!=null && <div className={classes.header}>
            <Grid spacing={2} container>
              <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                <Label>Ajánlat értéke</Label>
                <TextField value={offer.value} suffix={offer.currency} readOnly={true}/>
              </Grid>
              <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                <Label>Ajánlat érvényes a következőig</Label>
                <TextField value={offer.dateValidTo} iconProps={calenDarIcon} readOnly={true}/>
              </Grid>
              <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                <Label>Ajánlatot adta</Label>
                <TextField value={offer.offerCreator} iconProps={peopleIcon} readOnly={true}/>
              </Grid>               
            </Grid>
        </div>}

        <Separator></Separator>
        {requestResponse!==null &&
          <MessageBar
            messageBarType={this.setMessageBarType(requestResponse.type)}
            isMultiline={false}
            actions={
              <div>
                <MessageBarButton onClick={this.handleResponsePanel}>Ok</MessageBarButton>
              </div>
            }
          >
            {requestResponse.message}
          </MessageBar>
        }
        {offer.lastEvent === eventyTypes.EDIT && 
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            actions={
              <div>
                <MessageBarButton onClick={()=>this.handleChanges(true)}>Ok</MessageBarButton>
                <MessageBarButton onClick={this.showChanges}>Megtekintés</MessageBarButton>
              </div>
            }
          > 
            Változások történtek
          </MessageBar> 
        }
        <div className={classes.table}>
          <div style={style.fluentUI.controlWrapper}>
            <SearchBox style={style.fluentUI.search} placeholder="Keresés..." onChange={this._onChangeText} />
          </div>
          <div className={classes.breadcrumb}>
            <Breadcrumb
              items={tableNavItems}
              maxDisplayedItems={10}
            />
          </div> 
          <div style={style.fluentUI.tableWrapper}>
          <ScrollablePane>
            <DetailsList
              items={isFiltered ? filteredItems.slice(first,last) : items.slice(first,last)}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderRow={ this.onRenderColumnListRow}
              onRenderDetailsHeader={
                (detailsHeaderProps, defaultRender) => (
                  <Sticky>
                    {defaultRender(detailsHeaderProps)}
                  </Sticky>
                ) }
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
            />
            </ScrollablePane>
          </div>
          <Pagination loading={loading} parent={this} length={isFiltered ? filteredItems.length : items.length}/>
        </div>
      </div>
    );
  }

  //*To render table rows*//
  onRenderColumnListRow= (props) => {
    const customStyles = {};
    if (props) {
        customStyles.root = style.fluentUI.tableRow;
        return <DetailsRow {...props} styles={customStyles}/>;
    }
    return null;
  };


   _getKey(item, index) {
    return item.key;
  }

   _onChangeCompactMode = (ev, checked) => {
    this.setState({ isCompactMode: checked });
  };

   _onChangeText = (ev, text) => {
    if(text!=='' && text!==null && text!==undefined){
      this.setState({
        isFiltered:true
      });
    }else{
     this.setState({
       isFiltered:true
     });
    }
    const {items} = this.state;
     this.setState({
       filteredItems: text ? items.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
     });
  };

   _onItemInvoked(item) {
    if(item.isFolder){
      this.handleSelect(item)
    }
  }

   _onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns= columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
//**//


export default withStyles(style.materialUI)(ReceivedList);