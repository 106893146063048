import {
  mergeStyleSets,
  FontWeights,
} from 'office-ui-fabric-react';
import {lightTheme} from '../../../../../../../../themes';


const contentStyles = mergeStyleSets({
  callout: {
    width: 320,
    padding: '20px 24px',
    borderTop:'2px solid '+lightTheme.palette.themePrimary
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  download:{
    fontSize:'1rem',
    marginRight:'1rem',
    color:lightTheme.palette.themePrimary
  },
  downloadAll:{
    fontSize:'1rem',
    marginRight:'1rem',
    color:lightTheme.palette.themePrimary
  },
});

 
  export default {
    contentStyles
  };