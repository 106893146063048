import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
//Components
import { RouteWithLayout } from './components';
import {  Minimal as MinimalLayout, Main as MainLayout } from './layouts';


import {
    SignIn as SignInView,
    InnerHome as InnerHomeView,
    ExternalHome as ExternalHomeView
} from './views';


const Routes = (props) => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/sign-in"
            />
            <RouteWithLayout
                component={SignInView}
                layout={MinimalLayout}
                path="/sign-in"
                authorize={false}
            />
            <RouteWithLayout
                component={InnerHomeView}
                layout={MainLayout}
                path="/company/home"
                authorize={true}
            />
             <RouteWithLayout
                component={ExternalHomeView}
                layout={MainLayout}
                path="/partner/home"
                authorize={true}
            />
            {/*<RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
                authorize={false}
            />*/}
            {/*<RouteWithLayout
                component={UnauthorizedView}
                exact
                layout={MinimalLayout}
                path="/unauthorized"
                authorize={false}
            />*/}
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;