import * as React from 'react';
//Fluent UI
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import {
  Modal,
  IconButton,
  Spinner,
  MessageBar,
  MessageBarType,
  Checkbox,
  MessageBarButton
} from 'office-ui-fabric-react';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
//Icons
const cancelIcon = { iconName: 'Cancel' };

const classNames =  style.contentStyles;

const WarningPanel = (p) => (
  <MessageBar
    messageBarType={MessageBarType.warning}
    isMultiline={false}
    onDismiss={p.resetChoice}
    dismissButtonAriaLabel="Close"
    actions={
      <div>
        <MessageBarButton onClick={p.onYes}>Igen</MessageBarButton>
        <MessageBarButton onClick={p.onNo}>Nem</MessageBarButton>
      </div>
    }
  >
   Nincs lehetőség későbbi módosításra, biztosan a kiválasztott ajánlat nyert ?
  </MessageBar>
);


class DecisionModal extends React.Component{

  constructor(props){
    super(props);

    this.state={
      //request
      loading:true,
      saveRequest:false,
      response:null,
      //options
      options:[],
      //selected
      checked:null
    }

    this.handleCancel = this.handleCancel.bind(this);
    this.handleCancelRequest = this.handleCancelRequest.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveRequest = this.handleSaveRequest.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount(){
    const {offers} = this.props;
    const {checked} = this.state;
    var options = [];
    offers.map(offer=>(
    options.push(
          <div className={classNames.itemCell} data-is-focusable={true}>
            <Checkbox checked={offer.id===checked} onChange={(event)=>this.handleChange(event,offer)}  className={classNames.itemImage}/>
            <div className={classNames.itemContent}>
              <div className={classNames.itemName}>{offer.name}</div>
              <div className={classNames.itemIndex}>{`Érték: ${offer.value} ${offer.currency}`}</div>
              <div className={classNames.itemIndex}>{`Érvényés:  ${offer.dateValidTo}`}</div>
            </div>
          </div>)
    ));
    this.setState({
      options,
      loading:false
    }) 
  }

  componentDidUpdate(prevProps,prevState){
    const {offers} = this.props;
    const {checked} = this.state;
    if(checked!==prevState.checked){
      var options = [];
      offers.map(offer=>(
      options.push(
            <div className={classNames.itemCell} data-is-focusable={true}>
              <Checkbox checked={offer.id===checked} onChange={(event)=>this.handleChange(event,offer)} className={classNames.itemImage}/>
              <div className={classNames.itemContent}>
                <div className={classNames.itemName}>{offer.name}</div>
                <div className={classNames.itemIndex}>{`Érték: ${offer.value} ${offer.currency}`}</div>
                <div className={classNames.itemIndex}>{`Érvényés:  ${offer.dateValidTo}`}</div>
              </div>
            </div>)
      ));
      this.setState({
        options
      }) 
    }

  }

  //*Handle user interactions*//
  handleCancel(){
    const {onClose}=this.props;
    onClose();
  }

  handleSaveRequest(){
    this.setState({
      saveRequest:true,
      response:null
    });
  }

  handleCancelRequest(){
    this.setState({
      saveRequest:false
    });
  }

  handleSave(){
    const {checked} = this.state;
    const model = {offerId:checked,decision:true};
    this.setState({
      saveRequest:false,
      loading:true
    })
    apiRequest.setDecision(this,model);
  }

  handleChange(event,item){
    const checked = event.target.checked
    const id = item.id;

    if(checked){
      this.setState({
        checked:id
      })
    }else{
      this.setState({
        checked:null
      })
    }
  }

  //**//

  //*Helper methods*//
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }
  //**//

  render(){
    const {loading,response,saveRequest,checked,options} = this.state;
    const {open,onClose}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={onClose}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>Elbírálás</div>
            <div className={style.contentStyles.titleIndex}>Nyertes ajánlat kiválasztása</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onClose}
          />
        </div>
        {loading===false && <div className={style.contentStyles.body}>
          {response!==null &&
            <MessageBar
              messageBarType={this.setMessageBarType(response.type)}
              isMultiline={false}
            >
              {response.message}
            </MessageBar>
          }
          {saveRequest==true && 
            <WarningPanel 
              onYes={()=>this.handleSave()} 
              onNo={this.handleCancelRequest}
            />
          }
          <div className={classNames.container}> 
            {options}
          </div>

        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Betöltés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton
            disabled={(saveRequest===true || checked===null) ? true : false} 
            onClick={this.handleSaveRequest} 
            style={{marginRight:'5px'}}
          >
            Mentés
          </PrimaryButton>
          <DefaultButton onClick={this.handleCancel}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
};

export default DecisionModal;