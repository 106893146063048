import {lightTheme} from '../../../../../../themes';
import {
  getTheme,
  mergeStyleSets,
  FontWeights
} from 'office-ui-fabric-react';
import { getFocusStyle } from 'office-ui-fabric-react/lib/Styling';
import { FontSizes } from '@fluentui/style-utilities';
import { SharedColors } from '@fluentui/theme';




const theme = getTheme();
const { palette, semanticColors, fonts } = theme;
const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      minWidth:'400px'
    },
    fileInput:{
      backgroundColor:lightTheme.palette.themePrimary,
      color:lightTheme.palette.white,
      padding:'10px',
      fontSize:FontSizes.size14,
      fontWeight: FontWeights.semibold,
      cursor:'pointer',
      selectors: {
        ':hover': {
          backgroundColor: lightTheme.palette.themeDarkAlt,
        },
        ':focus':{
          backgroundColor: lightTheme.palette.themeDarkAlt,
        }
      }

    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${lightTheme.palette.themePrimary}`,
        color: lightTheme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    action:{
      padding: '0 24px 24px 24px',
      textAlign:'right'
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    filesTitle:{
      fontSize:FontSizes.size16
    },
    deleteIcon:{
      color:SharedColors.red10
    }
  });
  const listStyles =  mergeStyleSets({
    container: {
      overflow: 'auto',
      maxHeight: 500,
    },
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        boxSizing: 'border-box',
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        display: 'flex',
        selectors: {
          '&:hover': { background: palette.neutralLight },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    itemName: [
      fonts.medium,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    ],
    itemIndex: {
      fontSize: fonts.small.fontSize,
      color: palette.neutralTertiary,
      marginBottom: 10,
    },
    titleContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    titleName: [
      fonts.xLarge,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    ],
    titleIndex: {
      fontSize: fonts.medium.fontSize,
      color: palette.neutralTertiary,
      marginBottom: 10,
    }
  });
  const iconButtonStyles = {
    root: {
      color: lightTheme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: lightTheme.palette.neutralDark,
    },
  };
 
  export default {
    contentStyles,
    iconButtonStyles,
    listStyles
  };