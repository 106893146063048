import {lightTheme} from '../../../../../../themes';
import {
  getTheme,
  mergeStyleSets,
  FontWeights
} from 'office-ui-fabric-react';

const theme = getTheme();
const { palette, fonts,semanticColors } = theme;
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  titleContent: {
    marginLeft: 10,
    overflow: 'hidden',
    flexGrow: 1,
  },
  itemImage: {
    flexShrink: 0,
    marginTop:'2rem',
    marginRight:'0.5rem'
  },
  errorIcon:{
    fontSize:'1rem',
    color:'#d13438'
  },
  progressIcon:{
    fontSize:'1rem',
    color:lightTheme.palette.themePrimary
  },
  okIcon:{
    fontSize:'1rem',
    color:'#8cbd18'
  },

  itemName: [
    fonts.mediumPlus,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  itemIndex: {
    fontSize: fonts.medium.fontSize,
    color: palette.neutralTertiary,
  },
  titleName: [
    fonts.xLarge,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  titleIndex: {
    fontSize: fonts.medium.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${lightTheme.palette.themePrimary}`,
      color: lightTheme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  action:{
    padding: '0 24px 24px 24px',
    textAlign:'right'
  },
  itemCell: {
      minHeight: 54,
      padding: 10,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
      selectors: {
        '&:hover': { background: palette.neutralLight },
      },
    },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  }
  });
  const iconButtonStyles = {
    root: {
      color: lightTheme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: lightTheme.palette.neutralDark,
    },
  };
 
  export default {
    contentStyles,
    iconButtonStyles
  };