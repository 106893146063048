//User action scopes

export const scopes = {
    TENDER_VIEW:1,
    TENDER_EDIT:2,
    TENDER_MANAGE_ROLES:3,
    TENDER_OWNER:5,
    OFFER_VIEW:4,
    OFFER_DECISION:6
}

export const eventyTypes ={
    CREATE:'Create',
    EDIT:'Edit',
    DELETE:'Delete',
    DOWNLOAD:'Download',
    LOGIN:'Login',
    LOGOUT:'Logout',
    CLOSED:'Closed',
    NOTHING:'Nothing'
}

