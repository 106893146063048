

class SignInModel
{
    userName;
    password;

    constructor(userName,password)
    {
        this.userName = userName;
        this.password = password;
    }

    getUserName(){
        return this.userName
    }

    getPassword(){
        return this.password;
    }
    
}

export default SignInModel;