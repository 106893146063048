import * as React from 'react';
//Fluent UI
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useId, useBoolean } from '@uifabric/react-hooks';
import { Spinner} from '@fluentui/react';
//Theme
const dialogStyles = { main: { maxWidth: 450 } };

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Törlés folyamatban...',
  closeButtonAriaLabel: 'Close',
};

const DeleteProgress = (props) => {
    const {open,onClose} = props;
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId = useId('dialogLabel');
  const subTextId = useId('subTextLabel');

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable, labelId, subTextId],
  );

  return (
      <Dialog
        hidden={!open}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
          <Spinner label="Törlés..."/>
        <DialogFooter>
          {/*<PrimaryButton onClick={onClose} text="Mégsem" />*/}
        </DialogFooter>
      </Dialog>
  );
};
export default DeleteProgress;