import * as React from 'react';
//Fluent UI
import {
  Callout,
  Text,
  Icon
} from 'office-ui-fabric-react';

//Theme
import style from './style';

const styles = style.contentStyles;


class InfoCallout extends React.Component{


  render(){
    const {buttonId,onClose,dateAvailable} = this.props;
    return (
      <Callout
      className={styles.callout}
      ariaLabelledBy='info-label'
      ariaDescribedBy='info-description'
      role="alertdialog"
      gapSpace={0}
      target={`#${buttonId}`}
      onDismiss={onClose}
      setInitialFocus
    >
     <Text block variant="xLarge" className={styles.title} id='info-label'>
        Jelmagyarázat
      </Text>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.openFolder} iconName="OpenFolderHorizontal"/>  
        <Text block variant="small" id='info-open-folder'>
          Partner megnyitása. (További információk, interakciók megtekintése)
        </Text>
      </div>
      <div style={{display:'flex',alignItems:'center',padding:'0.2rem'}}>
        <Icon className={styles.delete} iconName="Delete"/>  
        <Text block variant="small" id='info-open-folder'>
          Partner törlése. (Ha törlésre kerül, a továbbiakban nem lehet hozzáadni a kiírásokhoz)
        </Text>
      </div>

    </Callout>
  );
  }
};

export default InfoCallout;