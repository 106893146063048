import {Api,handleResponse,handleError} from '../../../api';
//Action
const Actions = {
    GetPartnerTags:'inner/get-partner-tags'
}


export const apiRequest = {
    getPartners
};


async function getPartners(parent) {
    await new Api().call.get(Actions.GetPartnerTags).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                partners:res,
                loading:{
                    ...prevState.loading,
                    partners:false,
                  },
            }));
        }).catch(handleError);
}