import {Api,handleResponse,handleError} from '../../../../../../../../api';
//Action
const Actions = {
    GetChanges: 'inner/tender-changes',
    GetFileChanges:'inner/tender-changes/files'
}


export const apiRequest = {
    getChanges,
    getFileChanges
};



async function getChanges(parent,offerId) {
    await new Api().call.get(`${Actions.GetChanges}/${offerId}`).then(handleResponse)
        .then(res => {
            parent.setState(prevState=>({
                loading:{
                    ...prevState.loading,
                    changes:false
                },
                changes:res
              }));
        }).catch(handleError);
}


async function getFileChanges(parent,offerId) {
    await new Api().call.get(`${Actions.GetFileChanges}/${offerId}`).then(handleResponse)
        .then(res => {
            parent.setState(prevState=>({
                  loading:{
                    ...prevState.loading,
                    fileChanges:false
                },
                fileChanges:res
              }));
        }).catch(handleError);
}