import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Get: 'partner/get-partners',
    Delete:'partner/delete-partners'
}


export const apiRequest = {
    getPartners,
    deletePartners
};

async function getPartners(parent) {
    await new Api().call.get(Actions.Get).then(handleResponse)
        .then(res => {
            parent.setState({
                loading:false,
                items:res
              });
        }).catch(handleError);
}

async function deletePartners(parent,data) {

    parent.setState({
        deleteIsProgress:true
    });

    await new Api().call.post(Actions.Delete,data).then(handleResponse)
        .then(res => {
            parent.setState({
                deleteRequest:null,
                requestResponse:res,
            });

            parent.refreshTable();
        }).catch(error=>{
            handleError(error);

            let response = {message:'Váratlan hiba miatt nem törölhető',type:'error'}
            if(error.response!==null && error.response!==undefined && error.response.data!==null && error.response.data!==undefined){
                if(error.response.data.message!==null && error.response.data.message!==undefined)
                response=error.response.data
            }
            parent.setState({
                requestResponse: response,
                deleteRequest:null,
            });
        });
}