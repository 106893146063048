import { authenticationService } from '../services';
import {UserStore,Constants} from '../stores';
//import { NotificationBar } from 'components';


export default function handleError(error) {

    if(error.response){
      if ([401].indexOf(error.response.status) !== -1) {
        // auto logout if 401 Unauthorized response returned from api
        if(UserStore.getLoggedInStatusValue()==='LOGGED_IN'){
          authenticationService.logoutForced();
        }
      }else{
        if(error.response.data){
          if(error.response.data.message){
            console.log('Error', error.response.data.message);
            //NotificationBar(error.response.data.message, 'top', 'right', 4000, 'error', null);
          }       
        }
      }
    }else if(error.request){
      if(error.toJSON().message==='Network Error'){
        console.log('Error', "A kiszolgáló nem elérhető");
        //NotificationBar("A kiszolgáló nem elérhető", 'top', 'right', 3000, 'error', null);
      }else{
        //NotificationBar("Váratlan hiba történt", 'top', 'right', 3000, 'error', null);
      }
      //console.log(error.toJSON());
    }else{
      console.log('Error', error.message);
      //NotificationBar("Váratlan hiba történt", 'top', 'right', 3000, 'error', null);
    }
    //return Promise.reject(error);
}