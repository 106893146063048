import * as React from 'react';

import {
  TagPicker,
} from 'office-ui-fabric-react/lib/Pickers';




const pickerSuggestionsProps = {
  suggestionsHeaderText: 'Keresési találatok',
  mostRecentlyUsedHeaderText: 'Partnerek',
  noResultsFoundText: 'Nem található',
  loadingText: 'Betöltés',
  showRemoveButtons: false
};



const PartnerSelect= (props) => {
  const {disabled,tags,required,onChange,selected,placeholder} = props;
  // All pickers extend from BasePicker specifying the item type.
  const picker = React.useRef(null);

  const listContainsTagList = (tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
  };
  
  const filterSuggestedTags = (filterText, tagList) => {
    return filterText
      ? tags.filter(
          tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
        )
      : [];
  };
  
  const getTextFromItem = (item) => item.name;

  const returnPartners = () => {
    const used = selected.map(item=>item.key);
    return tags.filter(item=>!used.includes(item.key));
  };

  const inputProps = {
    placeholder: placeholder
  };


  return (
      <TagPicker
        disabled={disabled}
        removeButtonAriaLabel="Remove"
        onChange={onChange}
        selectedItems={selected}
        onEmptyInputFocus={returnPartners}
        onResolveSuggestions={filterSuggestedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={inputProps}
      />
  );
};

export default PartnerSelect;
