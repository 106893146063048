
//Theme
import style from './style';
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

import {eventyTypes} from '../../../../../../stores';

//Icons
const openFolderHorizontal = {iconName:'OpenFolderHorizontal'};
const deleteIcon ={iconName:'Delete'};
const tenderChangesIcon = {iconName:'PageHeaderEdit'};
const offerChangesIcon = {iconName:'PageEdit'};
const newOfferIcon = {iconName:'AddTo'};

function setColumns(parent,type){
    switch(type){
        case 'default':
            return [
              {
                key: 'column1',
                name: 'Azonosító',
                fieldName: 'id',
                minWidth: 100,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column2',
                name: 'Megnevezés',
                fieldName: 'name',
                minWidth: 250,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column3',
                name: 'Létrehozva',
                fieldName: 'dateCreatedValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: parent._onColumnClick,
                onRender: (item) => {
                  return <span>{item.dateCreated}</span>;
                },
                data: 'number',
                isPadded: true,
              },
              {
                key: 'column4',
                name: 'Módosítva',
                fieldName: 'dateModifiedValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
                onRender: (item) => {
                  return <span>{item.dateModified}</span>;
                },
                isPadded: true,
              },
              {
                key: 'column5',
                name: 'Utolsó módosító',
                fieldName: 'modifiedBy',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                data: 'string',
                onColumnClick: parent._onColumnClick
              },
              {
                key: 'column6',
                name: 'File-ok',
                fieldName: 'filesCount',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick
              },
              {
                key: 'column7',
                name: 'Partnerek',
                fieldName: 'partnersCount',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick
              },
              {
                key: 'column8',
                name: 'Beérkezett',
                fieldName: 'offersCount',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick
              },
              {
                key: 'column9',
                name: 'Lezárva',
                fieldName: 'isClosed',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
                onRender: (item) =>(item.isClosed===true ?  'Igen' : 'Nem')
              },
              {
                key: 'column10',
                name: 'Megnyitás',
                fieldName: 'open',
                style:style.fluentUI.fileIconCell,
                isIconOnly: true,
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: parent._onColumnClick,
                onRender: (item) => (<div>
                  <TooltipHost content="Megnyitás">
                     <IconButton
                      onClick={()=>parent.handleClick(item)}
                      iconProps={openFolderHorizontal}
                    />        
                  </TooltipHost>
                   {item.isClosed===false &&
                    <TooltipHost content="Törlés">
                        <IconButton
                          style={style.fluentUI.deleteIcon}
                          onClick={()=>parent.handleDeleteRequest(item)}
                          iconProps={deleteIcon}
                        />        
                    </TooltipHost>}
                    {item.tenderChanges === eventyTypes.EDIT && 
                    <TooltipHost content="Változott a kiírás">
                        <IconButton
                          style={style.fluentUI.changesIcon}
                          iconProps={tenderChangesIcon}
                        />        
                    </TooltipHost>}
                    {item.tenderChanges === eventyTypes.CREATE && 
                    <TooltipHost content="Új kiírás">
                        <IconButton
                          style={style.fluentUI.newIcon}
                          iconProps={newOfferIcon}
                        />        
                    </TooltipHost>}
                    {item.offerChanges === eventyTypes.EDIT && 
                    <TooltipHost content="Változtak a pályázatok">
                        <IconButton
                          style={style.fluentUI.changesIcon}
                          iconProps={offerChangesIcon}
                        />        
                    </TooltipHost>}
                    
                    </div>
                ),
              },     
            ]
        case 'delete':
          return [
            {
              key: 'column1',
              name: 'Azonosító',
              fieldName: 'id',
              minWidth: 100,
              maxWidth: 100,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              onColumnClick: parent._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'column2',
              name: 'Megnevezés',
              fieldName: 'name',
              minWidth: 250,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              onColumnClick: parent._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'column3',
              name: 'Létrehozva',
              fieldName: 'dateCreatedValue',
              minWidth: 70,
              maxWidth: 90,
              isResizable: true,
              onColumnClick: parent._onColumnClick,
              onRender: (item) => {
                return <span>{item.dateCreated}</span>;
              },
              data: 'number',
              isPadded: true,
            },
            {
              key: 'column4',
              name: 'Módosítva',
              fieldName: 'dateModifiedValue',
              minWidth: 70,
              maxWidth: 90,
              isResizable: true,
              data: 'number',
              onColumnClick: parent._onColumnClick,
              onRender: (item) => {
                return <span>{item.dateModified}</span>;
              },
              isPadded: true,
            },
            {
              key: 'column5',
              name: 'Utolsó módosító',
              fieldName: 'modifiedBy',
              minWidth: 120,
              maxWidth: 120,
              isResizable: true,
              data: 'string',
              onColumnClick: parent._onColumnClick
            },
            {
              key: 'column6',
              name: 'File-ok',
              fieldName: 'filesCount',
              minWidth: 70,
              maxWidth: 90,
              isResizable: true,
              data: 'number',
              onColumnClick: parent._onColumnClick
            },
            {
              key: 'column7',
              name: 'Partnerek',
              fieldName: 'partnersCount',
              minWidth: 70,
              maxWidth: 90,
              isResizable: true,
              data: 'number',
              onColumnClick: parent._onColumnClick
            },
            {
              key: 'column8',
              name: 'Beérkezett',
              fieldName: 'offersCount',
              minWidth: 70,
              maxWidth: 70,
              isResizable: true,
              data: 'number',
              onColumnClick: parent._onColumnClick
            },
            {
              key: 'column9',
              name: 'Lezárva',
              fieldName: 'isClosed',
              minWidth: 70,
              maxWidth: 70,
              isResizable: true,
              data: 'number',
              onColumnClick: parent._onColumnClick,
              onRender: (item) =>(item.isClosed===true ?  'Igen' : 'Nem')
            },
            {
              key: 'column10',
              name: 'Megnyitás',
              fieldName: 'open',
              style:style.fluentUI.fileIconCell,
              isIconOnly: true,
              minWidth: 16,
              maxWidth: 16,
              onColumnClick: parent._onColumnClick,
              onRender: (item) => (<div>
                <TooltipHost content="Megnyitás">
                   <IconButton
                    disabled
                    onClick={()=>parent.handleClick(item)}
                    iconProps={openFolderHorizontal}
                  />        
                </TooltipHost>
                 {item.isClosed===false &&
                  <TooltipHost content="Törlés">
                    <IconButton
                      disabled
                      style={style.fluentUI.deleteIcon}
                      onClick={()=>parent.handleDeleteRequest(item)}
                      iconProps={deleteIcon}
                    />        
                  </TooltipHost>}
                  {item.tenderChanges === eventyTypes.EDIT && 
                    <TooltipHost content="Változott a kiírás">
                        <IconButton
                          style={style.fluentUI.changesIcon}
                          iconProps={tenderChangesIcon}
                        />        
                    </TooltipHost>}
                    {item.tenderChanges === eventyTypes.CREATE && 
                    <TooltipHost content="Új kiírás">
                        <IconButton
                          style={style.fluentUI.newIcon}
                          iconProps={newOfferIcon}
                        />        
                    </TooltipHost>}
                    {item.offerChanges === eventyTypes.EDIT && 
                    <TooltipHost content="Változtak a pályázatok">
                        <IconButton
                          style={style.fluentUI.changesIcon}
                          iconProps={offerChangesIcon}
                        />        
                    </TooltipHost>}
                  </div>
              ),
            },     
          ]
        default:
            return []      
    }
}

function setCommandItems(parent) {
  return [
    {
      key: 'tenders',
      text: 'Kiírások',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Work' },
      subMenuProps: {
        items: [
          {
            key: 'new',
            text: 'Új létrehozása',
            iconProps: { iconName: 'Add',style:style.fluentUI.newIcon },
            onClick:()=>parent.handleOpen()
          },
        ],
      },
    }
  ]
}

function setFarCommandItems(parent){
  return [
    {
      key: 'info',
      text: 'Info',
      id:'bt-info',
      // This needs an ariaLabel since it's icon-only
      ariaLabel: 'Info',
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      onClick: () => parent.handleOpenInfo()
    },
  ]
}


export default {
  setColumns,
  setCommandItems,
  setFarCommandItems
}