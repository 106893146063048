import {lightTheme} from '../../themes';
import { getTheme,mergeStyleSets } from '@fluentui/react';
import { FontSizes } from '@fluentui/theme';

const theme = getTheme();


const contentStyles = mergeStyleSets({
  container: {
    position:'fixed',
    bottom: 0,
    borderTop: `4px solid ${lightTheme.palette.themeDark}`,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    minHeight:'fit-content'
  },
  body:{
    marginTop:'10px',
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
  },
  bodyLabel:{
    fontSize: FontSizes.size14
  }
});

export default contentStyles;