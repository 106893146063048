
//Theme
import style from './style';
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import React from 'react';

//Icons
const openFolderHorizontal = {iconName:'OpenFolderHorizontal'};
const deleteIcon ={iconName:'Delete'};


function setColumns(parent,type){
    switch(type){
        case 'default':
            return [
              {
                key: 'column1',
                name: 'Azonosító',
                fieldName: 'id',
                minWidth: 100,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column2',
                name: 'Megnevezés',
                fieldName: 'name',
                minWidth: 250,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column3',
                name: 'Kapcsolattartók',
                fieldName: 'contactCount',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
              },
              {
                key: 'column4',
                name: 'Pályázatban vett részt',
                fieldName: 'tenderCount',
                minWidth: 160,
                maxWidth: 160,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
              },
              {
                key: 'column6',
                name: 'Megnyitás',
                fieldName: 'open',
                style:style.fluentUI.fileIconCell,
                isIconOnly: true,
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: parent._onColumnClick,
                onRender: (item) => (
                <React.Fragment>
                  <TooltipHost content="Törlés">
                    <IconButton
                      style={style.fluentUI.deleteIcon}
                      onClick={()=>parent.handleDeleteRequest(item)}
                      iconProps={deleteIcon}
                    />        
                  </TooltipHost>
                  <TooltipHost content="Megnyitás">
                     <IconButton
                        onClick={()=>parent.handleClick(item)}
                        iconProps={openFolderHorizontal}
                     />        
                  </TooltipHost>
                </React.Fragment>
                ),
              },      
            ]
            case 'delete':
              return [
                {
                  key: 'column1',
                  name: 'Azonosító',
                  fieldName: 'id',
                  minWidth: 100,
                  maxWidth: 100,
                  isRowHeader: true,
                  isResizable: true,
                  isSortedDescending: false,
                  onColumnClick: parent._onColumnClick,
                  data: 'string',
                  isPadded: true,
                },
                {
                  key: 'column2',
                  name: 'Megnevezés',
                  fieldName: 'name',
                  minWidth: 250,
                  maxWidth: 250,
                  isRowHeader: true,
                  isResizable: true,
                  isSortedDescending: false,
                  onColumnClick: parent._onColumnClick,
                  data: 'string',
                  isPadded: true,
                },
                {
                  key: 'column3',
                  name: 'Kapcsolattartók',
                  fieldName: 'contactCount',
                  minWidth: 120,
                  maxWidth: 120,
                  isResizable: true,
                  data: 'number',
                  onColumnClick: parent._onColumnClick,
                },
                {
                  key: 'column4',
                  name: 'Pályázatban vett részt',
                  fieldName: 'tenderCount',
                  minWidth: 160,
                  maxWidth: 160,
                  isResizable: true,
                  data: 'number',
                  onColumnClick: parent._onColumnClick,
                },
                {
                  key: 'column6',
                  name: 'Megnyitás',
                  fieldName: 'open',
                  style:style.fluentUI.fileIconCell,
                  isIconOnly: true,
                  minWidth: 16,
                  maxWidth: 16,
                  onColumnClick: parent._onColumnClick,
                  onRender: (item) => (
                  <React.Fragment>
                    <TooltipHost content="Törlés">
                      <IconButton
                        disabled
                        style={style.fluentUI.deleteIcon}
                        onClick={()=>parent.handleDeleteRequest(item)}
                        iconProps={deleteIcon}
                      />        
                    </TooltipHost>
                    <TooltipHost content="Megnyitás">
                       <IconButton
                          disabled
                          onClick={()=>parent.handleClick(item)}
                          iconProps={openFolderHorizontal}
                       />        
                    </TooltipHost>
                  </React.Fragment>
                  ),
                },
          
              ]
        default:
            return []      
    }
}

function setCommandItems(parent){
  return [
    {
      key: 'partners',
      text: 'Partnerek',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Group' },
      subMenuProps: {
        items: [
          {
            key: 'new',
            text: 'Új létrehozása',
            iconProps: { iconName: 'Add',style:style.fluentUI.newIcon },
            onClick:()=>parent.handleOpen()
          },
        ],
      },
    }
  ]  
}

function setFarCommandItems(parent){
    return [
      {
        key: 'info',
        text: 'Info',
        id:'bt-info',
        // This needs an ariaLabel since it's icon-only
        ariaLabel: 'Info',
        iconOnly: true,
        iconProps: { iconName: 'Info' },
        onClick: () => parent.handleOpenInfo()
      },
    ]
  }

export default {
  setColumns,
  setFarCommandItems,
  setCommandItems
}
