import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Add: 'partner/add-contacts',
}


export const apiRequest = {
    addContact
};

async function addContact(parent,data) {
    await new Api().call.post(Actions.Add,data).then(handleResponse)
    .then(res => {
        parent.setState({
            response:res,
            loading:false
        });
    }).catch(error=>{
        if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
            parent.setState({
                response:error.response.data,
                loading:false
            });
        }
        else{
            parent.setState({
                response:{message:'Váratlan hiba történt',type:'error'},
                loading:false
            });
        }
        handleError(error);
    });
}