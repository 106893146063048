import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Get: 'partner/get-contacts',
    Edit: 'partner/edit-partner',
}


export const apiRequest = {
    getContacts,
    edit
};

async function getContacts(parent,partnerId) {
    await new Api().call.get(`${Actions.Get}/${partnerId}`).then(handleResponse)
        .then(res => {
            parent.setState({
                partner:{...parent.state.partner,contacts:res},
                loading:false
              });
        }).catch(handleError);
}

async function edit(parent,data) {
    const { setSelectedPartner } = parent.props;
    await new Api().call.post(Actions.Edit,data).then(handleResponse)
        .then(res => {
            setSelectedPartner(data);
            parent.setState({
                response:res,
                loading:false
            });
        }).catch(error=>{
            if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                parent.setState({
                    response:error.response.data,
                    loading:false
                });
            }
            else{
                parent.setState({
                    response:{message:'Váratlan hiba történt',type:'error'},
                    loading:false
                });
            }
            handleError(error);
        });
}