import * as React from 'react';
//Material UI
import {Grid,Accordion,AccordionSummary,AccordionDetails } from '@material-ui/core';
//FluentUI
import { 
  Sticky,
  ScrollablePane,
  Label,
  SearchBox,
  CommandBar,
  Breadcrumb,
  MessageBar,
  MessageBarType,
  MessageBarButton, 
  DayOfWeek,
  Icon,
  DetailsRow,
  Toggle,
  TextField} from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';

import { eventyTypes } from '../../../../../../stores';
//Components
import {EditTender,NewFile,AddRoles,Notifications} from '../../';
import {DateSelect, PartnerSelect} from '../../../../../../components/Inputs';
import {Pagination} from '../../../../../../components';
import EditRoles from '../../Modals/EditRoles';
import EditFile from '../../Modals/EditFile';
import NewFolder from '../../Modals/NewFolder';
import {apiRequest} from './apiRequest';
import {DownloadProgress,DeleteProgress, LoadingProgress,InfoCallout, TenderChanges} from './components';
import data from './data';
//Theme
import style from './style';
import { withStyles } from '@material-ui/core';


const WarningPanel = (p) => (
  <MessageBar
    messageBarType={MessageBarType.warning}
    isMultiline={false}
    onDismiss={p.resetChoice}
    dismissButtonAriaLabel="Close"
    actions={
      <div>
        <MessageBarButton onClick={p.onYes}>Igen</MessageBarButton>
        <MessageBarButton onClick={p.onNo}>Nem</MessageBarButton>
      </div>
    }
  >
   Biztosan törölni szeretnéd ?
  </MessageBar>
);

const TenderIsClosed =            
<MessageBar
  messageBarType={MessageBarType.severeWarning}
>
  A kiírást már lezárták, nem lehet további módosításokat elvégezni. 
</MessageBar>


export class FileList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //table rows
      items: [],
      tender:null,
      columns: [],
      isCompactMode: false,
      //interactions
      downloadIsProgress:null,
      zipIsProgress:false,
      deleteRequest:null,
      requestResponse:null,
      deleteIsProgress:false,
      //request
      loading:{
        tender:true,
        notice:false,
        files:true
      },
      //pagination
      first:0,
      last:0,
      //filter
      filteredItems:[],
      isFiltered:false,
      //helper
      firstDayOfWeek:DayOfWeek.Monday,
      //modals
      modal:{
        edit:false,
        newFile:false,
        addRoles:false,
        editRoles:false,
        editFile:null,
        notifications:false,
        newFolder:false,
        changes:false,
      },
      //commands
      commandItems:[],
      farCommandItems:[],
      info:false,
      tableNavItems:[],
      selected:null
    };

    this.handleDownload = this.handleDownload.bind(this);
    this._onItemInvoked  = this._onItemInvoked.bind(this);
    //Modal actions
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.handleEditFile = this.handleEditFile.bind(this);
    this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    //Refresh actions
    this.refreshTender = this.refreshTender.bind(this);
    this.refreshFiles = this.refreshFiles.bind(this);

    this.closeProgressModal = this.closeProgressModal.bind(this);
    this.handleResponsePanel = this.handleResponsePanel.bind(this);
  }

  componentDidMount(){
    const {tender} = this.props;
    apiRequest.getInfos(this,tender.id);
    const model = {tenderId:tender.id};
    apiRequest.getFiles(this,model);
    this.setState({
      columns: data.setColumns(this,'empty')
    })
  }


  componentDidUpdate(prevProps,prevState){
      const {deleteRequest,downloadIsProgress,items,tender,modal,selected} = this.state;

      /*if(prevState.modal.edit!==modal.edit){
        if(modal.edit===false){
          apiRequest.getInfos(this,this.props.tender.id);
        }
      }*/

      if(prevState.items!=items){
        if(items.length>0){
          this.setState({
            columns: data.setColumns(this,'default')
          });
        }else{
          this.setState({
            columns: data.setColumns(this,'empty')
          });
        }
      }

      if(prevProps.tender!==this.props.tender){
        if(items.length>0){
          this.setState({
            columns: data.setColumns(this,'default')
          });
        }else{
          this.setState({
            columns: data.setColumns(this,'empty')
          });
        }
      }

      if(downloadIsProgress!=prevState.downloadIsProgress){
        if(downloadIsProgress!==null){
          this.setState({
            columns:  data.setColumns(this,'downloadIsProgress')
          });
        }else{
          this.setState({
            columns: data.setColumns(this,'default')
          });
        }
      }
      
      if(deleteRequest!=prevState.deleteRequest){
        if(deleteRequest!==null){
          this.setState({
            columns: data.setColumns(this,'delete')
          });
        }else{
          this.setState({
            columns: data.setColumns(this,'default'),
            deleteIsProgress:false
          });
        }
      }
      if(prevState.tender!=tender){
        this.setState({
          commandItems: data.setCommandItems(this),
          farCommandItems:data.setFarCommandItems(this)
        });
      }
      if(prevState.selected!=selected){
          //Init states
          this.setState((prevState)=>({
            loading:{
              ...prevState.loading,
              files:true
            }
          }));
          //Call api
          const model = {tenderId:tender.id,parentId:(selected!==null && selected!==undefined) ? selected.id : null};
          apiRequest.getFiles(this,model);
      }
  } 

  //*Helper methods*//
  refreshTender(tender){
    const {setSelectedTender} = this.props;
    //Call api
    setSelectedTender(tender);
    apiRequest.getInfos(this,tender.id);

    const model = {tenderId:tender.id};
    apiRequest.getFiles(this,model);
  }

  refreshFiles(){
    const {tender} = this.props;
    const {selected} = this.state;
    //Init states
    this.setState((prevState)=>({
      loading:{
        ...prevState.loading,
        files:true
      }
    }));
    //Call api
    const model = {tenderId:tender.id,parentId:(selected!==null && selected!==undefined) ? selected.id : null};
    apiRequest.getFiles(this,model);
  }

  
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }

  closeProgressModal(){
    this.setState({
      downloadIsProgress:null,
      deleteIsProgress:false
    });
  }

  
  handleResponsePanel(){
    this.setState({
      requestResponse:null
    })
  }

  handleCloseInfo = () =>{
    this.setState({
      info:false
    });
  }

  handleOpenInfo = () =>{
    this.setState({
      info:true
    });
  }
  //**//

  //*Handle downloads*//
  handleDownload(item){
    const {tender} = this.props;
    //Init states
    this.setState({
      requestResponse:null
    });
    //Call api
    apiRequest.downloadFile(this,tender.id,item.id,item.isFolder);
  }

  handleDownloadAllFiles=()=>{  
    const {tender} = this.props;
    const {selected} = this.state;
    //Call api
    apiRequest.downloadAllFile(this,tender.id,(selected!==null && selected!==undefined) ? selected.id : 'null');
  }
  //**//


  //*Handle modals*//
  handleOpen(props){
     //Init states
    this.setState((prevState)=>({
      requestResponse:null,
      modal:{
        ...prevState.modal,
        [props]:true
      }
    }));
  }

  handleClose(props){
    this.setState((prevState)=>({
      modal:{
        ...prevState.modal,
        [props]:false
      }
    }));
  }
  //**//


  
  //*Delete actions*//
  handleDeleteAllRequest=()=>{
    const {items} = this.state;
    this.setState({
      deleteRequest:items,
      requestResponse:null,
    });
  }

  handleDeleteRequest(item){
    //Create array and push into the selected item
    let request = [];
    request.push(item);

    this.setState({
      deleteRequest:request,
      requestResponse:null,
    });
  }

  handleDelete(){
    const {tender} = this.props;
    const {deleteRequest} = this.state;

    //Fill model
    const model = [];
    deleteRequest.map(row=>{
      model.push({fileId:row.id,tenderId:tender.id,isFolder:row.isFolder})
    });
    //Call api
      apiRequest.deleteFile(this,model);  
  }

  handleCancelDelete(){
    this.setState({
      deleteRequest:null
    })
  }
  //**//


  //*Edit actions*//
  handleEditFile(props,item){
    this.setState((prevState)=>({
      requestResponse:null,
      modal:{
        ...prevState.modal,
        [props]:item
      }
    }));
  }

  handleCloseEditfile(props){
    this.setState((prevState)=>({
      modal:{
        ...prevState.modal,
        [props]:null,
      }
    }));
  }
  //**//

  
  //*Set new tender after edit*//
  handleSave(newTender){
    this.setState((prevState)=>({
      tender:newTender
    }));
   
  }
  //**//

  handleSelect = (item) =>{
    const {tableNavItems} = this.state;

    var navItems = null;
    if(tableNavItems.length===0){
      navItems = [{ text: '....', key: 0, onClick: this.onBreadcrumbItemClicked },{ text: item.name, key: 1,selected:item, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }];
    }else{
      navItems = tableNavItems;
      navItems.map(item=>item.isCurrentItem=false);
      navItems.push({ text: item.name,selected:item, key:navItems.length+2, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true });
    }
   
    this.setState({
      tableNavItems:navItems,
      selected:item
    });
  }

  onBreadcrumbItemClicked=(ev, item)=> {
    const {tableNavItems} = this.state;
    

    if(item.key===0){
      this.setState({
        tableNavItems:[],
        selected:null
      });
    }else {
      const navItems = tableNavItems;
      var filtered = null;
      filtered = navItems.filter(row=>row.key<=item.key);
      
      this.setState({
        tableNavItems:filtered,
        selected:item.selected
      });
    }
  }

  handleChanges = (showLoading) =>{
    const {tender} = this.state;

    if(showLoading){
      this.setState(prevState=>({
        loading:{
          ...prevState.loading,
          notice:true
        }
      }))
    }

    apiRequest.noticeChanges(this,tender);
  }

  showChanges = () =>{
    this.handleOpenChanges();
    this.handleChanges(false);
  }

  handleOpenChanges = () =>{
    this.setState(prevState=>({
      modal:{
        ...prevState.modal,
        changes:true
      }    
    }));
  }

  handleCloseChanges = () =>{
    this.setState(prevState=>({
      modal:{
        ...prevState.modal,
        changes:false
      }    
    }));
  }




  render() {
    const { columns,tender,first,last,selected,info,tableNavItems, isCompactMode,loading,deleteIsProgress,zipIsProgress,downloadIsProgress,requestResponse, items,commandItems,farCommandItems,modal,deleteRequest,filteredItems,isFiltered   } = this.state;
    const {classes,navItems,partners} = this.props;
    return (
      <div>
        {modal.edit===true && <EditTender onRefresh={this.refreshTender} onSave={this.handleSave} tender={tender} partners={partners} open={modal.edit} hideModal={()=>this.handleClose('edit')}/>}
        {modal.newFile===true && <NewFile selected={selected} onRefresh={this.refreshFiles} tender={tender} open={modal.newFile} hideModal={()=>this.handleClose('newFile')}/>}
        {modal.addRoles===true && <AddRoles tender={tender} open={modal.addRoles} hideModal={()=>this.handleClose('addRoles')}/>}
        {modal.editRoles===true && <EditRoles tender={tender} open={modal.editRoles} hideModal={()=>this.handleClose('editRoles')}/>}
        {modal.editFile!==null && <EditFile onRefresh={this.refreshFiles} tender={tender} file={modal.editFile} open={true} hideModal={()=>this.handleCloseEditfile('editFile')}/>}
        {modal.notifications===true && <Notifications tender={tender} open={modal.notifications} hideModal={()=>this.handleClose('notifications')}/>}
        {modal.newFolder===true && <NewFolder selected={selected} onRefresh={this.refreshFiles} tender={tender} open={modal.newFolder} hideModal={()=>this.handleClose('newFolder')}/>}
        {info===true && <InfoCallout  onClose={()=>this.handleCloseInfo()} buttonId={`bt-info`} />}
        {navItems!=null && 
        <div className={classes.breadcrumb}>
          <Breadcrumb
            items={navItems}
            maxDisplayedItems={10}
          />
        </div>
        }
        <CommandBar
          items={commandItems}
          farItems={farCommandItems}
        />
        {/*Single download*/}
        <DownloadProgress onClose={this.closeProgressModal} open={downloadIsProgress!==null ? true : false}/>
         {/*Zip download*/}
        <DownloadProgress onClose={this.closeProgressModal} open={zipIsProgress}/>
        <DeleteProgress onClose={this.closeProgressModal} open={deleteIsProgress}/>
        <LoadingProgress  open={loading.tender || loading.notice}/>
        {modal.changes && <TenderChanges tender={tender} open={true} hideModal={this.handleCloseChanges}/>}      
        {tender!=null && tender.isClosed===true && TenderIsClosed}
        {tender!==null && tender.tenderChanges === eventyTypes.EDIT && 
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            actions={
              <div>
                <MessageBarButton onClick={()=>this.handleChanges(true)}>Ok</MessageBarButton>
                <MessageBarButton onClick={this.showChanges}>Megtekintés</MessageBarButton>
              </div>
            }
          > 
            Változások történtek
          </MessageBar> 
        }
        <Accordion>
        <AccordionSummary
          expandIcon={<Icon style={{fontSize:'1rem'}} iconName="ChevronDownMed" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordion}
        >
          <Label className={classes.heading}>Kiírás törzsadatok</Label>
        </AccordionSummary>
        <AccordionDetails>
        {tender!=null &&
            <Grid spacing={2} container>
              <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                <Label>Beadási időszak kezdete</Label>
                <DateSelect value={new Date(tender.startDate)} disabled={true}/>
              </Grid>
              <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                <Label>Beadási időszak vége</Label>
                <DateSelect value={new Date(tender.endDate)} disabled={true}/>
              </Grid>
              <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                <Label>Munkaszám</Label>
                <TextField value={tender.workNumber} disabled={true}/>
              </Grid>
              <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                <Label>Ajánlati szám</Label>
                <TextField value={tender.offerNumber} disabled={true}/>
              </Grid>
              <Grid xs={12}  sm={12} md={12} lg={12} xl={12} item>
                <Label>Leírás</Label>
                <TextField multiline={true} value={tender.description} disabled={true}/>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={5} xl={5} item>
                <Label>Kiválasztott partnerek</Label>
                <PartnerSelect placeholder="Nincs kiválasztva partner" selected={tender.partners} tags={partners} disabled={true} />
              </Grid>
              <Grid xs={12} sm={12} md={4} lg={2} xl={2} item>
                <Toggle disabled checked={tender.showBestOffer} label="Legjobb ajánlat mutatása" defaultChecked onText="Igen" offText="Nem" />
              </Grid>    
            </Grid>
          }
        </AccordionDetails>
      </Accordion>



        
        {deleteRequest!==null && 
          <WarningPanel 
            onYes={()=>this.handleDelete(deleteRequest)} 
            onNo={this.handleCancelDelete}
          />
        }
        {requestResponse!==null &&
          <MessageBar
            messageBarType={this.setMessageBarType(requestResponse.type)}
            isMultiline={false}
            actions={
              <div>
                <MessageBarButton onClick={this.handleResponsePanel}>Ok</MessageBarButton>
              </div>
            }
          >
            {requestResponse.message}
          </MessageBar>
        }
        <div className={classes.table}>
          <div style={style.fluentUI.controlWrapper}>
            <SearchBox style={style.fluentUI.search} placeholder="Keresés..." onChange={this._onChangeText} />
          </div>
          <div className={classes.breadcrumb}>
            <Breadcrumb
              items={tableNavItems}
              maxDisplayedItems={10}
            />
          </div> 
          <div style={style.fluentUI.tableWrapper}>
          <ScrollablePane>
            <DetailsList
              items={isFiltered ? filteredItems.slice(first,last) : items.slice(first,last)}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={
                (detailsHeaderProps, defaultRender) => (
                  <Sticky>
                    {defaultRender(detailsHeaderProps)}
                  </Sticky>
                ) }
              isHeaderVisible={true}
              onRenderRow={ this.onRenderColumnListRow}
              onItemInvoked={this._onItemInvoked}
            />
            </ScrollablePane>
          </div>
          <Pagination loading={loading.files} parent={this} length={isFiltered ? filteredItems.length : items.length}/>
        </div>
      </div>
    );
  }

    //*To render table rows*//


    
  onRenderColumnListRow= (props) => {
    const {deleteRequest} = this.state
    const customStyles = {};
    if (props) {
      if(deleteRequest!==null && deleteRequest!==undefined && deleteRequest.filter(row=>row.id===props.item.id).length>0){
        customStyles.root = style.fluentUI.highlightRow;
        return <DetailsRow {...props} styles={customStyles} />;
      }else{
        customStyles.root = style.fluentUI.tableRow;
        return <DetailsRow {...props} styles={customStyles}/>;
      }

    }
    return null;
  };

   _getKey(item, index) {
    return item.key;
  }

   _onChangeCompactMode = (ev, checked) => {
    this.setState({ isCompactMode: checked });
  };

  _onChangeText = (ev, text) => {
    if(text!=='' && text!==null && text!==undefined){
      this.setState({
        isFiltered:true
      });
    }else{
     this.setState({
       isFiltered:true
     });
    }
    const {items} = this.state;
     this.setState({
       filteredItems: text ? items.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
     });
  };

   _onItemInvoked(item) {
      if(item.isFolder){
        this.handleSelect(item)
      }
  }


   _onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns= columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

//**//

export default withStyles(style.materialUI)(FileList);