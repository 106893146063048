import React,{Component} from 'react';
//MaterialUI
import { Grid, Paper,withStyles } from '@material-ui/core';
//FluentUI
import { Pivot,PivotItem} from '@fluentui/react';
import style from './style';
//Components
import { TenderList,FileList } from './components';

class External extends Component{

    constructor(props){
        super(props);
        this.state={
            //nav
            nav:[],
            //screen variations
            variation:1,
            //selected
            selected:null,
            //currencies
            currencyOptions:[]
        }
        this.handleSelect = this.handleSelect.bind(this);
        this.onBreadcrumbItemClicked = this.onBreadcrumbItemClicked.bind(this);
    }

    componentDidMount(){
        this.setState({
            currencyOptions:[
                { key: 'HUF', text: 'HUF'},
                { key: 'EUR', text: 'EUR'},
                { key: 'USD', text: 'USD'},
              ]
        });
    }

    //*Handle navigation*//
    onBreadcrumbItemClicked(ev, item) {
        const {selected} = this.state;
        if(item.key==="toTenders"){
          this.setState({
            nav:null,
            selected:null,
            variation:1
          });
        }
        if(item.key==="toFiles"){
            this.setState({
                nav:[
                    { text: '....', key: 'toTenders', onClick: this.onBreadcrumbItemClicked },
                    { text: selected.name, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true},
                  ],
              variation:2
            });
          }
    }

    handleSelect(item){
        this.setState({
            selected:item,
            nav:[
                { text: '....', key: 'toTenders', onClick: this.onBreadcrumbItemClicked },
                { text: item.name, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true},
              ],
            variation:2
        });
    }
    //**//


    render()
    {
        const {classes} = this.props;
        const {nav,variation,selected,currencyOptions} = this.state;
        return(
        <div className={classes.root}> 
            <Grid
                container
                spacing={2}
            >
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={false}
                  sm={false}
                  xs={false}
                ></Grid>
                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={12}
                  sm={12}
                  xs={12}
                >
                    <Pivot>
                        <PivotItem headerText="Pályázatok">
                            <Paper elevation={3}>
                                {variation===1 &&  <TenderList  navItems={nav} onSelect={this.handleSelect}/>}
                                {variation===2 &&  <FileList currencyOptions={currencyOptions} tender={selected} onSelect={this.handleSelect}  navItems={nav}/>}
                            </Paper>       
                        </PivotItem>      
                    </Pivot>
                </Grid>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={false}
                  sm={false}
                  xs={false}
                ></Grid>
               
            </Grid>
        </div>)
    }
}

export default withStyles(style.materialUI)(External);