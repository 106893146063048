import * as React from 'react';
//Material UI
import {Divider, Grid} from '@material-ui/core';
//Fluent UI
import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';
import {
  TextField,
  Modal,
  IconButton,
  MessageBarType,
  MessageBar,
  Spinner
} from 'office-ui-fabric-react';
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
import { SharedColors } from '@fluentui/theme';

const cancelIcon = { iconName: 'Cancel' };
const addIcon = { iconName: 'Add' };
const deleteIcon = { iconName: 'Delete',style:{color:SharedColors.red10}};

class NewFolder extends React.Component{

  constructor(props){
    super(props);
    this.state={
      //folder name
      name:'',
      //response
      response:null,
      //request
      loading:false
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
  }

  //*Handle user interactions*//
  onTextChange(event){
    const {partner} = this.state;
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
        [name]:value,
        response:null
    });
  }
  //**//

  //*Handle user actions*//
  handleSave(){
    const {partner,name} = this.state;
    const {offer,selected} = this.props;
    const model = {offerId:offer.id,name,parentId:(selected!==null && selected!==undefined) ? selected.id : null};

    this.setState({
      response:null,
      loading:true
    });
    apiRequest.createFolder(this,model);
  }
  //**//

  //*Helper methods*//

  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }

  validate(){
    const {name} = this.state;
    if(name!==null && name!==undefined && name!==''){
            return true;
    }
    return false;
  }

  //**//

  render(){
    const {name,loading,response} = this.state;
    const {open,hideModal}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>Új mappa létrehozása</div>
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false && <div className={style.contentStyles.body}>
          {response!==null &&
              <MessageBar
                messageBarType={this.setMessageBarType(response.type)}
                isMultiline={false}
              >
              {response.message}
              </MessageBar>
            }
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <TextField required onChange={this.onTextChange} name="name" value={name} label="Megnevezés" />
            </Grid>
          </Grid>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Betöltés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <PrimaryButton disabled={!this.validate() || loading} onClick={this.handleSave} style={{marginRight:'5px'}}>
            Létrehozás
          </PrimaryButton>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
  
};

export default NewFolder;