import * as React from 'react';
import { NormalPeoplePicker, ValidationState } from 'office-ui-fabric-react/lib/Pickers';

const suggestionProps = {
  suggestionsHeaderText: 'Keresési találatok',
  mostRecentlyUsedHeaderText: 'Emberek',
  noResultsFoundText: 'Nem található',
  loadingText: 'Betöltés',
  showRemoveButtons: false
};

const inputProps = {
  placeholder: 'Kereséshez kezdj el gépelni...'
};


const PeoplePicker = (props) => {
  const {selected,peopleList,disabled,onChange} = props;

  const picker = React.useRef(null);

  const onFilterChanged = (
    filterText,
    currentPersonas,
    limitResults,
  ) => {
    if (filterText) {
      let filteredPersonas= filterPersonasByText(filterText);

      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
      return filteredPersonas;
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText) => {
    return peopleList.filter(item => doesTextStartWith(item.text, filterText));
  };

  const returnPersons = () => {
    return peopleList.filter(item=>!selected.includes(item.key));
  };


  return (
      <NormalPeoplePicker
        // eslint-disable-next-line react/jsx-no-bind
        onResolveSuggestions={onFilterChanged}
        // eslint-disable-next-line react/jsx-no-bind
        onEmptyInputFocus={returnPersons}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={'ms-PeoplePicker'}
        key={'normal'}
        // eslint-disable-next-line react/jsx-no-bind
        onValidateInput={validateInput}
        componentRef={picker}
        onInputChange={onInputChange}
        onChange={onChange}
        disabled={disabled}
        inputProps={inputProps}
      />
  );
};

function doesTextStartWith(text, filterText) {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

function removeDuplicates(personas, possibleDupes) {
  return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona, personas) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}

/*function convertResultsToPromise(results) {
  return new Promise((resolve, reject) => setTimeout(() => resolve(results), 2000));
}*/

function getTextFromItem(persona) {
  return persona.text;
}

function validateInput(input) {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

/**
 * Takes in the picker input and modifies it in whichever way
 * the caller wants, i.e. parsing entries copied from Outlook (sample
 * input: "Aaron Reid <aaron>").
 *
 * @param input The text entered into the picker.
 */
function onInputChange(input) {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }

  return input;
}

export default PeoplePicker;
