import {call,put,takeEvery} from 'redux-saga/effects';

import {Api,handleResponse,handleError} from '../../api';
const Actions = {
    GetFile:'inner/get-file'
}




function download(payload){
    const {tenderId,fileId,parent} = payload;;
    const {downloadIsProgress} = parent.state;
    return new Api().call.get(`${Actions.GetFile}/${tenderId}/${fileId}`).then(handleResponse)
    .then(res => async function(res){
        var blob = new Blob([res.file], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.fileName;
        link.download = fileName+res.fileType;
        link.click();
        }
       /* //let jsonObject = JSON.parse(res);
        var sampleArr = base64ToArrayBuffer(res.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.fileName;
        link.download = fileName+res.fileType;
        link.click();
        //Remove from progress array 
        let temp = [...downloadIsProgress];
        let newTemp = temp.filter(item=>item!==payload.fileId);
            payload.parent.setState({
                downloadIsProgress:newTemp
        });*/
    ).catch(error=>{
        throw error
        /*handleError(error);
         //Remove from progress array 
         let temp = [...downloadIsProgress];
         let newTemp = temp.filter(item=>item!==payload.fileId);
            payload.parent.setState({
                 downloadIsProgress:newTemp
         });*/
    });
}


function* downloadFile(action){
    try {
        const response = yield call(download, action.payload);
        yield put({type: "FILE_DOWNLOAD_SUCCEEDED", response: response});
     } catch (e) {
        yield put({type: "FILE_DOWNLOAD_FAILED", message: e.message});
     }
}

function* fileSaga() {
    yield takeEvery("FILE_DOWNLOAD_REQUESTED", downloadFile);
}

  export default fileSaga;

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }