import {Api,handleResponse,handleError} from '../../../../../../api';
//Fluent UI
import {
    DropdownMenuItemType
  } from 'office-ui-fabric-react';
//Action
const Actions = {
    GetOptions:'inner/role-options',
    GetPersonOptions:'inner/person-options',
    AddRoles:'inner/create-new-tender-role'
}


export const apiRequest = {
    getOptions,
    getPersonOptions,
    addRoles
};

async function getOptions(parent) {
    await new Api().call.get(Actions.GetOptions).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                options:res.map(item=>(
                    {key:item.key,text:item.label,itemType:item.isHeader ? DropdownMenuItemType.Header : item.isDivider ? DropdownMenuItemType.Divider : null})),
                loading:{
                    ...prevState.loading,
                    options:false
                }
            }));
        }).catch(handleError);
}

async function getPersonOptions(parent,tenderId) {
    await new Api().call.get(`${Actions.GetPersonOptions}/${tenderId}`).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                peopleOptions:res.map(item=>({
                    key:item.key,imageUrl:item.avatar,secondaryText:item.bio,text:item.name,imageInitials:item.imageInitials
                  })),    
                loading:{
                    ...prevState.loading,
                    peopleOptions:false
                }
            }));
        }).catch(handleError);
}

async function addRoles(parent,data) {
    const {refreshOptions} = parent;
    await new Api().call.post(Actions.AddRoles,data).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                loading:{
                    ...prevState.loading,
                    request:false,
                },
                roles:[],
                people:[],
                response:res
              }));
              refreshOptions();
            }).catch(error=>{
                if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                    parent.setState({
                        response:error.response.data,
                        loading:{
                            request:false
                        }
                    });
                }
                else{
                    parent.setState((prevState)=>({
                        response:{message:'Váratlan hiba történt',type:'error'},
                        loading:{
                            ...prevState.loading,
                            request:false
                        }
                    }));
                }
                handleError(error);
            });
}