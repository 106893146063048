import React,{Component} from 'react';
//FluentUI
import { Pivot,PivotItem,DefaultButton,Panel } from '@fluentui/react';
//MaterialUI
import { Grid, Paper,withStyles,Box,Badge } from '@material-ui/core';
import style from './style';
//Models
import {TenderModel,PartnerModel,ActivityItemModel} from '../../../model';
import {apiRequest} from './apiRequest';
//Components
import { TenderList,FileList,ReceivedList,ReceivedGroupedList, PartnerList, PartnerTendersList, PartnerTenderFilesList } from './components';
import { ActivityItems } from '../../../components/Inputs';

//Icons
const activitiesIcon = { iconName: 'GroupedList',style:{fontSize:'20px'}  };


class Inner extends Component{

  constructor(props){
    super(props)
    this.state={
      //navs
      tenderNav:[],
      partnerNav:[],
      //partners
      partners:[],
      //request
      loading:{
        partners:true
      },
      //screen variations
      variation:1,
      partnerVariation:1,
      //selected 
      selectedTender:null,
      selectedPartner:null,
      selectedPartnerTender:null,
      selectedReceivedGroup:null,
      //activities
      activities:[],
      activitiesPanel:false,
      newActivites:0,

    };

    this.handleSelect = this.handleSelect.bind(this);
    this.onBreadcrumbItemClicked = this.onBreadcrumbItemClicked.bind(this);
    this.handleReceived = this.handleReceived.bind(this);
    this.handleGroupedReceived = this.handleGroupedReceived.bind(this);
    this.dismissPanel = this.dismissPanel.bind(this);
    this.openPanel = this.openPanel.bind(this);
    this.handlePartnerTenders = this.handlePartnerTenders.bind(this);
    this.handlePartnerTenderFiles = this.handlePartnerTenderFiles.bind(this);
    this.setSelectedPartner = this.setSelectedPartner.bind(this);
    this.refreshPartners = this.refreshPartners.bind(this);
    this.setSelectedTender = this.setSelectedTender.bind(this);
    this.handleReceivedGroup = this.handleReceivedGroup.bind(this);
  }

  componentDidMount(){

    apiRequest.getPartners(this);

    /*this.setState({
     activities:[
       new ActivityItemModel(1,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(2,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(3,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(4,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(5,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(6,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(7,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(8,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(9,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(10,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(11,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(12,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(13,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(14,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(15,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null),
       new ActivityItemModel(16,'Teszt Felhasználó','feltöltötte','Teszt.pdf','Teszt pályázát','50 perce','TF','Teszt Felhasználó',null)
     ]
    });*/
  }

  componentDidUpdate(prevProps,prevState){
    const {selectedTender,selectedPartner} = this.state;
    if(prevState.selectedTender!=selectedTender){
      if(selectedTender!=null){
        this.setState({
          tenderNav:[
            { text: '....', key: 'toTenders', onClick: this.onBreadcrumbItemClicked },
            { text: selectedTender.name, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }
          ]
        });
      }
    }
    if(prevState.selectedPartner!=selectedPartner){
      if(selectedPartner!=null){
        this.setState({
          partnerNav:[
            { text: '....', key: 'toPartners', onClick: this.onBreadcrumbItemClicked },
            { text: selectedPartner.name, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }
          ]
        });
      }
    }
  }

  //*Handle navs*//

  onBreadcrumbItemClicked(ev, item) {
    const {selectedTender,selectedPartner} = this.state;
    if(item.key==="toTenders"){
      this.setState({
        tenderNav:null,
        selectedTender:null,
        variation:1
      });
    }else if(item.key==="toTender"){
      this.setState({
        tenderNav:[
          { text: '....', key: 'toTenders', onClick: this.onBreadcrumbItemClicked },
          { text: selectedTender.name, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }
        ],
        selectedReceivedGroup:null,
        variation:2
      });
    }else if(item.key==="toReceived"){
      this.setState({
        variation:3,
        selectedReceivedGroup:null,
        tenderNav:[
          { text: '....', key: 'toTenders', onClick: this.onBreadcrumbItemClicked },
          { text: selectedTender.name, key: 'toTender', onClick: this.onBreadcrumbItemClicked },
          { text: 'Beérkezett', key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }
        ]
      });
    }else if(item.key==='toPartners'){
      this.setState({
        partnerNav:null,
        selectedPartner:null,
        partnerVariation:1
      });
    }else if(item.key==='toPartnerTenders'){
      this.setState({
        partnerNav:[
          { text: '....', key: 'toPartners', onClick: this.onBreadcrumbItemClicked },
          { text: selectedPartner.name, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true},
        ],
        selectedPartnerTender:null,
        partnerVariation:2
      });
    }
  }

  //**//


  //*Handle select*//

  handleSelect(value){
    this.setState({
      selectedTender:value,
      variation:2
    });
  }

  handleGroupedReceived(value){
    const {selectedTender} = this.state;
    this.setState({
      variation:4,
      selectedReceivedGroup:value,
      tenderNav:[
        { text: '....', key: 'toTenders', onClick: this.onBreadcrumbItemClicked },
        { text: selectedTender.name, key: 'toTender', onClick: this.onBreadcrumbItemClicked },
        { text: 'Beérkezett', key: 'toReceived', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true },
        { text: value.name, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }
      ]
    });
  }

  handleReceived(){
    const {selectedTender} = this.state;
    this.setState({
      variation:3,
      tenderNav:[
        { text: '....', key: 'toTenders', onClick: this.onBreadcrumbItemClicked },
        { text: selectedTender.name, key: 'toTender', onClick: this.onBreadcrumbItemClicked },
        { text: 'Beérkezett', key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }
      ]
    });
  }

  
  handlePartnerTenders(value){
    this.setState({
      partnerVariation:2,
      selectedPartner:new PartnerModel(value.id,value.name),
    });
  }

  handlePartnerTenderFiles(item){
    const {selectedPartner} = this.state;
    this.setState({
      partnerVariation:3,
      selectedPartnerTender:item,
      partnerNav:[
        { text: '....', key: 'toPartners', onClick: this.onBreadcrumbItemClicked },
        { text: selectedPartner.name, key: 'toPartnerTenders', onClick: this.onBreadcrumbItemClicked},
        { text: item.tender, key: 'f1', onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }
      ]
    });
  }
  //**//

  //*Handle panels*//
  dismissPanel(){
    this.setState({
      activitiesPanel:false
    });
  }

  openPanel(){
    this.setState({
      activitiesPanel:true
    });
  }
  //**//


  //*Helper methods*//

  setSelectedPartner(value){
    this.setState({
      selectedPartner:value
    });
  }

  setSelectedTender(value){
    this.setState({
      selectedTender:value
    });
  }

  refreshPartners(){
    apiRequest.getPartners(this);
  }

  handleReceivedGroup(value){

    this.setState({
      selectedReceivedGroup:value
    });

  }
  //**//


    render()
    {
      const {classes} = this.props;
      const {tenderNav,variation,partners,selectedReceivedGroup,selectedTender,selectedPartner,activities,activitiesPanel,selectedPartnerTender,partnerVariation,partnerNav,newActivites} = this.state;
      return(
            <div className={classes.root}>
            <Grid
              container
              spacing={2}
            >
              <Box clone order={{ xs: 2, sm: 2,md:2,lg:1,xl:1 }}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Pivot>
                    <PivotItem
                      headerText="Kiírások"
                    >
                      <Paper elevation={3}>
                        {variation===1 &&  <TenderList partners={partners} navItems={tenderNav} onSelect={this.handleSelect}/>}
                        {variation===2 &&  <FileList setSelectedTender={this.setSelectedTender} tender={selectedTender} partners={partners} onReceived={this.handleReceived} navItems={tenderNav} />}
                        {variation===3 &&  <ReceivedGroupedList tender={selectedTender} partners={partners} onSelect={this.handleGroupedReceived} navItems={tenderNav}/>}  
                        {variation===4 &&  <ReceivedList onReceivedGroup={this.handleReceivedGroup} offer={selectedReceivedGroup} tender={selectedTender} partners={partners} navItems={tenderNav}/>}  
                      </Paper>       
                    </PivotItem>
                    <PivotItem headerText="Partnerek">
                      <Paper elevation={3}>
                      {partnerVariation===1 &&  <PartnerList onRefreshPartners={this.refreshPartners} onSelect={this.handlePartnerTenders}/>}
                      {partnerVariation===2 &&  <PartnerTendersList onRefreshPartners={this.refreshPartners} setSelectedPartner={this.setSelectedPartner} selectedPartner={selectedPartner} onSelect={this.handlePartnerTenderFiles} navItems={partnerNav} />}
                      {partnerVariation===3 &&  <PartnerTenderFilesList selectedPartner={selectedPartner} offer={selectedPartnerTender} navItems={partnerNav} />}
                      </Paper>
                    </PivotItem>        
                  </Pivot>              
                </Grid>
              </Box>
              {/*<Box clone order={{ xs: 1, sm: 1,md:1,lg:2,xl:2 }}>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div style={{textAlign:'right'}}>
                  <Badge classes={{badge:classes.badge}} badgeContent={newActivites} color="primary">
                    <DefaultButton
                      onClick={this.openPanel}
                      text="Aktivitások"
                      iconProps={activitiesIcon}
                      allowDisabledFocus
                    />  
                  </Badge>
                   
                  </div>
                  <Panel
                     styles={{
                      main: style.fluentUI.panelHeader
                    }}     
                    headerText="Aktivitások"
                    isOpen={activitiesPanel}
                    onDismiss={this.dismissPanel}
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel="Close"
                  >
                    <ActivityItems items={activities}/>
                  </Panel>
                </Grid>
                  </Box>*/}   
            </Grid>
          </div>)
    }
}

export default withStyles(style.materialUI)(Inner);