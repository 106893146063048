import * as React from 'react';
//Fluent UI
import { DefaultButton } from '@fluentui/react';
import {
  Modal,
  IconButton,
  Icon,
  Spinner
} from 'office-ui-fabric-react';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
//Icons
const cancelIcon = { iconName: 'Cancel' };

const classNames =  style.contentStyles;



class Notifications extends React.Component{

  constructor(props){
    super(props);

    this.state={
      //request
      loading:true,
      lastRun:null,
      //options
      items:[],
      options:[],
    }
  }

  componentDidMount(){
    const {tender} = this.props;
    apiRequest.getNotifications(this,tender.id); 
    apiRequest.getLastRun(this);
  }

  componentDidUpdate(prevProps,prevState){
    const {items} = this.state;
    if(items!=prevState.items){
      var options = [];
      items.map((item,index)=>(
        options.push(
              <div key={index} className={classNames.itemCell} data-is-focusable={true}>
                <div className={classNames.itemImage} >{item.hasProblem===true ?  <Icon className={classNames.errorIcon} iconName="Error"/> : (item.emailIsSent===true ?<Icon className={classNames.okIcon} iconName="CheckMark"/> : <Icon className={classNames.progressIcon} iconName="MailSchedule"/>)}</div>
                <div className={classNames.itemContent}>
                  <div className={classNames.itemName}>{item.emailSentTo}</div>
                  <div className={classNames.itemIndex}>{`Típus: ${item.type}`}</div>
                  <div className={classNames.itemIndex}>{`Kiküldve:  ${item.emailSentTime}`}</div>
                </div>
              </div>)
        ));
        this.setState({
          options
        }) 
    }
  }


  render(){
    const {loading,lastRun,options} = this.state;
    const {open,hideModal}=this.props;
    return (
      <Modal
        titleAriaId="edit-modal"
        isOpen={open}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={style.contentStyles.container}
      >
        <div className={style.contentStyles.header}>
          <div className={style.contentStyles.titleContent}>
            <div className={style.contentStyles.titleName}>Értesítések</div>
            {lastRun!==null && lastRun!==undefined &&<div className={style.contentStyles.titleIndex}>Szolgáltatás utolsó futása: {lastRun}</div>}
          </div>
          <IconButton
            styles={style.iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        {loading===false && <div className={style.contentStyles.body}>
          <div className={classNames.container}> 
            {options}
          </div>
        </div>}
        {loading===true && <div className={style.contentStyles.body}>
          <Spinner label="Törlés..." />
        </div>}
        <div className={style.contentStyles.action}>
          <DefaultButton onClick={hideModal}>
            Vissza
          </DefaultButton>
        </div>
       
      </Modal>
  );
  }
};

export default Notifications;