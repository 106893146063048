//Theme
import style from './style';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { Spinner } from '@fluentui/react';
import React from 'react';

//Icons
const downloadIcon = { iconName: 'Download' };
const editIcon ={iconName:'Edit'};
const deleteIcon ={iconName:'Delete'};

const classNames=  mergeStyleSets(style.fluentUI.fileIconCell,style.fluentUI.fileIconHeaderIcon);


function setColumns(parent,type){
  const {tender} = parent.props;
    switch(type){
        case 'empty':
          return [
            {
             key: 'column1',
             name: 'File típus',
             className: classNames.fileIconCell,
             iconClassName: classNames.fileIconHeaderIcon,
             ariaLabel: 'Column operations for File type, Press to sort on File type',
             iconName: 'Page',
             isIconOnly: true,
             fieldName: 'icon',
             minWidth: 16,
             maxWidth: 16,
             onColumnClick: parent._onColumnClick,
             onRender: (item) => (
                 <TooltipHost content={`${item.fileType} file`}>
                     <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${getFileIcon(item.fileType,item.isFolder)}.svg`} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
                 </TooltipHost>
                 ),
           },
           {
             key: 'column2',
             name: 'Megnevezés',
             fieldName: 'name',
             minWidth: 250,
             maxWidth: 250,
             isRowHeader: true,
             isResizable: true,
             isSortedDescending: false,
             onColumnClick: parent._onColumnClick,
             data: 'string',
             isPadded: true,
           },
           {
             key: 'column3',
             name: 'Feltöltve',
             fieldName: 'dateCreatedValue',
             minWidth: 70,
             maxWidth: 90,
             isResizable: true,
             onColumnClick: parent._onColumnClick,
             data: 'number',
             onRender: (item) => {
               return <span>{item.dateCreated}</span>;
             },
             isPadded: true,
           },
           {
             key: 'column4',
             name: 'Módosítva',
             fieldName: 'dateModifiedValue',
             minWidth: 70,
             maxWidth: 90,
             isResizable: true,
             data: 'number',
             onColumnClick: parent._onColumnClick,
             onRender: (item) => {
               return <span>{item.dateModified}</span>;
             },
             isPadded: true,
           },
           {
             key: 'column5',
             name: 'Utolsó módosító',
             fieldName: 'modifiedBy',
             minWidth: 120,
             maxWidth: 120,
             isResizable: true,
             data: 'string',
             onColumnClick: parent._onColumnClick,
             onRender: (item) => {
               return <span>{item.modifiedBy}</span>;
             },
           },
           {
             key: 'column6',
             fieldName: 'download',
             style:style.fluentUI.fileIconCell,              
             minWidth: 16,
             maxWidth: 16,         
             onRender: (item) => (
                <div>
                 <TooltipHost content="Letöltés">
                     <IconButton
                     onClick={()=>parent.handleDownload(item)}
                     iconProps={downloadIcon}
                     />        
                 </TooltipHost>
                 {isModifiable(parent) && 
                 <React.Fragment>
                  <TooltipHost content="Módosítás">
                      <IconButton
                      style={style.fluentUI.modifyIcon}
                      onClick={()=>parent.handleEditFile('editFile',item)}
                      iconProps={editIcon}
                      />        
                  </TooltipHost>
                  <TooltipHost content="Törlés">
                      <IconButton
                        disabled={parent.state.deleteRequest!==null ? true : false}
                        style={style.fluentUI.deleteIcon}
                        onClick={()=>parent.handleDeleteRequest(item)}
                        iconProps={deleteIcon}
                      />     
                  </TooltipHost>
                 </React.Fragment>}
                </div>     
             ),
           },
        ]     
        case 'default':
            return [
                {
                 key: 'column1',
                 name: 'File típus',
                 className: classNames.fileIconCell,
                 iconClassName: classNames.fileIconHeaderIcon,
                 ariaLabel: 'Column operations for File type, Press to sort on File type',
                 iconName: 'Page',
                 isIconOnly: true,
                 fieldName: 'icon',
                 minWidth: 16,
                 maxWidth: 16,
                 onColumnClick: parent._onColumnClick,
                 onRender: (item) => (
                     <TooltipHost content={`${item.fileType} file`}>
                         <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${getFileIcon(item.fileType,item.isFolder)}.svg`} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
                     </TooltipHost>
                     ),
               },
               {
                 key: 'column2',
                 name: 'Megnevezés',
                 fieldName: 'name',
                 minWidth: 250,
                 maxWidth: 250,
                 isRowHeader: true,
                 isResizable: true,
                 isSortedDescending: false,
                 onColumnClick: parent._onColumnClick,
                 data: 'string',
                 isPadded: true,
               },
               {
                 key: 'column3',
                 name: 'Feltöltve',
                 fieldName: 'dateCreatedValue',
                 minWidth: 70,
                 maxWidth: 90,
                 isResizable: true,
                 onColumnClick: parent._onColumnClick,
                 data: 'number',
                 onRender: (item) => {
                   return <span>{item.dateCreated}</span>;
                 },
                 isPadded: true,
               },
               {
                 key: 'column4',
                 name: 'Módosítva',
                 fieldName: 'dateModifiedValue',
                 minWidth: 70,
                 maxWidth: 90,
                 isResizable: true,
                 data: 'number',
                 onColumnClick: parent._onColumnClick,
                 onRender: (item) => {
                   return <span>{item.dateModified}</span>;
                 },
                 isPadded: true,
               },
               {
                 key: 'column5',
                 name: 'Utolsó módosító',
                 fieldName: 'modifiedBy',
                 minWidth: 120,
                 maxWidth: 120,
                 isResizable: true,
                 data: 'string',
                 onColumnClick: parent._onColumnClick,
                 onRender: (item) => {
                   return <span>{item.modifiedBy}</span>;
                 },
               },
               {
                 key: 'column6',
                 name:
                 <div>
                    <TooltipHost content="Összes letöltése">
                      <IconButton 
                        onClick={parent.handleDownloadAllFiles}  
                        iconProps={downloadIcon}
                      >
                      </IconButton>
                    </TooltipHost>
                    {isModifiable(parent) && 
                      <TooltipHost content="Összes törlése">
                        <IconButton
                            onClick={parent.handleDeleteAllRequest}
                            style={style.fluentUI.deleteIcon}
                            iconProps={deleteIcon}
                        />     
                      </TooltipHost>}
                 </div>,
                 fieldName: 'download',
                 style:style.fluentUI.fileIconCell,              
                 minWidth: 16,
                 maxWidth: 16,         
                 onRender: (item) => (
                    <div>
                     <TooltipHost content="Letöltés">
                         <IconButton
                         onClick={()=>parent.handleDownload(item)}
                         iconProps={downloadIcon}
                         />        
                     </TooltipHost>
                     {isModifiable(parent) && 
                     <React.Fragment>
                      <TooltipHost content="Módosítás">
                          <IconButton
                          style={style.fluentUI.modifyIcon}
                          onClick={()=>parent.handleEditFile('editFile',item)}
                          iconProps={editIcon}
                          />        
                      </TooltipHost>
                      <TooltipHost content="Törlés">
                          <IconButton
                            disabled={parent.state.deleteRequest!==null ? true : false}
                            style={style.fluentUI.deleteIcon}
                            onClick={()=>parent.handleDeleteRequest(item)}
                            iconProps={deleteIcon}
                          />     
                      </TooltipHost>
                     </React.Fragment>}
                    </div>     
                 ),
               },
            ]
        case 'delete':
              return [
                {
                 key: 'column1',
                 name: 'File típus',
                 className: classNames.fileIconCell,
                 iconClassName: classNames.fileIconHeaderIcon,
                 ariaLabel: 'Column operations for File type, Press to sort on File type',
                 iconName: 'Page',
                 isIconOnly: true,
                 fieldName: 'icon',
                 minWidth: 16,
                 maxWidth: 16,
                 onColumnClick: parent._onColumnClick,
                 onRender: (item) => (
                     <TooltipHost content={`${item.fileType} file`}>
                         <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${getFileIcon(item.fileType,item.isFolder)}.svg`} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
                     </TooltipHost>
                     ),
               },
               {
                 key: 'column2',
                 name: 'Megnevezés',
                 fieldName: 'name',
                 minWidth: 250,
                 maxWidth: 250,
                 isRowHeader: true,
                 isResizable: true,
                 isSortedDescending: false,
                 onColumnClick: parent._onColumnClick,
                 data: 'string',
                 isPadded: true,
               },
               {
                 key: 'column3',
                 name: 'Feltöltve',
                 fieldName: 'dateCreatedValue',
                 minWidth: 70,
                 maxWidth: 90,
                 isResizable: true,
                 onColumnClick: parent._onColumnClick,
                 data: 'number',
                 onRender: (item) => {
                   return <span>{item.dateCreated}</span>;
                 },
                 isPadded: true,
               },
               {
                 key: 'column4',
                 name: 'Módosítva',
                 fieldName: 'dateModifiedValue',
                 minWidth: 70,
                 maxWidth: 90,
                 isResizable: true,
                 data: 'number',
                 onColumnClick: parent._onColumnClick,
                 onRender: (item) => {
                   return <span>{item.dateModified}</span>;
                 },
                 isPadded: true,
               },
               {
                 key: 'column5',
                 name: 'Utolsó módosító',
                 fieldName: 'modifiedBy',
                 minWidth: 120,
                 maxWidth: 120,
                 isResizable: true,
                 data: 'string',
                 onColumnClick: parent._onColumnClick,
                 onRender: (item) => {
                   return <span>{item.modifiedBy}</span>;
                 },
               },
               {
                 key: 'column6',
                 name: 'Letöltés',
                 fieldName: 'download',
                 style:style.fluentUI.fileIconCell,
                 isIconOnly: true,
                 minWidth: 16,
                 maxWidth: 16,
                 onColumnClick: parent._onColumnClick,
                 onRender: (item) => (              
                    <div>
                     <TooltipHost content="Letöltés">
                         <IconButton
                          disabled
                          onClick={()=>parent.handleDownload(item)}
                          iconProps={downloadIcon}
                         />        
                     </TooltipHost>
                     {isModifiable(parent) && 
                     <React.Fragment>
                        <TooltipHost content="Módosítás">
                          <IconButton
                              disabled
                              style={style.fluentUI.modifyIcon}
                              onClick={()=>parent.handleEditFile('editFile',item)}
                              iconProps={editIcon}
                          />        
                      </TooltipHost>
                      <TooltipHost content="Törlés">
                          <IconButton
                            disabled
                            style={style.fluentUI.deleteIcon}
                            onClick={()=>parent.handleDeleteRequest(item)}
                            iconProps={deleteIcon}
                          />        
                      </TooltipHost>
                     </React.Fragment>}
                    </div>     
                 ),
               },
             ]
        case 'downloadIsProgress':
              return [
                {
                 key: 'column1',
                 name: 'File típus',
                 className: classNames.fileIconCell,
                 iconClassName: classNames.fileIconHeaderIcon,
                 ariaLabel: 'Column operations for File type, Press to sort on File type',
                 iconName: 'Page',
                 isIconOnly: true,
                 fieldName: 'icon',
                 minWidth: 16,
                 maxWidth: 16,
                 onColumnClick: this._onColumnClick,
                 onRender: (item) => (
                     <TooltipHost content={`${item.fileType} file`}>
                         <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${getFileIcon(item.fileType,item.isFolder)}.svg`} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
                     </TooltipHost>
                     ),
               },
               {
                 key: 'column2',
                 name: 'Megnevezés',
                 fieldName: 'name',
                 minWidth: 250,
                 maxWidth: 250,
                 isRowHeader: true,
                 isResizable: true,
                 isSortedDescending: false,
                 onColumnClick: this._onColumnClick,
                 data: 'string',
                 isPadded: true,
               },
               {
                 key: 'column3',
                 name: 'Feltöltve',
                 fieldName: 'dateCreatedValue',
                 minWidth: 70,
                 maxWidth: 90,
                 isResizable: true,
                 onColumnClick: this._onColumnClick,
                 data: 'number',
                 onRender: (item) => {
                   return <span>{item.dateCreated}</span>;
                 },
                 isPadded: true,
               },
               {
                 key: 'column4',
                 name: 'Módosítva',
                 fieldName: 'dateModifiedValue',
                 minWidth: 70,
                 maxWidth: 90,
                 isResizable: true,
                 data: 'number',
                 onColumnClick: this._onColumnClick,
                 onRender: (item) => {
                   return <span>{item.dateModified}</span>;
                 },
                 isPadded: true,
               },
               {
                 key: 'column5',
                 name: 'Utolsó módosító',
                 fieldName: 'modifiedBy',
                 minWidth: 120,
                 maxWidth: 120,
                 isResizable: true,
                 data: 'string',
                 onColumnClick: this._onColumnClick,
                 onRender: (item) => {
                   return <span>{item.modifiedBy}</span>;
                 },
               },
               {
                 key: 'column6',
                 name: 'Letöltés',
                 fieldName: 'download',
                 style:style.fluentUI.fileIconCell,
                 isIconOnly: true,
                 minWidth: 16,
                 maxWidth: 16,
                 onColumnClick: this._onColumnClick,
                 onRender: (item) => (
                  parent.state.downloadIsProgress===item.id ?
                  <Spinner style={{justifyContent:"unset"}} label="Letöltés folyamatban..." ariaLive="assertive" labelPosition="right" /> :               
                    <div>
                     <TooltipHost content="Letöltés">
                         <IconButton
                          disabled
                          onClick={()=>this.handleDownload(item)}
                          iconProps={downloadIcon}
                         />        
                     </TooltipHost>
                     {isModifiable(parent) && 
                      <React.Fragment>
                        <TooltipHost content="Módosítás">
                         <IconButton
                            disabled
                            style={style.fluentUI.modifyIcon}
                            onClick={()=>this.handleEditFile('editFile',item)}
                            iconProps={editIcon}
                         />   
                     </TooltipHost>
                      <TooltipHost content="Törlés">
                         <IconButton
                           disabled
                           style={style.fluentUI.deleteIcon}
                           onClick={()=>this.handleDeleteRequest(item)}
                           iconProps={deleteIcon}
                         />        
                     </TooltipHost>
                     </React.Fragment>}
                    </div>     
                 ),
               },
             ]
        default:
            return []      
    }
}

export default {
  setColumns,
  setCommandItems
}

function setCommandItems(parent){
  const {offer} = parent.state;
  const {tender} = parent.props;
 return [
    {
      key: 'files',
      disabled:!isModifiable(parent),
      text: 'File-ok',
      iconProps: { iconName: 'DocumentSet' },
      subMenuProps: {
        items: [
          {
            key: 'new-file',
            text: 'File feltöltése',
            disabled:!isModifiable(parent),
            iconProps: { iconName: 'OpenFile',style:style.fluentUI.newIcon },
            onClick:()=>parent.handleOpen('newFile'),
          },
          {
            key: 'new-folder',
            text: 'Mappa létrehozása',
            iconProps: { iconName: 'NewFolder',style:style.fluentUI.newIcon },
            onClick:()=>parent.handleOpen('newFolder'),
          },
        ],
      },
    },
    {
      key: 'offer',
      text: 'Ajánlat',
      disabled:!isModifiable(parent),
      iconProps: { iconName: 'Articles' },
      subMenuProps: {
        items: [
          {
            key: 'edit',
            text: 'Módosítás',
            disabled:!isModifiable(parent) || (offer!==null && offer!=='' ? false : true),
            onClick:()=>parent.handleOpen('edit'),
            iconProps: { iconName: 'Edit',style:style.fluentUI.modifyIcon },
          },
          {
                  key: 'delete',
                  text: 'Törlés',
                  iconProps: { iconName: 'Delete',style:style.fluentUI.deleteIcon },
                  onClick:()=>parent.handleOpen('delete'),
           },     
        ],
      },
    }
  ]
}

function getFileIcon(fileType,isFolder){
  const pictures = ['png','jpg'];
  const docs=['doc'];
  if(!isFolder){
    if(pictures.includes(fileType)){
      return 'photo';
    }else if(docs.includes(fileType)){
      return 'docx';
    }
    return fileType;
  }else{
    return 'folder';
  }
}

//Helper method to disable edit and delete buttons
function isModifiable(parent){
  const {tender} = parent.props;
  if(tender.isClosed==true || tender.isOver===true){
    return false
  }
  return true;
}