import React,{Component} from 'react'
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { Label,Spinner } from '@fluentui/react';
//Material UI
import { Divider } from '@material-ui/core';
//Theme
import style from './style';

//Icons
const pageLeftIcon = { iconName: 'PageLeft' };
const pageRightIcon = { iconName: 'PageRight' };
const lastPageIcon = { iconName: 'Next' };
const firtPageIcon = { iconName: 'Previous' };


class Pagination extends Component{

    constructor(props){
        super(props);

        this.state={
            //act page
            page:1,
            //all page
            allPage:1,
            //step
            step:10,
            //first page number
            first:1,
            //last page number
            last:this.props.length > 10 ? 10 : this.props.length
        }
    }

    componentDidMount(){
        const {step,first,last} = this.state;
        const {length,parent} = this.props; 
        //Calculate all page count
        var allPage = Math.ceil(length / step);
        this.setState({
            allPage
        });
    }

    componentDidUpdate(prevProps,prevState){
        const {page,step} = this.state;
        const {length,parent} = this.props; 

        //Calculate fist and last values
        if(prevState.page!==page){
            if(page===1){
                var first = page;
                if(length<1){
                    first = 0;
                }
                var last = page*step;
                if(last>length){
                    last = length;
                }
            }else{
                var first = ((page-1)*step)+1;
                var last = page*step;
                if(last>length){
                    last = length;
                }
            }
            //Set states
            this.setState({
                first,
                last
            });
            //Set parent states
            parent.setState({
                first:first-1,
                last
            });
        }

        if(prevProps.length!==length){
            //Calculate all page count
            var allPage = Math.ceil(length / step);

            var first = 1;
            if(length<1){
                first = 0;
            }
            var last = page*step;
            if(last>length){
                last = length;
            }

            //Set parent states
            this.setState({
                allPage,
                page:1,
                first,
                last
             });

            //Set parent states
            parent.setState({
                first:first>0 ? first-1 : 0,
                last
            });
        }
    }

    handleNext = () =>{
        this.setState({
            page:this.state.page+1
        });
    }

    handlePrevious = ()=>{
        this.setState({
            page:this.state.page-1
        });
    }

    handleLast = () =>{
        this.setState({
            page:this.state.allPage
        });
    }

    handleFirst = () =>{
        this.setState({
            page:1
        });
    }

    validateNext = () =>{
    const {last} = this.state;
    const {length} = this.props; 
        if(last<length){
            return false;
        }
      return true;
    }

    validatePrevious = () =>{
        const {page} = this.state;
            if(page>1){
                return false;
            }
        return true;
    }

    render(){
        const {page,first,last} = this.state;
        const {length,loading} = this.props; 
        return(
        <div style={style.fluentUI.pagination}>
            {loading===true && <Spinner label="Betöltés..." ariaLive="assertive" labelPosition="right" />}
            <div style={{flexGrow:3}}/>
            {length>0 && <React.Fragment>
            <Label style={style.fluentUI.paginationText}>Össz: {length}</Label>
            <Divider style={style.materialUI.divider} orientation="vertical" flexItem/>
            <Label style={style.fluentUI.paginationText}>{`${first}-${last}`}</Label>
            <Divider style={style.materialUI.divider} orientation="vertical" flexItem/>
            <IconButton
              disabled={this.validatePrevious()} 
              iconProps={firtPageIcon} 
              onClick={this.handleFirst}  
              title="Legelső" ariaLabel="First" 
            />
            <IconButton
              disabled={this.validatePrevious()}
              onClick={this.handlePrevious}  
              iconProps={pageLeftIcon} 
              title="Előző" ariaLabel="Left" 
            />
            <IconButton
              disabled={this.validateNext()}
              onClick={this.handleNext} 
              iconProps={pageRightIcon} 
              title="Következő" ariaLabel="Right" 
            />
            <IconButton
             disabled={this.validateNext()}  
             iconProps={lastPageIcon}
             onClick={this.handleLast}   
             title="Utolsó" ariaLabel="Last" 
            /></React.Fragment>}                 
        </div>);
    }
}

export default Pagination;