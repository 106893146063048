import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Get: 'inner/get-tenders',
    DeleteTender:'inner/delete-tender',
    NoticeChanges:'inner/tender-changes/notice'
}


export const apiRequest = {
    getTenders,
    deleteTender,
    noticeChanges
};

async function getTenders(parent) {
    await new Api().call.get(Actions.Get).then(handleResponse)
        .then(res => {
            parent.setState({
                loading:false,
                items:res
              });
        }).catch(handleError);
}

async function noticeChanges(parent,model) {
    
    await new Api().call.post(`${Actions.NoticeChanges}/${model.id}`).then(handleResponse)
        .then(res => {
        }).catch(handleError);
}

async function deleteTender(parent,data) {

    parent.setState({
        deleteIsProgress:true
    });

    await new Api().call.post(Actions.DeleteTender,data).then(handleResponse)
        .then(res => {
            parent.setState({
                deleteRequest:null,
                requestResponse:res,
            });

            parent.refresh();
        }).catch(error=>{
            handleError(error);

            let response = {message:'Váratlan hiba miatt nem törölhető',type:'error'}
            if(error.response!==null && error.response!==undefined && error.response.data!==null && error.response.data!==undefined){
                if(error.response.data.message!==null && error.response.data.message!==undefined)
                response=error.response.data
            }
            parent.setState({
                requestResponse: response,
                deleteRequest:null,
            });
        });
}