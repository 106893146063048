import * as React from 'react';
//Material UI
import {Divider, Grid} from '@material-ui/core';
//FluentUI
import { Sticky,
  ScrollablePane,
  Label,
  SearchBox,
  MessageBar,
  MessageBarButton,
  MessageBarType, 
  CommandBar,
  Breadcrumb, 
  DayOfWeek,
  DetailsRow,
  TextField, 
  Pivot, 
  PivotItem, 
  Separator} from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
//Components
import {EditOffer,AddOffer,NewFile,NewFolder} from '../../../components';
import {DownloadProgress,UploadedList,OfferDelete,InfoCallout,LoadingProgress,OfferChanges,TenderChanges} from './components';
import {Pagination} from '../../../../../../components';
import { eventyTypes } from '../../../../../../stores';
//Model
import data from './data';
//Api
import {apiRequest} from './apiRequest';
//Theme
import style from './style';
import { withStyles } from '@material-ui/core';

const calenDarIcon = { iconName: 'Calendar' };

const TenderIsClosed =            
<MessageBar
  messageBarType={MessageBarType.severeWarning}
>
  A kiírást már lezárták, nem lehet további módosításokat elvégezni. 
</MessageBar>

const BestOfferInfo = 
  <MessageBar
  messageBarType={MessageBarType.info}
>
  A pályazatok közötti legjobb ajánlat.
  Különböző valuták esetén, valutánként a legjobb ajánlatok vannak megjelenítve. 
   (Az összes pályázó látja ezt az informácót.)
</MessageBar>




export class FileList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //table rows
      items: [],
      columns: data.setColumns(this,'empty'),
      //offers
      offerFiles:[],
      bestOffer:null,
      offer:null,
      //nav
      pivotItem:'0',
      //pagination
      first:0,
      list:0,
      //request
      loading:true,
      notice:false,
      //filter
      filteredItems:[],
      isFiltered:false,
      //interactions
      downloadIsProgress:null,
      requestResponse:null,
      zipIsProgress:false,
      isCompactMode: false,
      //helper
      firstDayOfWeek:DayOfWeek.Monday,
      //modals
      modal:{
        edit:false,
        new:false,
        newFile:false,
        delete:false,
        newFolder:false,
        offer:false,
        tender:false,
      },
      //commands
      commandItems: [],
      farCommandItems: [],
      tableNavItems:[],
      info:false,
      selected:null,
      selectedOffer:null
    };

    this._onItemInvoked  = this._onItemInvoked.bind(this);
    //Modal actions
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleResponsePanel = this.handleResponsePanel.bind(this);
  }

  componentDidMount(){
    const {tender} = this.props;
    apiRequest.getOffer(this,tender.id);
    apiRequest.getBestOffer(this,tender.id);
    const model = {tenderId:tender.id};
    apiRequest.getFiles(this,model);

    this.setState({
      commandItems: data.setCommandItems(this),
      farCommandItems: data.setFarCommandItems(this)
    })
  }

  componentDidUpdate(prevProps,prevState){
    const {items,downloadIsProgress,offer,pivotItem,selected} = this.state;
    const {tender} = this.props;

    if(prevState.items!=items){
      if(items.length>0){
        this.setState({
          columns: data.setColumns(this,'default')
        });
      }else{
        this.setState({
          columns: data.setColumns(this,'empty')
        });
      }
    }
    if(downloadIsProgress!=prevState.downloadIsProgress){
      if(downloadIsProgress!==null){
        this.setState({
          columns:  data.setColumns(this,'downloadIsProgress')
        });
      }else{
        this.setState({
          columns: data.setColumns(this,'default')
        });
      }
    }
    if(prevState.offer!=offer){
      if(pivotItem==='1'){
        this.setState({
          commandItems: data.setUploadedCommandItems(this),
          farCommandItems: data.setFarCommandItems(this,pivotItem)
        });
      }else{
        this.setState({
          commandItems: data.setCommandItems(this),
          farCommandItems: data.setFarCommandItems(this,pivotItem)
        })
      }

    }
    if(prevState.pivotItem!==pivotItem){
      if(pivotItem==='1'){
        this.setState({
          commandItems: data.setUploadedCommandItems(this),
          farCommandItems: data.setFarCommandItems(this,pivotItem),
        });
      }else if(pivotItem==='0'){
        this.setState({
          commandItems: data.setCommandItems(this),
          farCommandItems: data.setFarCommandItems(this,pivotItem)
        });
      }else if(pivotItem==='2'){
        this.setState({
          commandItems: [],
          farCommandItems: []
        });
      }
    }
    if(prevState.selected!=selected){
      //Init states
      this.setState((prevState)=>({
        loading:{
          ...prevState.loading,
          files:true
        }
      }));
      //Call api
      const model = {tenderId:tender.id,parentId:(selected!==null && selected!==undefined) ? selected.id : null};
      apiRequest.getFiles(this,model);
    }
  }

  //*Handle modals*//
  handleOpen(props){
    this.setState((prevState)=>({
      modal:{
        ...prevState.modal,
        [props]:true
      }
    }));
  }

  handleClose(props){
    this.setState((prevState)=>({
      modal:{
        ...prevState.modal,
        [props]:false
      }
    }));
  }
  //**//

  //*Download actions*//
  handleDownload=(item)=>{
    const {tender} = this.props;
    //Init states
    this.setState({
      requestResponse:null
    });
    //Call api
    apiRequest.downloadFile(this,tender.id,item.id,item.isFolder);
  }

  handleDownloadAllFiles=()=>{  
    const {tender} = this.props;
    const {selected} = this.state;
    //Call api
    apiRequest.downloadAllFile(this,tender.id,(selected!==null && selected!==undefined) ? selected.id : 'null');
  }
  //**//

  //*Helper methods*//
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }

  handleOpenInfo(){
    this.setState({
      info:true
    })
  }

  handleCloseInfo(){
    this.setState({
      info:false
    })
  }

  handleResponsePanel(){
    this.setState({
      requestResponse:null
    })
  }

  refreshOffer=()=>{
    const {tender} = this.props;
    apiRequest.getOffer(this,tender.id);
    apiRequest.getBestOffer(this,tender.id);
  }

  handlePivotChange=(item)=>{
    this.setState({
      pivotItem:item.key.replace('.','')
    });
  }

  setPivotItem = (value) =>{
    this.setState({
      pivotItem:value
    });
  }
  refreshOfferFiles=()=>{
    const {offer} = this.state;
    apiRequest.getOfferFiles(this,offer.id)
  }
  //**//

  handleSelect = (item) =>{
    const {tableNavItems} = this.state;

    var navItems = null;
    if(tableNavItems.length===0){
      navItems = [{ text: '....', key: 0, onClick: this.onBreadcrumbItemClicked },{ text: item.name, key: 1,selected:item, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }];
    }else{
      navItems = tableNavItems;
      navItems.map(item=>item.isCurrentItem=false);
      navItems.push({ text: item.name,selected:item, key:navItems.length+2, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true });
    }
   
    this.setState({
      tableNavItems:navItems,
      selected:item
    });
  }

  onBreadcrumbItemClicked=(ev, item)=> {
    const {tableNavItems} = this.state;
    

    if(item.key===0){
      this.setState({
        tableNavItems:[],
        selected:null
      });
    }else {
      const navItems = tableNavItems;
      var filtered = null;
      filtered = navItems.filter(row=>row.key<=item.key);
      
      this.setState({
        tableNavItems:filtered,
        selected:item.selected
      });
    }
  }

  handleSelectedOffer = (value) =>{
    this.setState({
      selectedOffer:value
    });
  }

  handleTenderChanges = (showLoading) =>{
    const {tender} = this.props;

    if(showLoading){
      this.setState({
        notice:true
      });
    }

    apiRequest.noticeTenderChanges(this,tender);
  }

  showTenderChanges = () =>{
    this.handleOpenChanges("tender");
    this.handleTenderChanges(false);
  }

  handleOfferChanges = (showLoading) =>{
    const {offer} = this.state;

    if(showLoading){
      this.setState({
          notice:true
      });
    }

    apiRequest.noticeOfferChanges(this,offer);
  }

  showOfferChanges = () =>{
    this.handleOpenChanges("offer");
    this.handleOfferChanges(false);
  }

  handleOpenChanges = (props) =>{
    this.setState(prevState=>({
      modal:{
        ...prevState.modal,
        [props]:true
      }    
    }));
  }

  handleCloseChanges = (props) =>{
    this.setState(prevState=>({
      modal:{
        ...prevState.modal,
        [props]:false
      }    
    }));
  }

  render() {
    const { columns,info,tableNavItems,selected,selectedOffer,notice, isCompactMode,loading,offerFiles,first,last, items,pivotItem,commandItems,requestResponse,offer,downloadIsProgress,zipIsProgress, farCommandItems,filteredItems,isFiltered,modal,bestOffer } = this.state;
    const {classes,navItems,tender,currencyOptions} = this.props;
    return (
      <div>
         {modal.new===true && <AddOffer setPivotItem={this.setPivotItem} onRefresh={this.refreshOffer} options={currencyOptions}  tender={tender} open={modal.new} hideModal={()=>this.handleClose('new')}/>}
         {modal.edit===true && <EditOffer onRefresh={this.refreshOffer} options={currencyOptions} offer={offer} tender={tender} open={modal.edit} hideModal={()=>this.handleClose('edit')}/>}
         {modal.delete===true && <OfferDelete setPivotItem={this.setPivotItem} onRefresh={this.refreshOffer} offer={offer} tender={tender} open={modal.delete} hideModal={()=>this.handleClose('delete')}/>}
         {info===true && <InfoCallout pivotItem={pivotItem}  onClose={()=>this.handleCloseInfo()} buttonId={`bt-info`} />}
         {modal.offer && <OfferChanges offer={offer} open={true} hideModal={()=>this.handleCloseChanges("offer")}/>}
         {modal.tender && <TenderChanges tender={tender} open={true} hideModal={()=>this.handleCloseChanges("tender")}/>}
         <LoadingProgress  open={notice}/>
         {navItems!=null && 
          <div className={classes.breadcrumb}>
            <Breadcrumb
              items={navItems}
              maxDisplayedItems={10}
            />
          </div>
         }
        {pivotItem!=='2' && <CommandBar 
          items={commandItems} 
          farItems={farCommandItems}
        />}   
       
        {/*Single download*/}
        <DownloadProgress open={downloadIsProgress!==null ? true : false}/>
         {/*Zip download*/}
        <DownloadProgress open={zipIsProgress}/>
        <div className={classes.header}>
          <Pivot selectedKey={pivotItem} onLinkClick={this.handlePivotChange}>
          {tender!==null && tender!=='' &&  
          <PivotItem headerText="Kiírás">
             {tender!==null && tender.tenderChanges === eventyTypes.EDIT && 
              <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                actions={
                  <div>
                    <MessageBarButton onClick={()=>this.handleTenderChanges(true)}>Ok</MessageBarButton>
                    <MessageBarButton onClick={this.showTenderChanges}>Megtekintés</MessageBarButton>
                  </div>
                }
              > 
                Változások történtek
              </MessageBar> 
              }
              <Grid className={classes.headerInfos} spacing={2} container>
                <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                  <Label>Beadási időszak kezdete</Label>
                  <TextField 
                    value={tender.dateStarted} 
                    iconProps={calenDarIcon} 
                    readOnly={true}
                  />
                </Grid>
                <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                  <Label>Beadási időszak vége</Label>
                  <TextField 
                    value={tender.dateEnd} 
                    iconProps={calenDarIcon} 
                    readOnly={true}
                  />
                </Grid>
                <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                  <Label>Hátralévő idő</Label>
                  <TextField 
                    value={tender.remainingTime} 
                    readOnly={true}
                  />
                </Grid>
                <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                  <Label>Kiírásért felelős</Label>
                  <TextField 
                    readOnly={true} 
                    value={tender.creator}
                  />
                </Grid>     
              </Grid>
              <Separator/>
              {requestResponse!==null &&
                  <MessageBar
                    messageBarType={this.setMessageBarType(requestResponse.type)}
                    isMultiline={false}
                    actions={
                      <div>
                        <MessageBarButton onClick={this.handleResponsePanel}>Ok</MessageBarButton>
                      </div>
                    }
                  >
                  {requestResponse.message}
                  </MessageBar>
              }    
              <div className={classes.table}>
                <div style={style.fluentUI.controlWrapper}>
                  <SearchBox style={style.fluentUI.search} placeholder="Keresés..." onChange={this._onChangeText} />
                </div>
                <div className={classes.breadcrumb}>
                  <Breadcrumb
                    items={tableNavItems}
                    maxDisplayedItems={10}
                  />
                </div> 
                <div style={style.fluentUI.tableWrapper}>
                <ScrollablePane>
                  <DetailsList
                    items={isFiltered ? filteredItems.slice(first,last) : items.slice(first,last)}
                    compact={isCompactMode}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={this._getKey}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderDetailsHeader={
                      (detailsHeaderProps, defaultRender) => (
                        <Sticky>
                          {defaultRender(detailsHeaderProps)}
                        </Sticky>
                      ) }
                    isHeaderVisible={true}
                    onRenderRow={ this.onRenderColumnListRow}
                    onItemInvoked={this._onItemInvoked}
                  />
                  </ScrollablePane>
                </div>
                <Pagination loading={loading} parent={this} length={isFiltered ? filteredItems.length : items.length}/>
              </div>
            </PivotItem>

            }
            {offer!==null && offer!=='' &&  
            <PivotItem headerText="Ajánlat">
               {tender!==null && tender.offerChanges === eventyTypes.EDIT && 
              <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                actions={
                  <div>
                    <MessageBarButton onClick={()=>this.handleOfferChanges(true)}>Ok</MessageBarButton>
                    <MessageBarButton onClick={this.showOfferChanges}>Megtekintés</MessageBarButton>
                  </div>
                }
              > 
                Változások történtek
              </MessageBar> 
              }
              <Grid className={classes.headerInfos} spacing={2} container>
              {tender.isClosed===true && TenderIsClosed}
                <Grid xs={6}  sm={6} md={6} lg={3} xl={3} item>
                  <Label>Ajánlat értéke</Label>
                  <TextField 
                    value={offer.value} 
                    suffix={offer.currency} 
                    disabled={true}
                    />
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                  <Label>Ajánlat érvényes a következőig</Label>
                  <TextField 
                    value={offer.dateValidTo} 
                    iconProps={calenDarIcon} 
                    disabled={true}
                  />
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={3} xl={3} item>
                  <Label>Ajánlatot elkészítette</Label>
                  <TextField 
                    value={offer.creator} 
                    iconProps={calenDarIcon} 
                    disabled={true}
                  />
                </Grid>                
              </Grid>
              <Separator/>
              <UploadedList onClose={this.handleClose} p_modal={modal} onSelectedOffer={this.handleSelectedOffer} selected={selectedOffer} offer={offer} items={offerFiles}  tender={tender}/>
            </PivotItem>
            }
            {bestOffer!==null && bestOffer!=='' && 
            <PivotItem headerText="Legjobb ajánlat">
              <Grid className={classes.headerInfos} spacing={2} container>
              {BestOfferInfo}
              {bestOffer.map(offer=>(
              <React.Fragment>
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} item>
                  <Label>Ajánlat értéke</Label>
                  <TextField 
                    value={offer.value} 
                    suffix={offer.currency} 
                    readOnly={true}
                  />             
                </Grid>
                <Grid xs={false} sm={6} md={6} lg={9} xl={9} item/>
              </React.Fragment>
              ))}
              </Grid>         
            </PivotItem>}
          </Pivot>
        </div>
      </div>
    );
  }

  //*To render table rows*//
  onRenderColumnListRow= (props) => {
    const {deleteRequest} = this.state
    const customStyles = {};
    if (props) {
        customStyles.root = style.fluentUI.tableRow;
        return <DetailsRow {...props} styles={customStyles}/>;
    }
    return null;
  };

   _getKey(item, index) {
    return item.key;
  }

   _onChangeCompactMode = (ev, checked) => {
    this.setState({ isCompactMode: checked });
  };

  _onChangeText = (ev, text) => {
    if(text!=='' && text!==null && text!==undefined){
      this.setState({
        isFiltered:true
      });
    }else{
     this.setState({
       isFiltered:true
     });
    }
    const {items} = this.state;
     this.setState({
       filteredItems: text ? items.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
     });
  };

  _onItemInvoked(item) {
    if(item.isFolder){
      this.handleSelect(item)
    }
}


   _onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns= columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
//**//


export default withStyles(style.materialUI)(FileList);