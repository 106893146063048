import * as React from 'react';
//FluentUI
import { Sticky,
  ScrollablePane,
  SearchBox,
  Breadcrumb,
  MessageBar,
  MessageBarType,
  MessageBarButton,
  DayOfWeek,
  DetailsRow } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
//Components
import {EditFile,NewFolder,NewFile} from '../../../../../components';
import {DownloadProgress,DeleteProgress} from '../../components';
import {Pagination} from '../../../../../../../../components';

import {apiRequest} from './apiRequest';
import data from './data';
//Theme
import style from './style';
import { withStyles } from '@material-ui/core';

//*Notification panel*//
const WarningExample = (p) => (
  <MessageBar
    messageBarType={MessageBarType.warning}
    isMultiline={false}
    onDismiss={p.resetChoice}
    dismissButtonAriaLabel="Close"
    actions={
      <div>
        <MessageBarButton onClick={p.onYes}>Igen</MessageBarButton>
        <MessageBarButton onClick={p.onNo}>Nem</MessageBarButton>
      </div>
    }
  >
   Biztosan törölni szeretnéd ?
  </MessageBar>
);
//**//

export class UploadedList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //table rows
      items: this.props.items,
      columns: data.setColumns(this,'empty'),
      isCompactMode: false,
      //filter
      filteredItems:[],
      isFiltered:false,
      //pagination
      first:0,
      last:0,
      //request
      loading:true,
      //interactions
      requestResponse:null,
      deleteIsProgress:false,
      zipIsProgress:false,
      downloadIsProgress:null,
      deleteRequest:null,
      //helper
      firstDayOfWeek:DayOfWeek.Monday,
      //modals
      modal:{
        editFile:null
      },
      tableNavItems:[],
      selected:null
    };

    this._onItemInvoked  = this._onItemInvoked.bind(this);
     //Modal actions
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseEditFile = this.handleCloseEditFile.bind(this);
    this.handleEditFile = this.handleEditFile.bind(this);

    this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleResponsePanel = this.handleResponsePanel.bind(this);
  }

  componentDidMount(){
    const {offer} = this.props;
    const model = {offerId:offer.id};
    apiRequest.getFiles(this,model);
  }

  componentDidUpdate(prevProps,prevState){
    const {deleteRequest,downloadIsProgress,selected,items} = this.state;
    const {offer} = this.props;

    if(prevState.items!=items){
      if(items.length>0){
        this.setState({
          columns: data.setColumns(this,'default')
        });
      }else{
        this.setState({
          columns: data.setColumns(this,'empty')
        });
      }
    }

    if(downloadIsProgress!=prevState.downloadIsProgress){
      if(downloadIsProgress!==null){
        this.setState({
          columns:  data.setColumns(this,'downloadIsProgress')
        });
      }else{
        this.setState({
          columns: data.setColumns(this,'default')
        });
      }
    }
    
    if(deleteRequest!=prevState.deleteRequest){
      if(deleteRequest!==null){
        this.setState({
          columns: data.setColumns(this,'delete')
        });
      }else{
        this.setState({
          columns: data.setColumns(this,'default'),
          deleteIsProgress:false
        });
      }
    }
    if(prevProps.items!==this.props.items){
      const model = {offerId:offer.id,parentId:(selected!==null && selected!==undefined) ? selected.id : null};
      apiRequest.getFiles(this,model);
    }
    if(prevState.selected!=selected){
      //Init states
      this.setState((prevState)=>({
        loading:{
          ...prevState.loading,
          files:true
        }
      }));
      //Call api
      const model = {offerId:offer.id,parentId:(selected!==null && selected!==undefined) ? selected.id : null};
      apiRequest.getFiles(this,model);
    }
  } 

  //*Download actions*//
  handleDownload(item){
    const {offer} = this.props;
    //Init states
    this.setState({
      requestResponse:null
    });
    //Call api
    apiRequest.downloadFile(this,offer.id,item.id,item.isFolder);
  }

  handleDownloadAllFiles=()=>{  
    const {offer} = this.props;
    const {selected} = this.state;
    //Call api
    apiRequest.downloadAllFile(this,offer.id,(selected!==null && selected!==undefined) ? selected.id : 'null');
  }
  //**//

  
  //*Delete actions*//
  handleDeleteAllRequest=()=>{
    const {items} = this.state;
    this.setState({
      deleteRequest:items,
      requestResponse:null,
    });
  }

  handleDeleteRequest(item){
    //Create array and push into the selected item
    let request = [];
    request.push(item);

    this.setState({
      deleteRequest:request,
      requestResponse:null,
    });
  }

  handleDelete(){
    const {tender,offer} = this.props;
    const {deleteRequest} = this.state;

    //Fill model
    const model = [];
    deleteRequest.map(row=>{
      model.push({fileId:row.id,offerId:offer.id,isFolder:row.isFolder})
    });
    //Call api
    apiRequest.deleteFile(this,model);
  }

  handleCancelDelete(){
    this.setState({
      deleteRequest:null
    })
  }
  //**//

  //*Edit actions*//
  handleEditFile(props,item){
    this.setState((prevState)=>({
      requestResponse:null,
      modal:{
        ...prevState.modal,
        [props]:item
      }
    }));
  }

  handleCloseEditFile(props){
    this.setState((prevState)=>({
      modal:{
        ...prevState.modal,
        [props]:null,
      }
    }));
  }
  //**//

  //*Handle modals*//
  handleClose(props){
    this.setState((prevState)=>({
      modal:{
        ...prevState.modal,
        [props]:false
      }
    }));
  }

  handleOpen(props){
    this.setState((prevState)=>({
      modal:{
        ...prevState.modal,
        [props]:true
      }
    }));
  }

  //**//

  //*Handle modals*//
  handleResponsePanel(){
    this.setState({
      requestResponse:null
    })
  }
  //**//

  
  //*Helper methods*//
  setMessageBarType(type){
    switch(type){
      case 'success':
        return MessageBarType.success
      case 'warning':
        return MessageBarType.warning
      case 'error':
        return MessageBarType.error
      default: 
        return MessageBarType.error
    }
  }

  refreshFiles = () =>{
    const {offer} = this.props;
    const {selected} = this.state;
    const model = {offerId:offer.id,parentId:(selected!==null && selected!==undefined) ? selected.id : null};
    apiRequest.getFiles(this,model);
  }

  closeProgressModal(){
    this.setState({
      downloadIsProgress:null,
      deleteIsProgress:false
    });
  }

  //**//

  handleSelect = (item) =>{
    const {tableNavItems} = this.state;

    var navItems = null;
    if(tableNavItems.length===0){
      navItems = [{ text: '....', key: 0, onClick: this.onBreadcrumbItemClicked },{ text: item.name, key: 1,selected:item, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true }];
    }else{
      navItems = tableNavItems;
      navItems.map(item=>item.isCurrentItem=false);
      navItems.push({ text: item.name,selected:item, key:navItems.length+2, onClick: this.onBreadcrumbItemClicked,isCurrentItem: true });
    }
   
    this.setState({
      tableNavItems:navItems,
      selected:item
    });
  }

  onBreadcrumbItemClicked=(ev, item)=> {
    const {tableNavItems} = this.state;
    

    if(item.key===0){
      this.setState({
        tableNavItems:[],
        selected:null
      });
    }else {
      const navItems = tableNavItems;
      var filtered = null;
      filtered = navItems.filter(row=>row.key<=item.key);
      
      this.setState({
        tableNavItems:filtered,
        selected:item.selected
      });
    }
  }

  

  render() {
    const { columns, isCompactMode,tableNavItems,selected,first,last,loading, items,modal,deleteRequest,filteredItems,zipIsProgress,requestResponse,downloadIsProgress,isFiltered,deleteIsProgress   } = this.state;
    const {classes,navItems,offer,p_modal,onClose,tender} = this.props;
    return (
      <div>
        {modal.editFile!==null && <EditFile onRefresh={this.refreshFiles} offer={offer} file={modal.editFile} open={true} hideModal={()=>this.handleCloseEditFile('editFile')}/>}
        {p_modal.newFolder===true && <NewFolder selected={selected} onRefresh={this.refreshFiles} offer={offer} open={p_modal.newFolder} hideModal={()=>onClose('newFolder')}/>} 
        {p_modal.newFile===true && <NewFile tender={tender} selected={selected} onRefresh={this.refreshFiles} offer={offer} open={p_modal.newFile} hideModal={()=>onClose('newFile')}/>}
        {navItems!=null && 
        <div className={classes.breadcrumb}>
          <Breadcrumb
            items={navItems}
            maxDisplayedItems={10}
          />
        </div>
        }
          {/*Single download*/}
          <DownloadProgress onClose={this.closeProgressModal} open={downloadIsProgress!==null ? true : false}/>
          {/*Zip download*/}
          <DownloadProgress onClose={this.closeProgressModal} open={zipIsProgress}/>
          <DeleteProgress onClose={this.closeProgressModal} open={deleteIsProgress}/>
        {deleteRequest!==null && <WarningExample onYes={()=>this.handleDelete(deleteRequest)} onNo={this.handleCancelDelete}/>}
        {requestResponse!==null &&
          <MessageBar
            messageBarType={this.setMessageBarType(requestResponse.type)}
            isMultiline={false}
            actions={
              <div>
                <MessageBarButton onClick={this.handleResponsePanel}>Ok</MessageBarButton>
              </div>
            }
          >
            {requestResponse.message}
          </MessageBar>
        }    
        <div className={classes.table}>
          <div style={style.fluentUI.controlWrapper}>
            <SearchBox style={style.fluentUI.search} placeholder="Keresés..." onChange={this._onChangeText} />
          </div>
          <div className={classes.breadcrumb}>
            <Breadcrumb
                items={tableNavItems}
                maxDisplayedItems={10}
            />
          </div> 
          <div style={style.fluentUI.tableWrapper}>
          <ScrollablePane>
            <DetailsList
              items={isFiltered ? filteredItems.slice(first,last) : items.slice(first,last)}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={
                (detailsHeaderProps, defaultRender) => (
                  <Sticky>
                    {defaultRender(detailsHeaderProps)}
                  </Sticky>
                ) }
              isHeaderVisible={true}
              onRenderRow={ this.onRenderColumnListRow}
              onItemInvoked={this._onItemInvoked}
            />
            </ScrollablePane>
          </div>
          <Pagination loading={loading} parent={this} length={isFiltered ? filteredItems.length : items.length}/>
        </div>
      </div>
    );
  }

  //*To render table rows*//
  getFileIcon(fileType){
    const pictures = ['png','jpg'];
    const docs=['doc'];
    if(pictures.includes(fileType)){
      return 'photo';
    }else if(docs.includes(fileType)){
      return 'docx';
    }
    return fileType;
  }

  onRenderColumnListRow= (props) => {
    const {deleteRequest} = this.state
    const customStyles = {};
    if (props) {
      if(deleteRequest!==null && deleteRequest!==undefined && deleteRequest.filter(row=>row.id===props.item.id).length>0){
        customStyles.root = style.fluentUI.highlightRow;
        return <DetailsRow {...props} styles={customStyles} />;
      }else{
        customStyles.root = style.fluentUI.tableRow;
        return <DetailsRow {...props} styles={customStyles}/>;
      }

    }
    return null;
  };

   _getKey(item, index) {
    return item.key;
  }

   _onChangeCompactMode = (ev, checked) => {
    this.setState({ isCompactMode: checked });
  };

  _onItemInvoked(item) {
    if(item.isFolder){
      this.handleSelect(item)
    }
  }

  _onChangeText = (ev, text) => {
    if(text!=='' && text!==null && text!==undefined){
      this.setState({
        isFiltered:true
      });
    }else{
     this.setState({
       isFiltered:true
     });
    }
    const {items} = this.state;
     this.setState({
       filteredItems: text ? items.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
     });
  };


   _onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns= columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
//**//


export default withStyles(style.materialUI)(UploadedList);