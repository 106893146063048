import  {createStore,applyMiddleware } from 'redux';
import rootReducer from './reducers/index.js';
import rootSaga from './sagas/index';
import createSagaMiddleware from 'redux-saga'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store
const store = createStore(
    rootReducer,
  applyMiddleware(sagaMiddleware)
)

// then run the saga
sagaMiddleware.run(rootSaga)

export default store;