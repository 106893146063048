import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    ModifyFile:'inner/modify-file'
}


export const apiRequest = {
    modifyFile
};

async function modifyFile(parent,data) {
    const {onRefresh} = parent.props;
    await new Api().call.post(Actions.ModifyFile,data).then(handleResponse)
        .then(res => {
            parent.setState({
                response:res,
                loading:false
            });
            onRefresh();
        }).catch(error=>{
            let validResponse = {message:'Váratlan hiba történt',type:'error'};
            if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                validResponse = error.response.data;
            }
            parent.setState({
                response:validResponse,
                loading:false
            });
            handleError(error);
        });
}