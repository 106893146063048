import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Create: 'inner/create-tender',
}


export const apiRequest = {
    createTender
};

async function createTender(parent,data) {
    const {onRefresh} = parent.props;
    await new Api().call.post(Actions.Create,data).then(handleResponse)
        .then(res => {
            parent.setState({
                loading:false,
                response:res
              });
              onRefresh();
            }).catch(error=>{
                if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                    parent.setState({
                        response:error.response.data,
                        loading:false
                    });
                }
                else{
                    parent.setState({
                        response:{message:'Váratlan hiba történt',type:'error'},
                        loading:false
                    });
                }
                handleError(error);
            });
}