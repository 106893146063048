import * as React from 'react';
//FluentUI
import { 
  Sticky,
  ScrollablePane,
  SearchBox,
  CommandBar,
  DetailsRow } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { eventyTypes } from '../../../../../../stores';

//Theme
import style from './style';
import { withStyles } from '@material-ui/core';
//Models
import data from './data';
import {apiRequest} from './apiRequest';
//Components
import {Pagination} from '../../../../../../components';
import { InfoCallout } from './components';



export class TenderList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //table rows
      items: [],
      columns: data.setColumns(this,'default'),
      isCompactMode: false,
      //filter
      filteredItems:[],
      isFiltered:false,
      //pagination
      first:0,
      last:0,
      //request
      loading:true,
      //interaction
      addNew:false,
      //commands
      farCommandItems:data.setFarCommandItems(this),
      info:null,
    };

    this._onItemInvoked  = this._onItemInvoked.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOpenInfo = this.handleOpenInfo.bind(this);
    this.handleCloseInfo = this.handleCloseInfo.bind(this);
  }

  componentDidMount(){
    apiRequest.getTenders(this);
  }
  
  //*Select clicked row*//
  handleClick(item){

    this.props.onSelect(item);
    if(item.tenderChanges==eventyTypes.CREATE){
      apiRequest.noticeChanges(this,item);
    }

  }
  //**//

  //*Helper methods*//
  handleOpenInfo(){
    this.setState({
      info:true
    })
  }

  handleCloseInfo(){
    this.setState({
      info:false
    })
  }
  //**//

  render() {
    const { columns, isCompactMode,farCommandItems,first,info,last,loading, items,filteredItems,isFiltered  } = this.state;
    const {classes} = this.props;
    return (
      <div>
        <CommandBar 
          farItems={farCommandItems}
        />   
        <div className={classes.table}>
          <div style={style.fluentUI.controlWrapper}>
            <SearchBox style={style.fluentUI.search} placeholder="Keresés.." onChange={this._onChangeText} />
          </div>
          <div style={style.fluentUI.tableWrapper}>
          {info===true && <InfoCallout  onClose={()=>this.handleCloseInfo()} buttonId={`bt-info`} />} 
          <ScrollablePane>
            <DetailsList
              items={isFiltered ? filteredItems.slice(first,last) : items.slice(first,last)}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={
                (detailsHeaderProps, defaultRender) => (
                  <Sticky>
                    {defaultRender(detailsHeaderProps)}
                  </Sticky>
                ) }
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
              onRenderRow={this.onRenderColumnListRow}
            />
            </ScrollablePane>
          </div>
          <Pagination loading={loading} parent={this} length={isFiltered ? filteredItems.length : items.length}/>
        </div>
      </div>
    );
  }

  //*To render table rows*//
  onRenderColumnListRow= (props) => {
    const customStyles = {};
    if (props) {
      if(props.item.isWon===true){
        //Set green background to row with isWon:true property 
        customStyles.root = style.fluentUI.highlightRow;
        return <DetailsRow {...props} styles={customStyles}/>;
      }else{
        //Default row style
        customStyles.root = style.fluentUI.tableRow;
        return <DetailsRow {...props} styles={customStyles}/>;
      }
    }
    return null;
  };

   _getKey(item, index) {
    return item.key;
  }

   _onChangeCompactMode = (ev, checked) => {
    this.setState({ isCompactMode: checked });
  };

   _onChangeText = (ev, text) => {
     if(text!=='' && text!==null && text!==undefined){
       this.setState({
         isFiltered:true
       });
     }else{
      this.setState({
        isFiltered:true
      });
     }
     const {items} = this.state;
      this.setState({
        filteredItems: text ? items.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
      });
  };

   _onItemInvoked(item) {
     this.handleClick(item);
  }


   _onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns= columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
//**//




export default withStyles(style.materialUI)(TenderList);