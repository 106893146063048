import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    GetTenders: 'external/get-tenders',
    NoticeChanges:'inner/tender-changes/notice'
}


export const apiRequest = {
    getTenders,
    noticeChanges
};

async function getTenders(parent) {
    await new Api().call.get(Actions.GetTenders).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                items:res,
                loading:false
            }));
        }).catch(handleError);
}

async function noticeChanges(parent,model) {
    
    await new Api().call.post(`${Actions.NoticeChanges}/${model.id}`).then(handleResponse)
        .then(res => {
        }).catch(handleError);
}
