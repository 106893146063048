import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const materialUI = ({
    logo:{
        position:'absolute',
        top:'0',
        left:'0',
        right:'0',
        bottom:'0',
        margin:'auto',
        width:'200px',
        height:'200px'
    },
    root:{
        height:'100%'
    }
  });

const styles={
    materialUI
};


  export default styles;