import {lightTheme} from '../../../../../../../../themes';
import {
  getTheme,
  mergeStyleSets,
  FontWeights
} from 'office-ui-fabric-react';
import { getFocusStyle } from 'office-ui-fabric-react/lib/Styling';
import { FontSizes } from '@fluentui/style-utilities';
import { SharedColors } from '@fluentui/theme';




const theme = getTheme();
const { palette, semanticColors, fonts } = theme;
const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      minWidth:'400px'
    },
    titleContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    titleName: [
      fonts.xLarge,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    ],
    titleIndex: {
      fontSize: fonts.medium.fontSize,
      color: palette.neutralTertiary,
      marginBottom: 10,
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${lightTheme.palette.themePrimary}`,
        color: lightTheme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    action:{
      padding: '0 24px 24px 24px',
      textAlign:'right'
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    editIcon:{
      color:SharedColors.yellow10
    },
    newIcon:{
      color:SharedColors.yellowGreen10
    },
    deleteIcon:{
      color:SharedColors.red10
    },
    tableHeader:{
     borderBottom:`2px solid ${lightTheme.palette.themePrimary}`
    },
    borderLeft:{
      borderLeft:`2px solid ${lightTheme.palette.themePrimary}`,
      padding:'0.5rem'
    },
    borderRight:{
      borderRight:`2px solid ${lightTheme.palette.themePrimary}`,
      padding:'0.5rem'
    },
    borderX:{
      borderRight:`2px solid ${lightTheme.palette.themePrimary}`,
      borderLeft:`2px solid ${lightTheme.palette.themePrimary}`,
      padding:'0.5rem'
    },
    markedCol:{
      backgroundColor:'#fff4ce'
    }
  });
  const iconButtonStyles = {
    root: {
      color: lightTheme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: lightTheme.palette.neutralDark,
    },
  };
 
  export default {
    contentStyles,
    iconButtonStyles
  };