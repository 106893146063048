import React from 'react';
//Theme
import style from './style';
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

import {eventyTypes} from '../../../../../../stores';
//Icons
const openFolderHorizontal = {iconName:'OpenFolderHorizontal'};
const infoIcon = {iconName:'Info', style:{color:'#107c10'}};
const tenderChangesIcon = {iconName:'PageHeaderEdit'};
const offerChangesIcon = {iconName:'PageEdit'};
const newOfferIcon = {iconName:'AddTo'};

function setColumns(parent,type){
    switch(type){
        case 'default':
            return [
              {
                key: 'column1',
                name: 'Azonosító',
                fieldName: 'id',
                minWidth: 100,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column2',
                name: 'Megnevezés',
                fieldName: 'name',
                minWidth: 250,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column3',
                name: 'Képviselve',
                fieldName: 'partner',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: parent._onColumnClick,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'column4',
                name: 'Beküldhető',
                fieldName: 'dateStartedValue',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: parent._onColumnClick,
                onRender: (item) => {
                  return <span>{item.dateStarted}</span>;
                },
                data: 'number',
                isPadded: true,
              },
              {
                key: 'column5',
                name: 'Beküldési határidő',
                fieldName: 'dateEndValue',
                minWidth: 110,
                maxWidth: 110,
                isResizable: true,
                data: 'number',
                onColumnClick: parent._onColumnClick,
                onRender: (item) => {
                  return <span>{item.dateEnd}</span>;
                },
                isPadded: true,
              },
              {
                key: 'column6',
                name: 'Hátralévő idő',
                fieldName: 'remainingTime',
                minWidth: 110,
                maxWidth: 110,
                isResizable: true,
                data: 'string',
                onColumnClick: parent._onColumnClick,
                isPadded: true,
              },
              {
                key: 'colum7',
                name: 'Kiíró',
                fieldName: 'creator',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                data: 'string',
                onColumnClick: parent._onColumnClick,
              },
              {
                key: 'colum8',
                name: 'Ajánlat beküldve',
                fieldName: 'offerIsSent',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                data: 'string',
                onColumnClick: parent._onColumnClick,
              },
              {
                key: 'column9',
                name: 'Megnyitás',
                fieldName: 'open',
                style:style.fluentUI.fileIconCell,
                isIconOnly: true,
                minWidth: 16,
                maxWidth: 16,
                onColumnClick: parent._onColumnClick,
                onRender: (item) => (
                  <React.Fragment>
                  {item.isOver===true || item.isClosed===true &&     
                    <TooltipHost content="Elérhetőség">
                      <IconButton
                      id={`info-button-${item.id}`}
                      onClick={()=>parent.handleOpenInfo(item)}
                      iconProps={infoIcon}
                      />        
                    </TooltipHost>
                  }
                  <TooltipHost content="Megnyitás">
                     <IconButton
                      onClick={()=>parent.handleClick(item)}
                      iconProps={openFolderHorizontal}
                    />        
                  </TooltipHost>
                  {item.tenderChanges === eventyTypes.EDIT && 
                    <TooltipHost content="Változott a kiírás">
                        <IconButton
                          style={style.fluentUI.changesIcon}
                          iconProps={tenderChangesIcon}
                        />        
                    </TooltipHost>}
                    {item.tenderChanges === eventyTypes.CREATE && 
                    <TooltipHost content="Új kiírás">
                        <IconButton
                          style={style.fluentUI.newIcon}
                          iconProps={newOfferIcon}
                        />        
                    </TooltipHost>}
                    {item.offerChanges === eventyTypes.EDIT && 
                    <TooltipHost content="Változott az ajánlat">
                        <IconButton
                          style={style.fluentUI.changesIcon}
                          iconProps={offerChangesIcon}
                        />        
                    </TooltipHost>}
                  </React.Fragment>
                ),
              },
        
            ]        
        default:
            return []      
    }
}


function setFarCommandItems(parent){
  return [
    {
      key: 'info',
      text: 'Info',
      id:'bt-info',
      // This needs an ariaLabel since it's icon-only
      ariaLabel: 'Info',
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      onClick: () => parent.handleOpenInfo()
    },
  ]
}



export default {
  setColumns,
  setFarCommandItems
}