import { createMuiTheme } from '@material-ui/core/styles';
import {lightTheme} from '../../../../../../themes';

const theme = createMuiTheme();

const materialUI = ({
    table:{
      padding:theme.spacing(2)
    },
    breadcrumb:{
        paddingLeft:theme.spacing(2),
        backgroundColor:lightTheme.palette.neutralLighterAlt
    },
    header:{
      padding: theme.spacing(2),
      backgroundColor:lightTheme.palette.neutralLighterAlt
  }
});

  
const fluentUI ={

      fileIconCell: {
        textAlign: 'left',
        selectors: {
          '&:before': {
            content: '.',
            display: 'inline-block',
            verticalAlign: 'middle',
            height: '100%',
            width: '0px',
            visibility: 'hidden',
          },
        },
      },
      controlWrapper: {
        width:'100%'
      },
      tableRow:{
        selectors:{
          '&:hover':{backgroundColor:lightTheme.palette.themeLighter}
        }
      },
      search:{
        margin: '5px 5px 5px 5px',
      },
      tableWrapper:{
        height: '550px',
        position: 'relative',
        maxHeight: 'inherit'
      }
}

const styles={
    materialUI,
    fluentUI
};


  export default styles;