import {Api,handleResponse,handleError} from '../../../../../../api';
//Action
const Actions = {
    Edit: 'external/edit-offer',
    GetOffer:'external/get-offer',
}


export const apiRequest = {
    editOffer,
    getOffer
};

async function editOffer(parent,data) {
    const {onRefresh} = parent.props;
    await new Api().call.post(Actions.Edit,data).then(handleResponse)
        .then(res => {
            parent.setState({
                loading:false,
                response:{message:res.message,type:res.type},
                editOffer:res.data
              });
              onRefresh();
            }).catch(error=>{
                if(error!=null && error!==undefined && error.response!==null && error.response!==undefined){
                    parent.setState({
                        response:error.response.data,
                        loading:false
                    });
                }
                else{
                    parent.setState({
                        response:{message:'Váratlan hiba történt',type:'error'},
                        loading:false
                    });
                }
                handleError(error);
            });
}

async function getOffer(parent,tenderId) {
    await new Api().call.get(`${Actions.GetOffer}/${tenderId}`).then(handleResponse)
        .then(res => {
            parent.setState((prevState)=>({
                editOffer:res,
                loading:false
            }));
        }).catch(handleError);
}