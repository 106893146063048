
//Theme
import style from './style';
//FluentUI
import { IconButton } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import React from 'react';

import {scopes,eventyTypes} from '../../../../../../stores';

//Icons
const openFolderHorizontal = {iconName:'OpenFolderHorizontal'};
const checkboxCompositeIcon = {iconName:'CheckboxComposite'}
const offerChangesIcon = {iconName:'PageEdit'};
const newOfferIcon = {iconName:'AddTo'};


function setColumns(parent,type){
    switch(type){
        case 'default':
            return [
                {
                  key: 'column1',
                  name: 'Partner',
                  fieldName: 'name',
                  minWidth: 250,
                  maxWidth: 250,
                  isRowHeader: true,
                  isResizable: true,
                  isSortedDescending: false,
                  onColumnClick: parent._onColumnClick,
                  data: 'string',
                  isPadded: true,
                },
                {
                  key: 'column2',
                  name: 'File-ok száma',
                  fieldName: 'filesCount',
                  minWidth: 70,
                  maxWidth: 90,
                  isResizable: true,
                  onColumnClick: parent._onColumnClick,
                  data: 'number',
                  isPadded: true,
                },
                {
                  key: 'column3',
                  name: 'Beérkezett',
                  fieldName: 'dateCreatedValue',
                  minWidth: 70,
                  maxWidth: 90,
                  isResizable: true,
                  data: 'number',
                  onColumnClick: parent._onColumnClick,
                  onRender: (item) => {
                    return <span>{item.dateCreated}</span>;
                  },
                  isPadded: true,
                },
                {
                  key: 'column4',
                  name: 'Ajánlat értéke',
                  fieldName: 'value',
                  minWidth: 70,
                  maxWidth: 90,
                  isResizable: true,
                  data: 'number',
                  onColumnClick: parent._onColumnClick,
                  isPadded: true,
                },{
                  key: 'column5',
                  name: 'Valuta',
                  fieldName: 'currency',
                  minWidth: 40,
                  maxWidth: 40,
                  isResizable: true,
                  data: 'string',
                  onColumnClick: parent._onColumnClick,
                  isPadded: true,
                },
                {
                  key: 'column6',
                  name: 'Ajánlat érvényes',
                  fieldName: 'dateValidToValue',
                  minWidth: 70,
                  maxWidth: 90,
                  isResizable: true,
                  data: 'number',
                  onColumnClick: parent._onColumnClick,
                  onRender: (item) => {
                    return <span>{item.dateValidTo}</span>;
                  },
                  isPadded: true,
                },
                {
                  key: 'column7',
                  name: 'Módosítva',
                  fieldName: 'dateModifiedValue',
                  minWidth: 70,
                  maxWidth: 90,
                  isResizable: true,
                  data: 'number',
                  onColumnClick: parent._onColumnClick,
                  onRender: (item) => {
                    return <span>{item.dateModified}</span>;
                  },
                  isPadded: true,
                },
                {
                  key: 'column8',
                  name: 'Megnyitás',
                  fieldName: 'open',
                  style:style.fluentUI.fileIconCell,
                  isIconOnly: true,
                  minWidth: 16,
                  maxWidth: 16,
                  onColumnClick: parent._onColumnClick,
                  onRender: (item) => (
                  <React.Fragment>
                    <TooltipHost content="Megnyitás">
                       <IconButton
                        onClick={()=>parent.handleClick(item)}
                        iconProps={openFolderHorizontal}
                      />        
                    </TooltipHost>
                    {item.lastEvent === eventyTypes.EDIT && 
                    <TooltipHost content="Változás történt">
                        <IconButton
                          style={style.fluentUI.changesIcon}
                          iconProps={offerChangesIcon}
                        />        
                    </TooltipHost>}
                    {item.lastEvent === eventyTypes.CREATE && 
                    <TooltipHost content="Új pályázat">
                        <IconButton
                          style={style.fluentUI.newIcon}
                          iconProps={newOfferIcon}
                        />        
                    </TooltipHost>}
                  </React.Fragment>
                  ),
                },
              ]
        default:
            return []      
    }
}

function setCommandItems(parent) {
  const {isClosed,items} = parent.state;
  const {tender} = parent.props;
  return [
    {
      key: 'newItem',
      text: 'Elbírálás',
      disabled: isClosed || (!tender.scopes.includes(scopes.TENDER_OWNER) && !tender.scopes.includes(scopes.OFFER_DECISION)),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'DecisionSolid' },
      subMenuProps: {
        items: [
          {
            key: 'winner',
            text: 'Nyertes ajánlat kiválasztása',
            disabled:items.length<1, 
            iconProps:items.length<1 ? {iconName:'EntitlementRedemption'} : {iconName:'EntitlementRedemption',style:style.fluentUI.newIcon},
            onClick:()=>parent.handleOpen('decision')
          },
          {
            key: 'no-winner',
            text: 'Nincs nyertes ajánlat',
            iconProps:{iconName:'PageRemove',style:style.fluentUI.modifyIcon},
            onClick:()=>parent.handleOpen('noWinner')
          },
        ],
      },
    }
  ]
}


function setFarCommandItems(parent){
    return [
      {
        key: 'info',
        text: 'Info',
        id:'bt-info',
        // This needs an ariaLabel since it's icon-only
        ariaLabel: 'Info',
        iconOnly: true,
        iconProps: { iconName: 'Info' },
        onClick: () => parent.handleOpenInfo()
      },
    ]
  }

export default {
  setColumns,
  setFarCommandItems,
  setCommandItems
}
