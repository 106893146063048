import React from 'react';
//Helpers
import Cookies from 'js-cookie';
//MaterialUI
import Grid from '@material-ui/core/Grid';
//FluentUI
import { Modal,Label,PrimaryButton } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
//Theme
import style from './style';


export default function CookieInfo(props) {
    
    const[open,setOpen] = React.useState(GetInfoForAccept());

    const titleId = useId('cookie-info-title');


    //Set CookiesAccepted to true
    //The modal not shown in the future, only the cookie is expired
    function hideModal(){
        setOpen(false);
        Cookies.set('CookiesAccepted', 'true', { expires: 365  });
    }

    //Get cookies info
    function GetInfoForAccept(){
        const result = Cookies.get('CookiesAccepted');
        if(result===undefined || result===null){
            return true;
        }
        if(result!=='true'){
            return true;
        }
        return false;
    }
  

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={open}
            isModeless={true}
            containerClassName={style.container}
        >
            <div className={style.body} >
                <Grid container>
                    <Grid sm={12} md={10} lg={10} item>
                        <Label className={style.bodyLabel}>
                            A Merkbau-AR felületén süti (cookie) fájlokat használ. Ezeket a fájlokat az Ön gépén tárolja a rendszer. 
                            A cookie-k személyek azonosítására nem alkalmasak, szolgáltatásaink biztosításához szükségesek. 
                            Az oldal használatával Ön beleegyezik a cookie-k használatába.
                        </Label>
                    </Grid>
                    <Grid sm={12} md={2} lg={2} item>
                        <PrimaryButton
                            onClick={hideModal}
                            id="cookie-info-button" 
                            text="Elfogadom" 
                            allowDisabledFocus
                        />
                    </Grid>
                </Grid>             
            </div>
        </Modal>
    );
}