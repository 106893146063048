import {
  mergeStyleSets,
  FontWeights,
} from 'office-ui-fabric-react';
import {lightTheme} from '../../../../../../../../themes';


const contentStyles = mergeStyleSets({
  callout: {
    width: 320,
    padding: '20px 24px',
    borderTop:'2px solid '+lightTheme.palette.themePrimary
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  openFolder:{
    fontSize:'1rem',
    marginRight:'1rem',
    color:lightTheme.palette.themePrimary
  },
  winner:{
    fontSize:'1rem',
    marginRight:'1rem',
    color:'#caeaca'
  },
  changes:{
    fontSize:'1rem',
    marginRight:'1rem',
    color:'rgb(193, 156, 0)'
  },
  new:{
    fontSize:'1rem',
    marginRight:'1rem',
    color:'rgb(140, 189, 24)'
  }

});

 
  export default {
    contentStyles
  };